import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import DataTableBase from "../../../components/common/DateTableBase";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { companyURL } from "../../../App";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomLoader from "../../../components/common/CustomLoader";
import { Col, Row } from "react-bootstrap";
import Select from 'react-select'
import { checkProjectProgress, convertMinutesToHoursAndMinutes, countTotalDuration, formateDateDayMonth, formateTimeHoursMints, getCurrentMonth, getCurrentYear, totalUserAmount } from "../../../utility/Index";
import ReactToPrint from 'react-to-print';
import logo from "../../../assets/logo.png";

const ReportProject = () => {
    const [department, setDepartment] = useState(null);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [projects, setProjects] = useState([]);
    const [reportDetail, setReportDetail] = useState(null);
    const [allData, setAllData] = useState(null);
    const [data2, setData2] = useState([]);
    const [pending, setPending] = useState(true);
    const [pending2, setPending2] = useState(false);
    const [month, setMonth] = useState(getCurrentMonth());
    const [year, setYear] = useState(getCurrentYear());
    const [project, setProject] = useState(null)
    const [options, setOptions] = useState([]);

    // const [id, setId] = useState(null);

    const componentRef = useRef();

    const [flag, setFlag] = useState(1);
    const [loading, setLoading] = useState(false);

    const showSuccessAlert = (msg) => {
        console.log("success alert")
        toast.success(msg);
    };

    // Function to show an error alert
    const showErrorAlert = (err) => {
        toast.error(err);
    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handleClose2 = () => {
        setReportDetail(null);
        setShow2(false);
    };

    const handleShow2 = () => {
        setShow2(true);
    }   
    
    const fetchData = async (id) => {
        // Mock API call
        // const response = await fetch('your-api-endpoint');
        // const dataFromAPI = await response.json();
        // setData(dataFromAPI);
        const token = localStorage.getItem("token");

        axios.post(`${companyURL}/viewprojectReport`, {year: 2024, month: 4, project_id: id},{
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            const temData = res?.data?.report[0];
            setReportDetail(temData);
            setAllData(temData);
            setData2(temData);
            // setAllData(res?.data?.data);
            // setPending(false);
        }).catch((err) => {
            // setPending(false);
            console.log("err : ", err);
        })
    };

    const handleButtonClick = async (row, actionType) => {
        // Call API to save the action in the database
        await saveActionToDatabase(row?.id, actionType);
        // Update the data after action is saved
        fetchData(row?.project_id);
    };
    
    const saveActionToDatabase = async (rowId, actionType) => {
        // Mock API call to save action to database
        // Replace this with your actual API endpoint and logic
        // await fetch(`your-save-action-api-endpoint`, {
        //   method: 'POST',
        //   body: JSON.stringify({ rowId, actionType }),
        //   headers: {
        //     'Content-Type': 'application/json'
        //   }
        // });
        const token = localStorage.getItem("token");

        axios.post(`${companyURL}/updateSessionColorByID`, {
            session_id: rowId, 
            colorValue: actionType
        },
        {headers: {
            Authorization: `Bearer ${token}`
        }}).then((res) => {
            console.log("response status api : ", res);
        }).catch((err) => {
            console.log("Error status api : ", err);
        })
    };

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const navigate = useNavigate();


    const columns = [
        {
            name: 'Project Name',
            selector: row => row?.project_name,
        },
        {
            name: <b className="working-th">Progress</b>,
            selector: row => <div className="text-center"><div className="performancediv"><div className="performnce-value" style={{backgroundColor: checkProjectProgress(row), width: `${33}%`}}><span style={{letterSpacing: '-1px'}} className="text5">33%</span></div> </div></div>,
        },
        {
            name: 'Dead Line',
            selector: row => '28 Days',
        },
        {
            name: 'Work Hours',
            selector: row => row?.duration_month > 0 ? convertMinutesToHoursAndMinutes(row?.duration_month) : 0,
        },
        {
            name: 'Budget',
            selector: row => '$1,000',
        },
        {
            name: 'Total Cost',
            selector: row => '$450',
        },
        {
            name: 'View',
            selector: row => <button className="edit-btn" onClick={() => {navigate(`/company/project-report/detail/${row?.id}`);}}>View</button>
        }
    ];
    
    useEffect(() => {
        setPending(true);
        console.log("flag updated")
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/projectReport`, {
            year: year, month: month, project_id: project ? project?.value : null
        }, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setProjects(res?.data?.projects);
            if(options?.length === 0){
                const optionTemp = [{label: 'All', value: null}];
                res?.data?.projects?.map((item => {
                    optionTemp.push({label: item?.project_name, value: item?.id});
                }))
                setOptions(optionTemp);
            }
            setPending(false);
        }).catch((err) => {
            setPending(false);
            // showErrorAlert(err?.response?.data?.error[0])
            console.log("err : ", err);
        })
    }, [flag, project, year, month])


    const onSubmit = () => {
        if(department){
            setLoading(true);
            const token = localStorage.getItem("token");
            axios.post(`${companyURL}/createDepartment`, {title: department}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                setFlag(flag + 1);
                setLoading(false);
                showSuccessAlert('Department created successfully');
            }).catch((err) => {
                setLoading(false);
                showErrorAlert(err?.response?.data?.error[0])
                console.log("err : ", err);
            })
            handleClose();
        }
    }

    const onSubmit2 = (id) => {        
        setPending2(true);
        handleShow2();
        // setLoading(true);
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/viewprojectReport`, {year: year, month: month, project_id: id}, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setReportDetail(res?.data?.report[0]);            
            setAllData(res?.data?.report[0]);            
            setPending2(false);
            // setLoading(false);
        }).catch((err) => {
            setPending2(false);
            setLoading(false);
            showErrorAlert(err?.response?.data?.error[0])
            console.log("err : ", err);
        })
        
    }

    // const delDepartment = () => {
    //     if(id){
    //         setLoading(true);
    //         const token = localStorage.getItem("token");
    //         axios.post(`${companyURL}/deleteDepartment`, {id: id}, {
    //             headers: {'Authorization': `Bearer ${token}`}
    //         }).then((res) => {
    //             console.log("res : ", res);
    //             setFlag(flag + 1);
    //             setLoading(false);
    //             showSuccessAlert('Department Deleted Successfully');
    //         }).catch((err) => {
    //             console.log("err : ", err);
    //             setLoading(false);
    //             showErrorAlert(err?.response?.data?.error[0])
    //         })
            
    //     }
    //     handleClose3();
    // }

    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    // ]

    const handleChange = (i, value) => {
        let prevData = [...reportDetail?.timelog];
        console.log("prevData[i] : ", prevData[i]);
        prevData[i] = {
            ...prevData[i],
            isChecked: value
        };
        console.log("prevData[i] after : ", prevData[i]);
        setReportDetail({...reportDetail, timelog: prevData});
    }

    const handleFilter = (val) => {
        if(val === "all"){
            setReportDetail({...reportDetail, timelog: allData?.timelog});
        } else {
            const filteredData = [];
            allData?.timelog?.map((item => {
                if(item?.isChecked === val){
                    filteredData.push(item);
                }
            })) 
            setReportDetail({...reportDetail, timelog: filteredData});
        }
    }

    // useEffect(() => {console.log("Selected project : ", project)}, [project])

    return (
        <div className="customer-dashboard">
            <div style={{ display: 'none' }}>
                <ComponentToPrint reportDetail={reportDetail} ref={componentRef} />
            </div>
            <Modal size="xl" show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>Project Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        {pending2 ? (
                            <CustomLoader />
                        ) : (
                            <div className="w-100">
                                <div className="d-flex w-100 invoice-header px-4 py-3 pt-4">
                                    <div className="w-50">
                                        <img src={logo} height={"105px"} alt="logo" />
                                    </div>
                                    <div className="w-50">
                                        <div className="text-end">
                                            <h2 className="mb-1 clr-303778x">Screen PC</h2>
                                            <p className="mb-1">200 Ngam Wong Wan Rd., Lat yao, Chatuchak</p>
                                            {/* <p className="mb-1">(123) 456 -829</p> */}
                                            <p className="mb-1">(Tax ID No.) 09940001655</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 text-center">
                                    {/* <h1 className="clr-303778 mb-1">Quatation</h1>                             */}
                                </div>
                                <div className="d-flex w-100 px-4 py-3">
                                    <div className="w-50">
                                        <div>                                    
                                            <h2 className="mb-1">{reportDetail?.project_name}</h2>
                                            <p className="mb-1">{"April 2024"}</p>
                                            
                                        </div>
                                    </div>
                                    <div className="w-50">
                                        <div className="text-end">
                                            <h2 className="mb-1 mt-2">Duration: {convertMinutesToHoursAndMinutes(countTotalDuration(reportDetail?.timelog))}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 px-4">
                                    <div className="d-flex justify-content-end mb-3">
                                        <div onClick={() => {handleFilter('all');}} className="pointer text-center white-radio-div bg-blue">All</div>
                                        <div onClick={() => {handleFilter(0);}} className="pointer white-radio-div"></div>
                                        <div onClick={() => {handleFilter(1);}} className="pointer green-radio-div"></div>
                                        <div onClick={() => {handleFilter(2);}} className="pointer yellow-radio-div"></div>
                                        <div onClick={() => {handleFilter(3);}} className="pointer red-radio-div"></div>
                                    </div>
                                    <table className="pdf-table1 session-table">
                                        <thead>
                                            <tr>
                                                <th>Actions</th>
                                                <th>User</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Hours</th>
                                                <th>Hour Rate</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportDetail?.timelog?.map((item, index) => (
                                                <tr key={index} className={`${index+1 === reportDetail?.length && "remove-border-bottom"} ${item?.isChecked === 0 ? "white-row" : item?.isChecked === 1 ? "green-row" : item?.isChecked === 2 ? "yellow-row" : item?.isChecked === 3 ? "red-row" : ""}`}>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div onClick={() => {handleChange(index, 0); handleButtonClick(item, 0);}} className="pointer white-radio-div"></div>
                                                            <div onClick={() => {handleChange(index, 1); handleButtonClick(item, 1);}} className="pointer green-radio-div"></div>
                                                            <div onClick={() => {handleChange(index, 2); handleButtonClick(item, 2);}} className="pointer yellow-radio-div"></div>
                                                            <div onClick={() => {handleChange(index, 3); handleButtonClick(item, 3);}} className="pointer red-radio-div"></div>
                                                        </div>
                                                    </td>
                                                    {/* <td className="bg-303778x w-60px">{index + 1}</td> */}
                                                    <td>
                                                        <h6 className="mb-1">{item?.user?.first_name}</h6>
                                                        {/* <p className="mb-0">Fund request for staff</p> */}
                                                    </td>
                                                    <td>{formateDateDayMonth(item?.start_date_time)}  </td>
                                                    <td>{formateTimeHoursMints(item?.start_date_time)} - {formateTimeHoursMints(item?.end_date_time)}</td>
                                                    <td>
                                                        <h6 className="mb-1">{convertMinutesToHoursAndMinutes(item?.duration)}</h6>
                                                    </td>
                                                    <td>
                                                        <h6 className="mb-1">${item?.user?.hour_rate}</h6>
                                                    </td>
                                                    <td className="bg-303778x w-100px">${Number(item?.user?.hour_rate * (Number(item?.duration) / 60)).toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex w-100 px-4 py-3 invoice-header">
                                    <div className="w-65">
                                        {/* <h3 className="pt-4">Thank You</h3> */}
                                    </div>
                                    <div className="w-35">
                                        <div>
                                            <table className="pdf-table2">
                                                <thead>
                                                    <tr className="bb-rgb-237">
                                                        <th>Label</th>
                                                        <th>Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="bb-303778">
                                                        <th className="clr-303778x">Total Duration</th>
                                                        <th className="clr-303778x">{convertMinutesToHoursAndMinutes(countTotalDuration(reportDetail?.timelog))}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Total Amount</th>
                                                        <th>${totalUserAmount(reportDetail?.timelog).toFixed(2)}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>            
                            </div>    
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <ReactToPrint
                    trigger={() => (
                        <Button 
                            className="bg-0facd5" 
                            // onClick={onSubmit2} 
                            variant="primary"
                        >
                            Download
                        </Button>                        
                    )}
                    content={() => componentRef.current}
                />
                </Modal.Footer>
            </Modal>
            

            <div className="d-flex">
                <div className="w-50">
                    <h3 className="mb-0 text2">Project Report</h3>                    
                </div>
                <div className="w-50 d-flex justify-content-end">
                    <div>
                    <Select 
                        options={options}
                        onChange={setProject}
                        styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                            //   borderColor: state.isFocused ? 'grey' : 'red',
                            width: '200px'
                            }),
                        }}     
                    />
                    </div>
                    <div className="mx-2">
                        <select className="form-control" defaultValue={year} onChange={(e) => setYear(e.target.value)}>
                            <option>Select Year</option>
                            <option value={2024}>2024</option>
                            <option value={2023}>2023</option>
                        </select>                        
                    </div>
                    <div>
                        <select className="form-control" onChange={(e) => setMonth(e.target.value)} defaultValue={month}>
                            <option>Select Month</option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                    </div>
                </div>                
            </div>

        
            <div className="table-card mt-30">
                {loading ? (
                    <div style={{paddingTop: '25vh', paddingBottom: '25vh'}}>
                        <CustomLoader />
                    </div>
                ) : (
                    <>
                        <DataTableBase 
                            title="Project Report"
                            progressPending={pending}
                            columns={columns}
                            data={projects}
                        />
                        <div className="text-end mt-3">
                            <button className="prev-next-btn">PREV</button>
                            <button className="current-page-btn mx-2">{1}/{1}</button>
                            <button className="prev-next-btn">NEXT</button>
                        </div>
                    </>
                )}


            </div>
            <ToastContainer />
        </div>
    )
}

export default ReportProject;


const ComponentToPrint = React.forwardRef(({reportDetail}, ref) => {
    return (
        <div className="w-100" ref={ref}>
            <div className="d-flex w-100 invoice-header px-4 py-3 pt-4">
                <div className="w-50">
                    <img src={logo} height={"105px"} alt="logo" />
                </div>
                <div className="w-50">
                    <div className="text-end">
                        <h2 className="mb-1 clr-303778x">Screen PC</h2>
                        <p className="mb-1">200 Ngam Wong Wan Rd., Lat yao, Chatuchak</p>
                        {/* <p className="mb-1">(123) 456 -829</p> */}
                        <p className="mb-1">(Tax ID No.) 09940001655</p>
                    </div>
                </div>
            </div>
            <div className="w-100 text-center">
                {/* <h1 className="clr-303778 mb-1">Quatation</h1>                             */}
            </div>
            <div className="d-flex w-100 px-4 py-3">
                <div className="w-50">
                    <div>                                    
                        <h2 className="mb-1">{reportDetail?.project_name}</h2>
                        <p className="mb-1">{"April 2024"}</p>
                        
                    </div>
                </div>
                <div className="w-50">
                    <div className="text-end">
                        <h2 className="mb-1 mt-2">Duration: {convertMinutesToHoursAndMinutes(countTotalDuration(reportDetail?.timelog))}</h2>
                    </div>
                </div>
            </div>
            <div className="w-100 px-4">
                <table className="pdf-table1 session-table">
                    <thead>
                        <tr>
                            <th>Actions</th>
                            <th>User</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Hours</th>
                            <th>Hour Rate</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportDetail?.timelog?.map((item, index) => (
                            <tr key={index} className={`${index+1 === reportDetail?.length && "remove-border-bottom"} ${item?.isChecked === 0 ? "white-row" : item?.isChecked === 1 ? "green-row" : item?.isChecked === 2 ? "yellow-row" : item?.isChecked === 3 ? "red-row" : ""}`}>
                                <td>
                                    <div className="d-flex">
                                        <div className="pointer white-radio-div"></div>
                                        <div className="pointer green-radio-div"></div>
                                        <div className="pointer yellow-radio-div"></div>
                                        <div className="pointer red-radio-div"></div>
                                    </div>
                                </td>
                                {/* <td className="bg-303778x w-60px">{index + 1}</td> */}
                                <td>
                                    <h6 className="mb-1">{item?.user?.first_name}</h6>
                                    {/* <p className="mb-0">Fund request for staff</p> */}
                                </td>
                                <td>{formateDateDayMonth(item?.start_date_time)}  </td>
                                <td>{formateTimeHoursMints(item?.start_date_time)} - {formateTimeHoursMints(item?.end_date_time)}</td>
                                <td>
                                    <h6 className="mb-1">{convertMinutesToHoursAndMinutes(item?.duration)}</h6>
                                </td>
                                <td>
                                    <h6 className="mb-1">${item?.user?.hour_rate}</h6>
                                </td>
                                <td className="bg-303778x w-100px">${Number(item?.user?.hour_rate * (Number(item?.duration) / 60)).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="d-flex w-100 px-4 py-3 invoice-header">
                <div className="w-65">
                    {/* <h3 className="pt-4">Thank You</h3> */}
                </div>
                <div className="w-35">
                    <div>
                        <table className="pdf-table2">
                            <thead>
                                <tr className="bb-rgb-237">
                                    <th>Label</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bb-303778">
                                    <th className="clr-303778x">Total Duration</th>
                                    <th className="clr-303778x">{convertMinutesToHoursAndMinutes(countTotalDuration(reportDetail?.timelog))}</th>
                                </tr>
                                <tr>
                                    <th>Total Amount</th>
                                    <th>${totalUserAmount(reportDetail?.timelog).toFixed(2)}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>            
        </div>
    )
});