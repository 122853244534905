import React from "react";
import logo from "../../assets/nav-logo.png";
import { FaUserAlt } from "react-icons/fa";
import { GoSignIn } from "react-icons/go";
import { PiSignInBold } from "react-icons/pi";
import { FaSignInAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const HomeNav = () => {
    const navigate = useNavigate();

    return (
        <div className="home-nav">
            <div className="container">
                <div className="d-flex">
                    <div className="w-25">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="w-75">
                        <div>
                            <ul className="home-nav-ul pt-2">
                                <li className="me-4 pointer" onClick={() => navigate('/signup')}>
                                    <div>
                                        <FaUserAlt />
                                    </div>
                                    <span className="ms-3 d-inline-block ">SIGN UP</span>
                                </li>
                                <li>
                                    <div style={{paddingTop: '5px'}}>
                                        <FaSignInAlt height='40px' />
                                    </div>
                                    <span className="ms-3 d-inline-block pointer" onClick={() => navigate('/login')}>SIGN IN</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeNav;