import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DataTableBase from "../../components/common/DateTableBase";
import axios from "axios";
import { apiEndPoint } from "../../App";

function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " mins" : "";
  
    return hoursText + minutesText;
}





const CompaniesList = () => {
    const [startDate, setStartDate] = useState();
    const [pending, setPending] = useState(true);
    const [flag, setFlag] = useState(2);
    const [companies, setCompanies] = useState([]);
    
    const columns2 = [
        {
            name: 'Company Name',
            selector: row => row?.company_name,
        },
        
        {
            name: 'Email',
            selector: row => row?.email,
        },    
        {
            name: 'Country',
            selector: row => row?.country,
        },
        {
            name: 'Status',
            selector: row => row?.status === 1 ?<span className="text-success">Approved</span> : <button className="approve-btn" onClick={() => {approveStatus(row?.id)}}>Approve</button>,
        },
        {
            name: 'Delete',
            selector: row => <button className="approve-btn bg-danger" onClick={() => {deleteCompany(row?.id)}}>Delete</button>,
        },
    ];

    useEffect(() => {
        const token = localStorage.getItem("pca_token");
        axios.get(`${apiEndPoint}/api/admin/companies`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setCompanies(res?.data?.companies);
            setPending(false);
        }).catch((err) => {
            console.log("err : ", err);
        })
    }, [flag])
    
    
    const approveStatus = (id) => {
        setPending(true);
        const token = localStorage.getItem("pca_token");
        axios.get(`${apiEndPoint}/api/admin/company/approve/${id}`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setFlag(flag+1);
            setPending(false);
        }).catch((err) => {
            console.log("err : ", err);
        })
    }
    const deleteCompany = (id) => {
        setPending(true);
        const token = localStorage.getItem("pca_token");
        axios.get(`${apiEndPoint}/api/admin/company/delete/${id}`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setFlag(flag+1);
            setPending(false);
        }).catch((err) => {
            console.log("err : ", err);
        })
    }

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];
      
    const currentDate = new Date();
    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
    
    const formattedDate = `${day} ${months[monthIndex]} ${year}`;


    return (
        <div className="customer-dashboard">
            <div className="d-flex">
                <div className="w-25">
                    <h3 className="mb-0 text2">Dashboard</h3>
                    <p className="text3">{formattedDate}</p>
                </div>
                <div className="w-75">
                    {/* <ul className="dashboard-bar-ul">
                        <li>
                            <p className="text4">Select Range</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li> - </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li>
                            <button className="filter-btn">Filter</button>
                        </li>
                    </ul> */}
                </div>
            </div>


            
            <div className="table-card mt-30">
                {/* {projects && ( */}
                    <DataTableBase 
                        title="Project"
                        columns={columns2}
                        data={companies}
                        progressPending={pending}
                    />

                {/* )} */}
            </div>

        </div>
    )
}

export default CompaniesList;