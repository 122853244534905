import React, { useEffect, useState } from "react";
import DataTableBase from "../../../components/common/DateTableBase";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { companyURL } from "../../../App";
import { useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../../../components/common/CustomLoader";
import { ToastContainer, toast } from "react-toastify";

function dateFormat(dt){
    const currentDate = new Date(dt);
    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
  
    const formattedDate = `${year}-${monthIndex+1}-${day}`;
    return formattedDate;
}

function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " min" : "";
  
    return hoursText + minutesText;
}

const ProjectSessions = () => {
    const [startDate, setStartDate] = useState();
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    
    const [pending, setPending] = useState(true);

    const navigate = useNavigate();

    const [tog, setTog] = useState(false);
    const [sessionId, setSessionID] = useState(null);
    const [allData, setAllData] = useState(null);
    const [arr, setArr] = useState([]);
    const [pagination, setPagination] = useState(1);

    const {id} = useParams();
    

    const fetchData = async () => {
        // Mock API call
        // const response = await fetch('your-api-endpoint');
        // const dataFromAPI = await response.json();
        // setData(dataFromAPI);
        const token = localStorage.getItem("token");

        axios.post(`${companyURL}/projectSessionsByID?page=${pagination}`, {project_id: id},{
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            const temData = res?.data?.data?.data;
            setData(temData);
            setData2(temData);
            setAllData(res?.data?.data);
            // setPending(false);
        }).catch((err) => {
            // setPending(false);
            console.log("err : ", err);
        })
    };

    const handleButtonClick = async (row, actionType) => {
        // Call API to save the action in the database
        await saveActionToDatabase(row?.id, actionType);
        // Update the data after action is saved
        
    };
    
    const saveActionToDatabase = async (rowId, actionType) => {
        // Mock API call to save action to database
        // Replace this with your actual API endpoint and logic
        // await fetch(`your-save-action-api-endpoint`, {
        //   method: 'POST',
        //   body: JSON.stringify({ rowId, actionType }),
        //   headers: {
        //     'Content-Type': 'application/json'
        //   }
        // });
        const token = localStorage.getItem("token");

        axios.post(`${companyURL}/updateSessionColorByID`, {
            session_id: rowId, 
            colorValue: actionType
        },
        {headers: {
            Authorization: `Bearer ${token}`
        }}).then((res) => {
            console.log("response status api : ", res);
            toast.success("Status updated successfully");
            fetchData();
        }).catch((err) => {
            console.log("Error status api : ", err);
        })
    };        

    const columns = [
        {
            name: 'Action',
            selector: (row, index) => {
                console.log("row: ", index, row);
                return (                
                    <>
                        <input type="radio" defaultChecked={row?.isChecked === 0 && true} onChange={() => handleButtonClick(row, 0)} className="radio-white" name={`type${index}`} />
                        <input type="radio" defaultChecked={row?.isChecked === 1 && true} onChange={() => handleButtonClick(row, 1)} className="radio-success mx-2" name={`type${index}`} />
                        <input type="radio" defaultChecked={row?.isChecked === 2 && true} onChange={() => handleButtonClick(row, 2)} className="radio-yellow me-2" name={`type${index}`} />
                        <input type="radio" defaultChecked={row?.isChecked === 3 && true} onChange={() => handleButtonClick(row, 3)} className="radio-danger" name={`type${index}`} />
                        
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Project',
            selector: row => row.project_name,
        },
        {
            name: 'Task',
            center: true,
            selector: row => row.task_heading,
        },
        // {
        //     name: 'Start time',
        //     center: true,
        //     selector: row => row.btime,
        // },
        {
            name: 'Duration',
            selector: row => convertMinutesToHoursAndMinutes(row?.duration),
            center: true,
            width: '300px' 
        },
        {
            name: 'Tools',
            center: true,
            selector: row => <>
                {/* <svg  onClick={() => { setSessionID(row?.id); setTog(true); }} className="me-3 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="21.111" height="19" viewBox="1566.706 322 21.111 19"><g data-name="Icon feather-monitor"><path d="M1568.817 322h16.89c1.165 0 2.11.945 2.11 2.111v10.556a2.111 2.111 0 0 1-2.11 2.11h-16.89a2.111 2.111 0 0 1-2.11-2.11V324.11c0-1.166.944-2.111 2.11-2.111Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 10"/><path d="M1573.04 341h8.444" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 11"/><path d="M1577.262 336.778V341" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 12"/></g></svg> */}
                <svg  onClick={() => navigate(`/company/sessions/${id}/screenshots/${row?.id}`)} className="me-3 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="21.111" height="19" viewBox="1566.706 322 21.111 19"><g data-name="Icon feather-monitor"><path d="M1568.817 322h16.89c1.165 0 2.11.945 2.11 2.111v10.556a2.111 2.111 0 0 1-2.11 2.11h-16.89a2.111 2.111 0 0 1-2.11-2.11V324.11c0-1.166.944-2.111 2.11-2.111Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 10"/><path d="M1573.04 341h8.444" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 11"/><path d="M1577.262 336.778V341" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 12"/></g></svg>
                <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="17.183" height="21.148" viewBox="1607.818 319.852 17.183 21.148"><path d="M1609.14 326.46v13.218c0 .727.594 1.322 1.321 1.322h11.896c.727 0 1.322-.595 1.322-1.322v-13.217h-14.54Zm3.965 11.896h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.644 0h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.974-15.86h-4.296v-1.653a.994.994 0 0 0-.991-.991h-4.626a.994.994 0 0 0-.991.991v1.652h-4.296a.994.994 0 0 0-.992.992v1.652H1625v-1.652a.994.994 0 0 0-.991-.992Zm-5.617 0h-3.966v-1.306h3.966v1.305Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
            </>,
        },
    ];


    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];
      
      const currentDate = new Date();
      const day = currentDate.getDate();
      const monthIndex = currentDate.getMonth();
      const year = currentDate.getFullYear();
      
      const formattedDate = `${day} ${months[monthIndex]} ${year}`;
      
    //   console.log(formattedDate);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(startDate){
            setTog(false); setData2([]); setArr([]); setSessionID(null);
            setPending(true);
            axios.post(`${companyURL}/projectSessionsByID`, {project_id: id,  date: dateFormat(startDate)},{
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                const temData = res?.data?.data?.data;
                setData(temData);
                setData2(temData);
                setAllData(res?.data?.data);
                setPending(false);
            }).catch((err) => {
                setPending(false);
                console.log("err : ", err);
            })
        } else {
            axios.post(`${companyURL}/projectSessionsByID?page=${pagination}`, {project_id: id},{
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                const temData = res?.data?.data?.data;
                setData(temData);
                setData2(temData);
                setAllData(res?.data?.data);
                setPending(false);
            }).catch((err) => {
                setPending(false);
                console.log("err : ", err);
            })
        }
    }, [startDate, pagination])

    const [pending2, setPending2] = useState(false);

    const getHighlightedDates = () => {
        // Dummy data for demonstration, replace with actual logic to fetch booked or unavailable dates
        const bookedDates = ['2024-04-18', '2024-04-20', '2024-04-25'];
      
        const highlightedDates = bookedDates.map(date => {
          return new Date(date);
        });
      
        return highlightedDates;
    };
      
    const handleChange = (i, value) => {
        let prevData = [...data];
        console.log("prevData[i] : ", prevData[i]);
        prevData[i] = {
            ...prevData[i],
            isChecked: value
        };
        console.log("prevData[i] after : ", prevData[i]);
        setData(prevData);
    }

    const handleFilter = (val) => {
        if(val === "all"){
            setData([...data2]);
        } else {
            const filteredData = [];
            data2?.map((item => {
                if(item?.isChecked === val){
                    filteredData.push(item);
                }
            })) 
            setData(filteredData);
        }
    }

    const prevFunc = () => {
        if(pagination > 1){
            setPending(true);
            setPagination(pagination - 1);
        }
    }
    
    const nextFunc = () => {
        if(pagination < allData?.last_page){
            setPending(true);
            setPagination(pagination + 1);
        }
    }

    return (
        <div className="customer-dashboard">
            <ToastContainer />
            <div className="d-flex">
                <div className="w-50 d-flex">
                    <div>
                        <h3 className="mb-0 text2">Work Dashboard - {data?.length > 0 && data[0]?.project_name}</h3>
                        {/* <p className="text3">22 September 2023</p> */}
                    </div>
                    {/* <div>
                        <ul className="dashboard-bar-ul ms-2">
                            <li>
                                <p className="text4">Personnel</p>
                            </li>
                            <li>
                                <div className="p-relative">
                                    <select className="form-control users-list-select" placeholder="Poom">
                                        <option>Poom</option>
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div> */}
                </div>
                <div className="w-50">
                    <ul className="dashboard-bar-ul">
                        <li>
                            <p className="text4">Select Day</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                    highlightDates={getHighlightedDates()}
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        {/* <li>
                            <button className="filter-btn">Filter</button>
                        </li> */}
                    </ul>
                </div>
            </div>

            <p className="text8">Work Session {formattedDate}</p>
            
            <div className="table-card mt-30">
                {/* <DataTableBase 
                    title="Session"
                    columns={columns}
                    data={data}
                    pagination
                    progressPending={pending}
                    conditionalRowStyles={conditionalRowStyles}
                /> */}
                {pending ? (
                    <CustomLoader />
                ) : (
                    <>
                        <div className="d-flex justify-content-end mb-3">
                            <div onClick={() => {handleFilter('all');}} className="pointer text-center white-radio-div bg-blue">All</div>
                            <div onClick={() => {handleFilter(0);}} className="pointer white-radio-div"></div>
                            <div onClick={() => {handleFilter(3);}} className="pointer red-radio-div"></div>
                            <div onClick={() => {handleFilter(1);}} className="pointer green-radio-div"></div>
                            <div onClick={() => {handleFilter(2);}} className="pointer yellow-radio-div"></div>
                        </div>
                        <table className="session-table">
                            <thead>
                                <tr>
                                    <th>Actions</th>
                                    <th>Project</th>
                                    <th>Task</th>
                                    <th>Duration</th>
                                    <th>Cost</th>
                                    <th>Tools</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => (
                                    <tr key={index} className={`${index+1 === data?.length && "remove-border-bottom"} ${item?.isChecked === 0 ? "white-row" : item?.isChecked === 1 ? "green-row" : item?.isChecked === 2 ? "yellow-row" : item?.isChecked === 3 ? "red-row" : ""}`}>
                                        <td>
                                            {/* <div className="d-flex">
                                                <div onClick={() => {handleChange(index, 0); handleButtonClick(item, 0);}} className="pointer white-radio-div"></div>
                                                <div onClick={() => {handleChange(index, 1); handleButtonClick(item, 1);}} className="pointer green-radio-div"></div>
                                                <div onClick={() => {handleChange(index, 2); handleButtonClick(item, 2);}} className="pointer yellow-radio-div"></div>
                                                <div onClick={() => {handleChange(index, 3); handleButtonClick(item, 3);}} className="pointer red-radio-div"></div>
                                            </div> */}
                                            <div className="d-flex">
                                                {item?.isChecked === 0 ? ( 
                                                    <div className="pointer white-radio-div"></div>
                                                ) : item?.isChecked === 1 ? (
                                                    <div className="pointer green-radio-div"></div>
                                                ) : item?.isChecked === 2 ?
                                                    <div className="pointer yellow-radio-div"></div>
                                                : <div className="pointer red-radio-div"></div>
                                                }
                                            </div>
                                            {/* <input type="radio" defaultChecked={item?.isChecked === 0 && true} onChange={() => handleButtonClick(item, 0)} className="radio-white" name={`type${index}`} />
                                            <input type="radio" defaultChecked={item?.isChecked === 1 && true} onChange={() => handleButtonClick(item, 1)} className="radio-success mx-2" name={`type${index}`} />
                                            <input type="radio" defaultChecked={item?.isChecked === 2 && true} onChange={() => handleButtonClick(item, 2)} className="radio-yellow me-2" name={`type${index}`} />
                                            <input type="radio" defaultChecked={item?.isChecked === 3 && true} onChange={() => handleButtonClick(item, 3)} className="radio-danger" name={`type${index}`} />                                                 */}
                                        </td>
                                        <td>{item?.project_name}</td>
                                        <td>{item?.task_heading}</td>
                                        <td>{convertMinutesToHoursAndMinutes(Number(item?.duration))}</td>
                                        <td>$821</td>
                                        <td>
                                            <svg  onClick={() => navigate(`/company/sessions/${id}/screenshots/${item?.id}`)} className="me-3 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="21.111" height="19" viewBox="1566.706 322 21.111 19"><g data-name="Icon feather-monitor"><path d="M1568.817 322h16.89c1.165 0 2.11.945 2.11 2.111v10.556a2.111 2.111 0 0 1-2.11 2.11h-16.89a2.111 2.111 0 0 1-2.11-2.11V324.11c0-1.166.944-2.111 2.11-2.111Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 10"/><path d="M1573.04 341h8.444" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 11"/><path d="M1577.262 336.778V341" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 12"/></g></svg>
                                            {/* <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="17.183" height="21.148" viewBox="1607.818 319.852 17.183 21.148"><path d="M1609.14 326.46v13.218c0 .727.594 1.322 1.321 1.322h11.896c.727 0 1.322-.595 1.322-1.322v-13.217h-14.54Zm3.965 11.896h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.644 0h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.974-15.86h-4.296v-1.653a.994.994 0 0 0-.991-.991h-4.626a.994.994 0 0 0-.991.991v1.652h-4.296a.994.994 0 0 0-.992.992v1.652H1625v-1.652a.994.994 0 0 0-.991-.992Zm-5.617 0h-3.966v-1.306h3.966v1.305Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon metro-bin"/></svg> */}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.642" height="21.438" viewBox="1593 437.562 18.642 21.438"><g data-name="bin-delete-recycle-svgrepo-com"><path d="M1610.71 438.494h-6.525a.932.932 0 0 0-.932-.932h-1.864a.932.932 0 0 0-.932.932h-6.525a.932.932 0 1 0 0 1.864h16.778a.932.932 0 0 0 0-1.864Z" fill="#5d5b5b" fill-rule="evenodd" data-name="Path 14"/><path d="M1609.312 441.29a.932.932 0 0 0-.933.932v14.914h-12.117v-14.914a.932.932 0 0 0-1.864 0v15.846c0 .515.417.932.932.932h13.982a.932.932 0 0 0 .932-.932v-15.846a.932.932 0 0 0-.932-.932Z" fill="#5d5b5b" fill-rule="evenodd" data-name="Path 15"/><path d="M1600.923 454.34v-8.39a.932.932 0 0 0-1.864 0v8.39a.932.932 0 0 0 1.864 0Z" fill="#5d5b5b" fill-rule="evenodd" data-name="Path 16"/><path d="M1605.583 454.34v-8.39a.932.932 0 0 0-1.864 0v8.39a.932.932 0 0 0 1.864 0Z" fill="#5d5b5b" fill-rule="evenodd" data-name="Path 17"/></g></svg>
                                        </td>
                                    </tr>                            
                                ))}
                            </tbody>
                        </table>
                        <div className="w-100 d-flex justify-content-end mb-3 mt-4">
                            <div onClick={prevFunc} className={'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                Prev
                            </div>                        
                            <div className={'bg-0facd5 cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                {pagination}
                            </div>
                            <div onClick={nextFunc} className={'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                Next
                            </div>
                        </div>
                    </>    
                )}
            </div>
        </div>
    )
}

export default ProjectSessions;