import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DataTableBase from "../../../components/common/DateTableBase";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { companyURL, imgEndPoint } from "../../../App";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomLoader from "../../../components/common/CustomLoader";
import { UserLabel } from "./AddProject";
import { convertMinutesToHoursAndMinutes } from "../../../utility/Index";
import { FaRegUserCircle } from "react-icons/fa";


const Tasks = () => {
    const [department, setDepartment] = useState(null);
    const [data, setData] = useState(null);
    const [tasks, setTasks] = useState([]);
    
    const [task, setTask] = useState({
        heading: null,
        status: 1,
        description: null
    });
    
    const [loadings, setLoadings] = useState({
        load1: false,
        load2: false,
    })

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [departments, setDepartments] = useState([]);
    
    const [pending, setPending] = useState(true);
    const [projectId, setProjectId] = useState(null);

    const {id} = useParams();

   const [flag, setFlag] = useState(1);
    const [loading, setLoading] = useState(false);

    const showSuccessAlert = (msg) => {
        console.log("success alert")
        toast.success(msg);
    };

    // Function to show an error alert
    const showErrorAlert = (err) => {
        toast.error(err);
    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handleClose2 = () => setShow2(false);
    const handleShow2 = (taskId) => {
        setLoadings((prev) => ({
            ...prev,
            load2: true,
        }))
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/getTaskByID`, {task_id: taskId}, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("Task id res : ", res);
            const resData = res?.data?.data;
            const temDepartment = res?.data?.data;
            setTask((prev) => ({
                ...prev,
                heading: resData?.task?.heading,
                status: resData?.task?.status,
                description: resData?.task?.description,

            }))
            const tempOption = [];
            resData?.users?.map(item => {
                // tempOption.push({label: `${item?.first_name} ${item?.last_name}`, value: item?.id})
                tempOption.push({value: item?.id, label: <UserLabel url={item?.profileImage}  name={`${item?.first_name} ${item?.last_name}`} /> });
            })
            setSelectedUsers(tempOption);
            setLoadings((prev) => ({
                ...prev,
                load2: false,
            }))
            // setPending(false);
        }).catch((err) => {
            // showErrorAlert(err?.response?.data?.error[0])
            setLoadings((prev) => ({
                ...prev,
                load2: false,
            }))
            console.log("err : ", err);
        })

        setShow2(true);
    }   
    
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);


    const columns2 = [
        {
            name: 'Heading',
            selector: row => row?.heading,
        },
        {
            name: 'Duration',
            selector: row => row?.timelog_sum_duration ? convertMinutesToHoursAndMinutes(row?.timelog_sum_duration) : '0 Hrs 0 Mins',
        },
        {
            name: 'Users',
            selector: (row, index) => row?.users?.map((item, ind) => item?.profileImage ? <img style={{marginLeft: ind > 0 ? '-12px' : '0'}} className="profile-table-view" src={`${imgEndPoint}/${item?.profileImage}`} /> : <FaRegUserCircle size="32px" /> ),
        },
        {
            name: 'Status',
            width: '120px',
            selector: row => row?.status === 1 ? "To Do" : row?.status === 2 ? "In Progress" : row?.status === 3 ? "Complete" : "Unknown",
        },
        {
            name: 'Description',
            minWidth: '300px',
            selector: row => row?.description,
        },
        {
            name: 'Update',
            width: '125px',
            selector: row => <button className="edit-btn px-3" onClick={() => {setProjectId(row?.id);  handleShow2(row?.id)}}>Update</button>
        },
        {
            name: 'Delete',
            width: '125px',
            selector: row => <button className="edit-btn px-3" onClick={() => {setProjectId(row?.id); handleShow3();}}>Delete</button>
        }
    ];
    
    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/${id}/tasks`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            const temDepartment = [];
            const resData = res?.data?.data;
            resData?.tasks?.map((item, ind) => {
                temDepartment.push({...item, users: resData?.users[ind]})
            })
            console.log("tem department : ", temDepartment);
            setTasks(temDepartment);
            setData(resData);
            setPending(false);
        }).catch((err) => {
            setPending(false);
            // showErrorAlert(err?.response?.data?.error[0])
            console.log("err : ", err);
        })
    }, [flag])

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/getPersonnelUser`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            const temDepartment = res?.data?.data;
            const tempOption = [];
            temDepartment?.map(item => {
                // tempOption.push({label: `${item?.first_name} ${item?.last_name}`, value: item?.id})
                tempOption.push({value: item?.id, label: <UserLabel url={item?.profileImage}  name={`${item?.first_name} ${item?.last_name}`} /> });
            })
            setOptions(tempOption);
            // setPending(false);
        }).catch((err) => {
            showErrorAlert(err?.response?.data?.error[0])
            console.log("err : ", err);
        })
    }, [flag])


    const onSubmit = () => {
        if(task?.status && task?.heading && selectedUsers){
            setLoadings((prev) => ({
                ...prev,
                load1: true,
            }));
            const token = localStorage.getItem("token");
            let users = [];
            
            selectedUsers?.map(item => {
                users?.push(item?.value);
            })
            axios.post(`${companyURL}/createTask`, {...task, project_id: id,  users: users}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                setFlag(flag + 1);
                setLoadings((prev) => ({
                    ...prev,
                    load1: false,
                }));
                handleClose();
                setTask({heading: null, description: null, status: 1});
                setSelectedUsers([]);
                showSuccessAlert('Users created successfully');
            }).catch((err) => {
                setLoadings((prev) => ({
                    ...prev,
                    load1: false,
                }));
                setLoading(false);
                // showErrorAlert(err?.response?.data?.error[0])
                console.log("err : ", err);
            })
            // handleClose();
        }
    }

    const onSubmit2 = () => {
        if(task?.status && task?.heading && selectedUsers){

            setLoadings((prev) => ({
                ...prev,
                load2: true,
            }));
            const token = localStorage.getItem("token");
            let users = [];
            
            selectedUsers?.map(item => {
                users?.push(item?.value);
            })
            axios.post(`${companyURL}/updateTask`, {...task, task_id: projectId,  users: users}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                setFlag(flag + 1);
                setLoadings((prev) => ({
                    ...prev,
                    load2: false,
                }));
                handleClose();
                setTask({heading: null, description: null, status: 1});
                setSelectedUsers([]);
                showSuccessAlert('Users updated successfully');
                handleClose2();
            }).catch((err) => {
                setLoadings((prev) => ({
                    ...prev,
                    load2: false,
                }));
                setLoading(false);
                // showErrorAlert(err?.response?.data?.error[0])
                console.log("err : ", err);
            })
            // handleClose();
        }
    }

    const delTask = () => {
        if(projectId){
            setLoading(true);
            const token = localStorage.getItem("token");
            axios.post(`${companyURL}/deleteTask`, {task_id: id}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                setFlag(flag + 1);
                setLoading(false);
                showSuccessAlert('Task Deleted Successfully');
            }).catch((err) => {
                console.log("err : ", err);
                setLoading(false);
                showErrorAlert(err?.response?.data?.error && err?.response?.data?.error[0])
            })
            
        }
        handleClose3();
    }

    const handleTaskInput = (e) => {
        const {name, value} = e.target;
        setTask((prev) => ({
            ...prev,
            [name]: value,
        }))
    }


    return (
        <div className="customer-dashboard">
            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {loadings?.load2 ? <CustomLoader /> : (
                    <>
                        <div className="mb-3">
                            <label>Heading</label>
                            <input 
                                type="text" value={task?.heading}
                                name="heading"
                                onChange={handleTaskInput} 
                                className="form-control custom-form-select" 
                                placeholder="Enter task title" 
                            />
                        </div>
                        <div className="mb-3">
                            <label>Assign Users</label>
                            <Select
                                defaultValue={selectedUsers}
                                isMulti
                                name="users"
                                options={options}
                                onChange={setSelectedUsers}
                                className="mt-2 w-100 basic-multi-select"
                                classNamePrefix="select"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Status</label>
                            <select defaultValue={task?.status} className="form-control" onChange={handleTaskInput} name="status">
                                {/* <option>Select Status</option> */}
                                <option value={1}>To Do</option>
                                <option value={2}>In Progress</option>
                                <option value={3}>Complete</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Description</label>
                            <textarea
                                name="description" 
                                type="text" 
                                value={task?.description}
                                onChange={handleTaskInput} 
                                className="form-control custom-form-select" 
                                placeholder="Enter task description" 
                                rows={3}
                            ></textarea>
                        </div>
                    </>
                )}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <Button className="bg-0facd5" onClick={onSubmit2} variant="primary">
                    Update
                </Button>
                </Modal.Footer>
            </Modal>
            {/* add task model  */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                    <div className="mb-3">
                        <label>Heading</label>
                        <input 
                            type="text" value={task?.heading}
                            name="heading"
                            onChange={handleTaskInput} 
                            className="form-control custom-form-select" 
                            placeholder="Enter task title" 
                        />
                    </div>
                    <div className="mb-3">
                        <label>Assign Users</label>
                        <Select
                            // defaultValue={[options[2], options[3]]}
                            isMulti
                            name="users"
                            options={options}
                            onChange={setSelectedUsers}
                            className="mt-2 w-100 basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                    <div className="mb-3">
                        <label>Status</label>
                        <select defaultValue={task?.status} className="form-control" onChange={handleTaskInput} name="status">
                            {/* <option>Select Status</option> */}
                            <option value={1}>To Do</option>
                            <option value={2}>In Progress</option>
                            <option value={3}>Complete</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label>Description</label>
                        <textarea
                            name="description" 
                            type="text" 
                            value={task?.description}
                            onChange={handleTaskInput} 
                            className="form-control custom-form-select" 
                            placeholder="Enter task description" 
                            rows={3}
                        ></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button className="bg-0facd5" onClick={onSubmit} variant="primary">
                    Add
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Are you sure you want to delete this?</h2>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose3}>
                    No
                </Button>
                <Button className="bg-0facd5" onClick={() => delTask(id)} variant="primary">
                    Yes
                </Button>
                </Modal.Footer>
            </Modal>

            <div className="d-flex">
                <div className="w-50">
                    <h3 className="mb-0 text2">Tasks <button className="edit-btn ms-4 px-3" onClick={handleShow}>Add Task</button></h3>
                    {/* <p className="text3">{formattedDate}</p> */}
                </div>
                
            </div>

            <div className="table-card mt-30">
                {loading ? (
                    <div style={{paddingTop: '25vh', paddingBottom: '25vh'}}>
                        <CustomLoader />
                    </div>
                ) : (
                    <DataTableBase 
                        title="Tasks"
                        progressPending={pending}
                        columns={columns2}
                        data={tasks}
                    />
                )}
            </div>
            <ToastContainer />
        </div>
    )
}

export default Tasks;