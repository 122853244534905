import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { companyURL } from "../../../App";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { UserLabel } from "./AddProject";
import CustomLoader from "../../../components/common/CustomLoader";
import { ToastContainer, toast } from "react-toastify";

const EditProject = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [data, setData] = useState([]);
    const [managers, setManagers] = useState([]);

    const [loading, setLoading] = useState({
        load1: true,
        load2: true,
        load3: true,
        load4: false,
        load5: true,
    })

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [options, setOptions] = useState([]);

    const [selectedUsers, setSelectedUsers] = useState([]);


    const [taskTitle, setTaskTitle] = useState('');
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [entries, setEntries] = useState([]);
    const [error, setError] = useState(null);
    const {register, handleSubmit, reset} = useForm()
    const [flag, setFlag] = useState(1);

    const {id} = useParams();
    
    const [project, setProject] = useState(null);

    const handleSubmit2 = () => {
        const tempEmployee = [];
        selectedEmployees.map(item => {
            tempEmployee?.push(item?.value);
        })
        const newEntry = {
          title: taskTitle,
          employee: tempEmployee,
        };
        setEntries([...entries, newEntry]);
        // Clear the form fields after submission
        setTaskTitle('');
        setSelectedEmployees([]);
        handleClose();
    };

    // const months = [
    //     'January', 'February', 'March', 'April', 'May', 'June', 'July',
    //     'August', 'September', 'October', 'November', 'December'
    // ];

    // function formatDate(dt){
    //    const currentDate = new Date(dt);
    //    const day = currentDate.getDate();
    //    const monthIndex = currentDate.getMonth();
    //    const year = currentDate.getFullYear();
       
    //    const formattedDate = `${day} ${months[monthIndex]} ${year}`;
    //   return formattedDate;
    // }

    // Define an array of month names
    const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    function formateDate(datee){

        // Convert the ISO string to a JavaScript Date object
        const date = new Date(datee);

        // Define a time zone offset
        const timeZoneOffset = "+0500"; // Change this to your desired time zone offset

        // Format the date using the desired format
        const formattedDate = `date ${date.toDateString()} ${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()} ${date.toTimeString()} GMT${timeZoneOffset} (Pakistan Standard Time)`;

        console.log(formattedDate);

        return formattedDate;
    }
    

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/projectByID`, {project_id: id}, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("Project res : ", res);
            setProject(res?.data?.data?.project);
            const temp = res?.data?.data?.project;
            const assignedUsers = res?.data?.data?.users;
            setData(res?.data?.data);
            const tempUsers = [];
            reset({
                project_name: temp?.project_name,
                department_id: temp?.department_id,
                hours_allocated: temp?.hours_allocated,
                project_budget: temp?.project_budget,
                manager_id: temp?.manager_id
            })
            assignedUsers?.map(item => {
                tempUsers.push({value: item?.id, label: <UserLabel url={item?.profileImage}  name={`${item?.first_name} ${item?.last_name}`} /> });
            })

            // const userrrs = assignedUsers?.map(item => ({value: item?.id, label: <UserLabel url={item?.profileImage}  name={`${item?.first_name} ${item?.last_name}`} /> }))
            // console.log("userss : ", userrrs)
            setSelectedUsers([...tempUsers]);

            setLoading((prev) => ({
                ...prev,
                load1: false
            }))

            // const rawData = temp?.tasks;
            // const selectedEmployees = rawData?.team_list.split(',').map((employeeId) => Number(employeeId));
            // const taskData = {
            //     title: rawData?.heading,
            //     employees: selectedEmployees,
            //     // Other task data fields here
            // };
            // setEntries(taskData)

            // const sd= formateDate(temp?.start_date);
            // const dl= formateDate(temp?.deadline);
            // console.log(sd, dl);
            // setStartDate(sd);
            // setStartDate(dl);
            // setPending(false);
            // setEntries(temp?.tasks);
        }).catch((err) => {
            setLoading((prev) => ({
                ...prev,
                load1: false
            }))
            console.log("err : ", err);
        })
    }, [flag])

    // console.log("projects", dailyProgress);

    

    useEffect(() => {
        console.log("entries : ", entries);
    }, [entries])

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/getPersonnelUser`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            const temUsers = res?.data?.data;
            const userOptions = [];
            const tempOption = [];
            temUsers?.map(item => {
                userOptions?.push({value: item?.id, label: `${item?.first_name} ${item?.last_name}`});
                tempOption.push({value: item?.id, label: <UserLabel url={item?.profileImage}  name={`${item?.first_name} ${item?.last_name}`} /> });
            })
            setOptions(tempOption);
            setUsers(userOptions);
            setLoading((prev) => ({
                ...prev,
                load2: false
            }))
        }).catch((err) => {
            setLoading((prev) => ({
                ...prev,
                load2: false
            }))
            console.log("err : ", err);
        })
    }, [flag])

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/getPersonnelManager`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setManagers(res?.data?.data);
            setLoading((prev) => ({
                ...prev,
                load3: false
            }))
        }).catch((err) => {
            setLoading((prev) => ({
                ...prev,
                load3: false
            }))
            console.log("err : ", err);
        })
    }, [flag])
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);   
 


    const onSubmit = (data) => {
        console.log("data : ", data);
        setLoading((prev) => ({
            ...prev,
            load4: true
        }))
        const token = localStorage.getItem("token");
        
        let users = [];
        selectedUsers?.map(item => {
            users?.push(item?.value);
        })
        console.log(startDate)
        axios.post(`${companyURL}/updateProject`, {...data, project_id: id, start_date: startDate ? startDate : data?.project?.start_date, deadline: endDate ? endDate : data?.project?.deadline, projectUsers: users?.length === 0 ? null : users}, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setLoading((prev) => ({
                ...prev,
                load4: false
            }))
            toast.success("Project updated successfully.");
            setTimeout(() => {
                navigate('/company/project');
            }, 4000)
        }).catch((err) => {
            setLoading((prev) => ({
                ...prev,
                load4: false
            }))
            console.log("err : ", err);
            // setError(err?.response?.data?.error[0]);
        })
    }

    console.log("Selected user : ", selectedUsers)


    return (
        <div className="customer-dashboard">
            <ToastContainer />
            <h3 className="mb-0 text2">Edit Project</h3>
            
            <div className="table-card mt-30">
                <h2 className="text10">Project Detail</h2>
                {(loading?.load1 || loading?.load2 || loading?.load3 || loading?.load4) ? (
                    <CustomLoader />
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="w-100 d-flex mt-4">
                            <div className="d-flex w-50">
                                <div className="w-25">
                                    <p className="text11 pt-3">Project Name</p>
                                    <p className="text11 pt-1">Department</p>
                                    <p className="text11">Assign User</p>

                                </div>
                                <div>
                                    <div>
                                        <input placeholder="Enter project name" {...register("project_name", {required: true})}  className="form-select custom-form-select" />
                                    </div>
                                    <div>
                                        <input placeholder="Enter department id" {...register("department_id", {required: true})}  className="form-select custom-form-select" />
                                    </div>
                                    <div>
                                        {/* <p className="text12 mb-2 pt-2 mt-1">
                                            <button type="button" className="edit-btn py-1" onClick={handleShow}>Create Task</button>
                                        </p> */}
                                        {/* <Select
                                            defaultValue={[options[2], options[3]]}
                                            isMulti
                                            name="colors"
                                            options={options}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        /> */}
                                        <div className="mt-1">
                                            <Select
                                                // defaultValue={data?.users?.map(item => ({value: item?.id, label: <UserLabel url={item?.profileImage}  name={`${item?.first_name} ${item?.last_name}`} /> }))}
                                                defaultValue={selectedUsers}
                                                isMulti
                                                name="colors"
                                                options={options}
                                                onChange={setSelectedUsers}
                                                className="mt-2 w-100 basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        {/* <div>
                                            <ul>
                                                {entries?.map((item, index) => (
                                                    <li key={index}>{item?.title}</li>
                                                ))}
                                            </ul>
                                        </div> */}
                                    </div>
                                    {/* <button className="edit-btn mt-2">Edit</button> */}
                                </div>
                            </div>

                            
                            <div className="w-50 d-flex">
                                <div className="w-25">
                                    <p className="text11">Manager</p>
                                    <p className="text11 mt-1 pt-1">Start</p>
                                    <p className="text11 pt-2">Finish</p>
                                    <p className="text11 pt-1">Time Allocated</p>
                                    <p className="text11 pt-1">Budget</p>
                                </div>
                                <div>
                                    <div>
                                        <select {...register("manager_id", {required: true})} className="form-select custom-form-select" style={{marginTop: '-10px'}}>
                                            <option>Select Manager</option>
                                            {managers?.map((item, index) => (
                                                <option value={item?.id} key={index}>{item?.first_name} {item?.last_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mt-2">
                                        <div className="p-relative" >
                                            <DatePicker 
                                                selected={startDate} 
                                                onChange={(date) => {console.log("date",date); setStartDate(date)}} 
                                                placeholderText="DD-MM-YYYY "
                                                className="datepickerr py-2 bg-f7f8fc"
                                            />
                                            <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="20" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                                        </div>
                                        
                                    </div>
                                    <div className="mt-2">
                                        <div className="p-relative" >
                                            <DatePicker 
                                                selected={endDate} 
                                                onChange={(date) => setEndDate(date)} 
                                                placeholderText="DD-MM-YYYY"
                                                className="datepickerr py-2 bg-f7f8fc"
                                            />
                                            <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="20" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                                        </div>
                                        
                                    </div>
                                    <div>
                                        <input {...register("hours_allocated")} className="form-select custom-form-select" />
                                    </div>
                                    <div>
                                        <input {...register("project_budget")} className="form-select custom-form-select" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div>
                            <button className="back-btn">back</button>
                            <button type="submit" className="edit-btn mt-2 ms-3">Submit</button>
                        </div>
                    </form>
                )}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Create Task</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label>Task Title</label>
                            <input 
                                type="text" value={taskTitle}
                                onChange={(e) => setTaskTitle(e.target.value)} 
                                className="form-control custom-form-select" 
                                placeholder="Enter task title" 
                            />
                        </div>
                        <div>
                            <label>Task Selected</label>
                            <Select
                                // defaultValue={selectedUsers}
                                isMulti
                                name="colors"
                                options={users}
                                value={selectedEmployees}
                                onChange={(selectedOptions) => setSelectedEmployees(selectedOptions)}
                                className="basic-multi-select basic-multi-select2"
                                classNamePrefix="select"
                            />
                            
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button className="bg-0facd5" onClick={handleSubmit2} variant="primary">
                        Create
                    </Button>
                    </Modal.Footer>
                </Modal>
                {/* <div className="w-100 mt-3">
                    <h4 className="text14 w-100">Password</h4>
                    <div className="d-flex w-50">
                        <div className="d-flex w-25 pt-3">
                            <p className="text11">Reset password</p>
                        </div>
                        <div className="d-flex pt-1">
                            <div className="p-relative">
                                <input type="password" placeholder="Enter new password" className="form-select custom-form-select" />
                                <svg className="eye-icon" xmlns="http://www.w3.org/2000/svg" width="33.75" height="22.5" viewBox="973.25 716.75 33.75 22.5"><path d="M1006.796 727.145c-3.177-6.2-9.468-10.395-16.671-10.395-7.203 0-13.495 4.198-16.671 10.395a1.896 1.896 0 0 0 0 1.71c3.177 6.2 9.468 10.395 16.671 10.395 7.203 0 13.495-4.198 16.671-10.395a1.895 1.895 0 0 0 0-1.71Zm-16.671 9.293a8.438 8.438 0 1 1 8.438-8.438 8.433 8.433 0 0 1-8.438 8.438Zm0-14.063c-.502.007-1 .082-1.483.222a2.804 2.804 0 0 1-3.92 3.92 5.612 5.612 0 1 0 5.403-4.142Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-eye"/></svg>
                            </div>
                            <div>
                                <button className="genrate-pass-btn mt-2 ms-3">Generate</button>    
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <button className="back-btn">back</button>
                        <button className="edit-btn mt-2 ms-3">Accept</button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default EditProject;