import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Customer from './components/Customer';
import CustomerDashboard from './users/customer/pages/Dashboard';
import CustomerPersonnel from './users/customer/pages/Personnel';
import WorkDetail from './users/customer/pages/WorkDetail';
import Profile from './users/customer/pages/Profile';
import Company from './users/manager';
import ManagerDashboard from './users/manager/pages/ManagerDashboard';
import Personal from './users/manager/pages/Personal';
import PersonalWorkDetail from './users/manager/pages/PersonalWorkDetail';
import ManagerProfile from './users/manager/pages/ManagerProfile';
import EditUser from './users/manager/pages/EditUser';
import AddUser from './users/manager/pages/AddUser';
import Projects from './users/manager/pages/Projects';
import AddProject from './users/manager/pages/AddProject';
import ProjectsDetail from './users/manager/pages/ProjectDetail';
import UserProfile from './users/manager/pages/UserProfile';
import EditProject from './users/manager/pages/EditProject';
import ProjectSessions from './users/manager/pages/ProjectSessions';
import Departments from './users/manager/pages/Departments';
import Toast from './users/manager/pages/Toast';
import Home from './pages/Home';
import Signup from './pages/Signup';
import AdminLogin from './users/admin/AdminLogin';
import Admin from './users/admin/Index';
import CompaniesList from './users/admin/CompaniesList';
import Manager from './users/managers/Index';
import PersonalUser from './users/managers/pages/PersonalUsers';
import PersonalUserDetail from './users/managers/pages/PersonalUserDetail';
import ReportProject from './users/manager/pages/ReportProject';
import ReportEmployee from './users/manager/pages/ReportEmployee';
import ReportManager from './users/manager/pages/ReportManager';
import 'react-toastify/dist/ReactToastify.css';
import ProjectSessionScreenShots from './users/manager/pages/ProjectSessionScreenShots';
import Tasks from './users/manager/pages/Tasks';
import Slider from './pages/Slider';
import ProjectReportDetail from './users/manager/pages/ProjectReportDetail';


// export const companyURL = 'https://screenmeter.trigondigital.net/api/company';
export const companyURL = 'https://backend.screenpc.com/api/company';
export const apiEndPoint = `https://backend.screenpc.com`;
export const imgEndPoint = `https://backend.screenpc.com/uploads/profileImages`;



function App() {

  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path='/adminlogin' element={<AdminLogin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path='/admin' element={<Admin />} >
          <Route index element={<CompaniesList />} />
        </Route>
        <Route path='/manager' element={<Manager />}>
          <Route index element={<PersonalUser />} />
          <Route path='/manager/detail/:id' element={<PersonalUserDetail />} />
        </Route>
        <Route path="/user" element={<Customer />}>
          <Route index element={<CustomerDashboard />} />
          <Route path='personnel' element={<CustomerPersonnel />} />
          <Route path='workdetail' element={<WorkDetail />} />
          <Route path='profile' element={<Profile />} />
        </Route>
        <Route path='/company' element={<Company />}>
          <Route index element={<ManagerDashboard />} />
          <Route path='personnel' element={<Personal />} />
          <Route path='personnel/workdetail/:id/:name' element={<PersonalWorkDetail />} />
          <Route path='personnel/manager/:id' element={<ManagerProfile />} />
          <Route path='personnel/employee/:id' element={<UserProfile />} />
          <Route path='personnel/edit/:type/:id' element={<EditUser />} />
          <Route path='personnel/add' element={<AddUser />} />
          <Route path='project' element={<Projects />} />
          <Route path='project/detail/:id' element={<ProjectsDetail />} />
          <Route path='project/tasks/:id' element={<Tasks />} />
          <Route path='project/add' element={<AddProject />} />
          <Route path='project/edit/:id' element={<EditProject />} />
          <Route path='sessions/:id' element={<ProjectSessions />} />
          <Route path='sessions/:id/screenshots/:sid' element={<ProjectSessionScreenShots />} />
          {/* <Route path='sessions' element={<ProjectSessions />} /> */}
          <Route path='departments' element={<Departments />} />
          <Route path='toast' element={<Toast />} />
          <Route path='project-report' element={<ReportProject />} />
          <Route path='employee-report' element={<ReportEmployee />} />
          <Route path='manger-report' element={<ReportManager />} />
          <Route path='project-report/detail/:id' element={<ProjectReportDetail />} />
        </Route>
        <Route path='/slider' element={<Slider />} />
      </Routes>
    </div>
  );
}

export default App;
