import React, { useEffect, useState } from "react";
import navLogo from "../../assets/nav-logo.png"
import { useNavigate } from "react-router-dom";


const Navbar = ({type}) => {
    const [nav, setNav] = useState(1);
    const navigate = useNavigate();

    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     if(!token){
    //         navigate("/login");
    //     }
    // }, [])

    return (
        <div className="navbar-wrapper">
            <div className="navbar-c">
                <div className="w-100">
                    <img src={navLogo} className="nav-logo" alt="nav-logo" />
                </div>
                <p className="text1 ms-1">MAIN MENU</p>
                {type === 1 ? (<>
                    <ul className="navbar-ul">
                        <li className={nav === 1 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company'); setNav(1); }}>                    
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19"><path d="M0 10.556h8.444V0H0v10.556ZM0 19h8.444v-6.333H0V19Zm10.556 0H19V8.444h-8.444V19Zm0-19v6.333H19V0h-8.444Z"  fill-rule="evenodd" data-name="Icon material-dashboard"/></svg>
                            <span>Dashboard</span>
                        </li>
                        <li className={nav === 2 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/personnel'); setNav(2); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.25" height="19.25" viewBox="16 15 19.25 19.25">
                                <path d="M25.625 15A9.623 9.623 0 0 0 16 24.625a9.623 9.623 0 0 0 9.625 9.625 9.623 9.623 0 0 0 9.625-9.625A9.623 9.623 0 0 0 25.625 15Zm0 3.726a3.415 3.415 0 1 1 0 6.83 3.415 3.415 0 0 1 0-6.83Zm0 13.35a7.437 7.437 0 0 1-5.686-2.646c.73-1.374 2.158-2.321 3.823-2.321a.95.95 0 0 1 .276.043 5.139 5.139 0 0 0 1.587.267c.555 0 1.087-.104 1.587-.267a.95.95 0 0 1 .276-.043c1.665 0 3.093.947 3.823 2.32a7.437 7.437 0 0 1-5.686 2.648Z"  fill-rule="evenodd" data-name="Icon awesome-user-circle"/>
                            </svg>
                            <span>Personnel</span>
                        </li>
                        <li className={nav === 3 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/project'); setNav(3); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="19.25" viewBox="18.406 15 14.438 19.25"><path d="M31.04 17.406H28.03A2.408 2.408 0 0 0 25.625 15a2.408 2.408 0 0 0-2.406 2.406H20.21c-.996 0-1.805.809-1.805 1.805v13.234c0 .997.809 1.805 1.805 1.805h10.828c.996 0 1.805-.808 1.805-1.805V19.211c0-.996-.809-1.805-1.805-1.805Zm-9.024 13.535a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.902Zm0-3.609a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.903.9.9 0 0 1 .902.903.9.9 0 0 1-.902.902Zm0-3.61a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903Zm3.609-7.218a.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903.9.9 0 0 1-.902-.903.9.9 0 0 1 .902-.902Zm4.812 13.836c0 .165-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.165.135-.3.3-.3h5.415c.165 0 .3.135.3.3v.602Zm0-3.61c0 .166-.135.301-.3.301h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.166.135-.3.3-.3h5.415c.165 0 .3.134.3.3v.601Zm0-3.609c0 .166-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.601c0-.166.135-.301.3-.301h5.415c.165 0 .3.135.3.3v.602Z"  fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                            <span>Project</span>
                        </li>
                        <li className={nav === 4 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/departments'); setNav(4); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="19.25" viewBox="18.406 15 14.438 19.25"><path d="M31.04 17.406H28.03A2.408 2.408 0 0 0 25.625 15a2.408 2.408 0 0 0-2.406 2.406H20.21c-.996 0-1.805.809-1.805 1.805v13.234c0 .997.809 1.805 1.805 1.805h10.828c.996 0 1.805-.808 1.805-1.805V19.211c0-.996-.809-1.805-1.805-1.805Zm-9.024 13.535a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.902Zm0-3.609a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.903.9.9 0 0 1 .902.903.9.9 0 0 1-.902.902Zm0-3.61a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903Zm3.609-7.218a.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903.9.9 0 0 1-.902-.903.9.9 0 0 1 .902-.902Zm4.812 13.836c0 .165-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.165.135-.3.3-.3h5.415c.165 0 .3.135.3.3v.602Zm0-3.61c0 .166-.135.301-.3.301h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.166.135-.3.3-.3h5.415c.165 0 .3.134.3.3v.601Zm0-3.609c0 .166-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.601c0-.166.135-.301.3-.301h5.415c.165 0 .3.135.3.3v.602Z"  fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                            <span>Departments</span>
                        </li>
                        <li className={nav === 5 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/project-report'); setNav(5); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="19.25" viewBox="18.406 15 14.438 19.25"><path d="M31.04 17.406H28.03A2.408 2.408 0 0 0 25.625 15a2.408 2.408 0 0 0-2.406 2.406H20.21c-.996 0-1.805.809-1.805 1.805v13.234c0 .997.809 1.805 1.805 1.805h10.828c.996 0 1.805-.808 1.805-1.805V19.211c0-.996-.809-1.805-1.805-1.805Zm-9.024 13.535a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.902Zm0-3.609a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.903.9.9 0 0 1 .902.903.9.9 0 0 1-.902.902Zm0-3.61a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903Zm3.609-7.218a.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903.9.9 0 0 1-.902-.903.9.9 0 0 1 .902-.902Zm4.812 13.836c0 .165-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.165.135-.3.3-.3h5.415c.165 0 .3.135.3.3v.602Zm0-3.61c0 .166-.135.301-.3.301h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.166.135-.3.3-.3h5.415c.165 0 .3.134.3.3v.601Zm0-3.609c0 .166-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.601c0-.166.135-.301.3-.301h5.415c.165 0 .3.135.3.3v.602Z"  fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                            <span>Project Report</span>
                        </li>
                        <li className={nav === 6 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/employee-report'); setNav(6); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="19.25" viewBox="18.406 15 14.438 19.25"><path d="M31.04 17.406H28.03A2.408 2.408 0 0 0 25.625 15a2.408 2.408 0 0 0-2.406 2.406H20.21c-.996 0-1.805.809-1.805 1.805v13.234c0 .997.809 1.805 1.805 1.805h10.828c.996 0 1.805-.808 1.805-1.805V19.211c0-.996-.809-1.805-1.805-1.805Zm-9.024 13.535a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.902Zm0-3.609a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.903.9.9 0 0 1 .902.903.9.9 0 0 1-.902.902Zm0-3.61a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903Zm3.609-7.218a.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903.9.9 0 0 1-.902-.903.9.9 0 0 1 .902-.902Zm4.812 13.836c0 .165-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.165.135-.3.3-.3h5.415c.165 0 .3.135.3.3v.602Zm0-3.61c0 .166-.135.301-.3.301h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.166.135-.3.3-.3h5.415c.165 0 .3.134.3.3v.601Zm0-3.609c0 .166-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.601c0-.166.135-.301.3-.301h5.415c.165 0 .3.135.3.3v.602Z"  fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                            <span>Employee Report</span>
                        </li>
                        {/* <li className={nav === 7 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/manger-report'); setNav(7); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="19.25" viewBox="18.406 15 14.438 19.25"><path d="M31.04 17.406H28.03A2.408 2.408 0 0 0 25.625 15a2.408 2.408 0 0 0-2.406 2.406H20.21c-.996 0-1.805.809-1.805 1.805v13.234c0 .997.809 1.805 1.805 1.805h10.828c.996 0 1.805-.808 1.805-1.805V19.211c0-.996-.809-1.805-1.805-1.805Zm-9.024 13.535a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.902Zm0-3.609a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.903.9.9 0 0 1 .902.903.9.9 0 0 1-.902.902Zm0-3.61a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903Zm3.609-7.218a.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903.9.9 0 0 1-.902-.903.9.9 0 0 1 .902-.902Zm4.812 13.836c0 .165-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.165.135-.3.3-.3h5.415c.165 0 .3.135.3.3v.602Zm0-3.61c0 .166-.135.301-.3.301h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.166.135-.3.3-.3h5.415c.165 0 .3.134.3.3v.601Zm0-3.609c0 .166-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.601c0-.166.135-.301.3-.301h5.415c.165 0 .3.135.3.3v.602Z"  fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                            <span>Manger Report</span>
                        </li> */}
                    </ul>
                    <div className="user-li d-flex pointer-cursor cursor-pointer">
                        <div className="pt-1 pe-2 me-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="252 994.95 41 41"><g data-name="Group 29"><path d="M252 1015.4a20.5 20.5 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd" data-name="Ellipse 1"/><g data-name="Icon feather-user"><path d="M282.093 1027.053v-2.44a4.88 4.88 0 0 0-4.88-4.88h-9.76a4.88 4.88 0 0 0-4.88 4.88v2.44" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 2"/><path d="M277.213 1009.973a4.88 4.88 0 1 1-9.76 0 4.88 4.88 0 0 1 9.76 0Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 3"/></g></g></svg>
                        </div>
                        <div className="overflow-hidden">
                            <p className="mb-0">{localStorage.getItem("email")}</p>
                            <p className="mb-0">{localStorage.getItem("company_name")}</p>
                        </div>
                    </div>
                </>) : type === 2 ? (
                    <>
                        <ul className="navbar-ul">
                            <li className={nav === 1 ? "active-li" : "sdfafa"} onClick={() => { navigate('/admin'); setNav(1); }}>                    
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19"><path d="M0 10.556h8.444V0H0v10.556ZM0 19h8.444v-6.333H0V19Zm10.556 0H19V8.444h-8.444V19Zm0-19v6.333H19V0h-8.444Z"  fill-rule="evenodd" data-name="Icon material-dashboard"/></svg>
                                <span>Dashboard</span>
                            </li>
                            {/* <li className={nav === 2 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/personnel'); setNav(2); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.25" height="19.25" viewBox="16 15 19.25 19.25">
                                    <path d="M25.625 15A9.623 9.623 0 0 0 16 24.625a9.623 9.623 0 0 0 9.625 9.625 9.623 9.623 0 0 0 9.625-9.625A9.623 9.623 0 0 0 25.625 15Zm0 3.726a3.415 3.415 0 1 1 0 6.83 3.415 3.415 0 0 1 0-6.83Zm0 13.35a7.437 7.437 0 0 1-5.686-2.646c.73-1.374 2.158-2.321 3.823-2.321a.95.95 0 0 1 .276.043 5.139 5.139 0 0 0 1.587.267c.555 0 1.087-.104 1.587-.267a.95.95 0 0 1 .276-.043c1.665 0 3.093.947 3.823 2.32a7.437 7.437 0 0 1-5.686 2.648Z"  fill-rule="evenodd" data-name="Icon awesome-user-circle"/>
                                </svg>
                                <span>Personnel</span>
                            </li>
                            <li className={nav === 3 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/project'); setNav(3); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="19.25" viewBox="18.406 15 14.438 19.25"><path d="M31.04 17.406H28.03A2.408 2.408 0 0 0 25.625 15a2.408 2.408 0 0 0-2.406 2.406H20.21c-.996 0-1.805.809-1.805 1.805v13.234c0 .997.809 1.805 1.805 1.805h10.828c.996 0 1.805-.808 1.805-1.805V19.211c0-.996-.809-1.805-1.805-1.805Zm-9.024 13.535a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.902Zm0-3.609a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.903.9.9 0 0 1 .902.903.9.9 0 0 1-.902.902Zm0-3.61a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903Zm3.609-7.218a.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903.9.9 0 0 1-.902-.903.9.9 0 0 1 .902-.902Zm4.812 13.836c0 .165-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.165.135-.3.3-.3h5.415c.165 0 .3.135.3.3v.602Zm0-3.61c0 .166-.135.301-.3.301h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.166.135-.3.3-.3h5.415c.165 0 .3.134.3.3v.601Zm0-3.609c0 .166-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.601c0-.166.135-.301.3-.301h5.415c.165 0 .3.135.3.3v.602Z"  fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                                <span>Project</span>
                            </li>
                            <li className={nav === 4 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/departments'); setNav(4); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="19.25" viewBox="18.406 15 14.438 19.25"><path d="M31.04 17.406H28.03A2.408 2.408 0 0 0 25.625 15a2.408 2.408 0 0 0-2.406 2.406H20.21c-.996 0-1.805.809-1.805 1.805v13.234c0 .997.809 1.805 1.805 1.805h10.828c.996 0 1.805-.808 1.805-1.805V19.211c0-.996-.809-1.805-1.805-1.805Zm-9.024 13.535a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.902Zm0-3.609a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.903.9.9 0 0 1 .902.903.9.9 0 0 1-.902.902Zm0-3.61a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903Zm3.609-7.218a.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903.9.9 0 0 1-.902-.903.9.9 0 0 1 .902-.902Zm4.812 13.836c0 .165-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.165.135-.3.3-.3h5.415c.165 0 .3.135.3.3v.602Zm0-3.61c0 .166-.135.301-.3.301h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.166.135-.3.3-.3h5.415c.165 0 .3.134.3.3v.601Zm0-3.609c0 .166-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.601c0-.166.135-.301.3-.301h5.415c.165 0 .3.135.3.3v.602Z"  fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                                <span>Departments</span>
                            </li> */}
                        </ul>
                        <div className="user-li d-flex pointer-cursor cursor-pointer">
                            <div className="pt-1 pe-2 me-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="252 994.95 41 41"><g data-name="Group 29"><path d="M252 1015.4a20.5 20.5 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd" data-name="Ellipse 1"/><g data-name="Icon feather-user"><path d="M282.093 1027.053v-2.44a4.88 4.88 0 0 0-4.88-4.88h-9.76a4.88 4.88 0 0 0-4.88 4.88v2.44" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 2"/><path d="M277.213 1009.973a4.88 4.88 0 1 1-9.76 0 4.88 4.88 0 0 1 9.76 0Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 3"/></g></g></svg>
                            </div>
                            <div className="overflow-hidden">
                                <p className="mb-0">{localStorage.getItem("email")}</p>
                                <p className="mb-0">{localStorage.getItem("company_name")}</p>
                            </div>
                        </div>
                    </>
                ) : type === 3 ? (
                    <>
                        <ul className="navbar-ul">
                            <li className={nav === 1 ? "active-li" : "sdfafa"} onClick={() => { navigate('/manager'); setNav(1); }}>                    
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19"><path d="M0 10.556h8.444V0H0v10.556ZM0 19h8.444v-6.333H0V19Zm10.556 0H19V8.444h-8.444V19Zm0-19v6.333H19V0h-8.444Z"  fill-rule="evenodd" data-name="Icon material-dashboard"/></svg>
                                <span>Dashboard</span>
                            </li>
                            {/* <li className={nav === 2 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/personnel'); setNav(2); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.25" height="19.25" viewBox="16 15 19.25 19.25">
                                    <path d="M25.625 15A9.623 9.623 0 0 0 16 24.625a9.623 9.623 0 0 0 9.625 9.625 9.623 9.623 0 0 0 9.625-9.625A9.623 9.623 0 0 0 25.625 15Zm0 3.726a3.415 3.415 0 1 1 0 6.83 3.415 3.415 0 0 1 0-6.83Zm0 13.35a7.437 7.437 0 0 1-5.686-2.646c.73-1.374 2.158-2.321 3.823-2.321a.95.95 0 0 1 .276.043 5.139 5.139 0 0 0 1.587.267c.555 0 1.087-.104 1.587-.267a.95.95 0 0 1 .276-.043c1.665 0 3.093.947 3.823 2.32a7.437 7.437 0 0 1-5.686 2.648Z"  fill-rule="evenodd" data-name="Icon awesome-user-circle"/>
                                </svg>
                                <span>Personnel</span>
                            </li>
                            <li className={nav === 3 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/project'); setNav(3); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="19.25" viewBox="18.406 15 14.438 19.25"><path d="M31.04 17.406H28.03A2.408 2.408 0 0 0 25.625 15a2.408 2.408 0 0 0-2.406 2.406H20.21c-.996 0-1.805.809-1.805 1.805v13.234c0 .997.809 1.805 1.805 1.805h10.828c.996 0 1.805-.808 1.805-1.805V19.211c0-.996-.809-1.805-1.805-1.805Zm-9.024 13.535a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.902Zm0-3.609a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.903.9.9 0 0 1 .902.903.9.9 0 0 1-.902.902Zm0-3.61a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903Zm3.609-7.218a.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903.9.9 0 0 1-.902-.903.9.9 0 0 1 .902-.902Zm4.812 13.836c0 .165-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.165.135-.3.3-.3h5.415c.165 0 .3.135.3.3v.602Zm0-3.61c0 .166-.135.301-.3.301h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.166.135-.3.3-.3h5.415c.165 0 .3.134.3.3v.601Zm0-3.609c0 .166-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.601c0-.166.135-.301.3-.301h5.415c.165 0 .3.135.3.3v.602Z"  fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                                <span>Project</span>
                            </li>
                            <li className={nav === 4 ? "active-li" : "sdfafa"} onClick={() => { navigate('/company/departments'); setNav(4); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.438" height="19.25" viewBox="18.406 15 14.438 19.25"><path d="M31.04 17.406H28.03A2.408 2.408 0 0 0 25.625 15a2.408 2.408 0 0 0-2.406 2.406H20.21c-.996 0-1.805.809-1.805 1.805v13.234c0 .997.809 1.805 1.805 1.805h10.828c.996 0 1.805-.808 1.805-1.805V19.211c0-.996-.809-1.805-1.805-1.805Zm-9.024 13.535a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.902Zm0-3.609a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.903.9.9 0 0 1 .902.903.9.9 0 0 1-.902.902Zm0-3.61a.9.9 0 0 1-.903-.902.9.9 0 0 1 .903-.902.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903Zm3.609-7.218a.9.9 0 0 1 .902.902.9.9 0 0 1-.902.903.9.9 0 0 1-.902-.903.9.9 0 0 1 .902-.902Zm4.812 13.836c0 .165-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.165.135-.3.3-.3h5.415c.165 0 .3.135.3.3v.602Zm0-3.61c0 .166-.135.301-.3.301h-5.414a.302.302 0 0 1-.301-.3v-.602c0-.166.135-.3.3-.3h5.415c.165 0 .3.134.3.3v.601Zm0-3.609c0 .166-.135.3-.3.3h-5.414a.302.302 0 0 1-.301-.3v-.601c0-.166.135-.301.3-.301h5.415c.165 0 .3.135.3.3v.602Z"  fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                                <span>Departments</span>
                            </li> */}
                        </ul>
                        <div className="user-li d-flex pointer-cursor cursor-pointer">
                            <div className="pt-1 pe-2 me-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="252 994.95 41 41"><g data-name="Group 29"><path d="M252 1015.4a20.5 20.5 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd" data-name="Ellipse 1"/><g data-name="Icon feather-user"><path d="M282.093 1027.053v-2.44a4.88 4.88 0 0 0-4.88-4.88h-9.76a4.88 4.88 0 0 0-4.88 4.88v2.44" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 2"/><path d="M277.213 1009.973a4.88 4.88 0 1 1-9.76 0 4.88 4.88 0 0 1 9.76 0Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 3"/></g></g></svg>
                            </div>
                            <div className="overflow-hidden">
                                <p className="mb-0">{localStorage.getItem("email")}</p>
                                <p className="mb-0">{localStorage.getItem("name")}</p>
                            </div>
                        </div>
                    </>
                ) : ( 
                    <>
                        <ul className="navbar-ul">
                            <li className={nav === 1 ? "active-li" : "sdfafa"} onClick={() => { navigate('/user'); setNav(1); }}>                    
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19"><path d="M0 10.556h8.444V0H0v10.556ZM0 19h8.444v-6.333H0V19Zm10.556 0H19V8.444h-8.444V19Zm0-19v6.333H19V0h-8.444Z"  fill-rule="evenodd" data-name="Icon material-dashboard"/></svg>
                                <span>Dashboard</span>
                            </li>
                            {/* <li className={nav === 2 ? "active-li" : "sdfafa"} onClick={() => { navigate('/personnel'); setNav(2); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.25" height="19.25" viewBox="16 15 19.25 19.25">
                                    <path d="M25.625 15A9.623 9.623 0 0 0 16 24.625a9.623 9.623 0 0 0 9.625 9.625 9.623 9.623 0 0 0 9.625-9.625A9.623 9.623 0 0 0 25.625 15Zm0 3.726a3.415 3.415 0 1 1 0 6.83 3.415 3.415 0 0 1 0-6.83Zm0 13.35a7.437 7.437 0 0 1-5.686-2.646c.73-1.374 2.158-2.321 3.823-2.321a.95.95 0 0 1 .276.043 5.139 5.139 0 0 0 1.587.267c.555 0 1.087-.104 1.587-.267a.95.95 0 0 1 .276-.043c1.665 0 3.093.947 3.823 2.32a7.437 7.437 0 0 1-5.686 2.648Z"  fill-rule="evenodd" data-name="Icon awesome-user-circle"/>
                                </svg>
                                <span>Personnel</span>
                            </li> */}
                            <li className={nav === 2 ? "active-li" : "sdfafa"} onClick={() => { navigate('/user/workdetail'); setNav(2); }}>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="19.25" height="19.25" viewBox="16 15 19.25 19.25">
                                    <path d="M25.625 15A9.623 9.623 0 0 0 16 24.625a9.623 9.623 0 0 0 9.625 9.625 9.623 9.623 0 0 0 9.625-9.625A9.623 9.623 0 0 0 25.625 15Zm0 3.726a3.415 3.415 0 1 1 0 6.83 3.415 3.415 0 0 1 0-6.83Zm0 13.35a7.437 7.437 0 0 1-5.686-2.646c.73-1.374 2.158-2.321 3.823-2.321a.95.95 0 0 1 .276.043 5.139 5.139 0 0 0 1.587.267c.555 0 1.087-.104 1.587-.267a.95.95 0 0 1 .276-.043c1.665 0 3.093.947 3.823 2.32a7.437 7.437 0 0 1-5.686 2.648Z"  fill-rule="evenodd" data-name="Icon awesome-user-circle"/>
                                </svg> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.111" height="19" viewBox="1566.706 322 21.111 19"><g data-name="Icon feather-monitor"><path d="M1568.817 322h16.89c1.165 0 2.11.945 2.11 2.111v10.556a2.111 2.111 0 0 1-2.11 2.11h-16.89a2.111 2.111 0 0 1-2.11-2.11V324.11c0-1.166.944-2.111 2.11-2.111Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2"   data-name="Path 10"/><path d="M1573.04 341h8.444" stroke-linejoin="round" stroke-linecap="round" stroke-width="2"  data-name="Path 11"/><path d="M1577.262 336.778V341" stroke-linejoin="round" stroke-linecap="round" stroke-width="2"   data-name="Path 12"/></g></svg>
                                <span>Screenshots</span>
                            </li>
                        </ul>
                        <div className="user-li d-flex pointer-cursor cursor-pointer" onClick={() => navigate('/user/profile')}>
                            <div className="pt-1 pe-2 me-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="252 994.95 41 41"><g data-name="Group 29"><path d="M252 1015.4a20.5 20.5 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd" data-name="Ellipse 1"/><g data-name="Icon feather-user"><path d="M282.093 1027.053v-2.44a4.88 4.88 0 0 0-4.88-4.88h-9.76a4.88 4.88 0 0 0-4.88 4.88v2.44" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 2"/><path d="M277.213 1009.973a4.88 4.88 0 1 1-9.76 0 4.88 4.88 0 0 1 9.76 0Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 3"/></g></g></svg>
                            </div>
                            <div className="overflow-hidden">
                                <p className="mb-0">{localStorage.getItem("email")}</p>
                                <p className="mb-0">{localStorage.getItem("name")}</p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Navbar;