import React, { useEffect, useState } from "react";
import { companyURL } from "../../../App";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../../../components/common/CustomLoader";

const ManagerProfile = () => {
    const [manager, setManager] = useState();
    const navigate = useNavigate();
    const {id} = useParams();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/viewPersonnelManager`, {manager_id: id}, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setManager(res?.data?.data[0]);
            // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
            // setUsers(res?.data?.data);
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
            setLoader(false);
        }).catch((err) => {
            console.log("err : ", err);
            setLoader(false);
        })
    }, [])
     
    return (
        <div className="customer-dashboard">
            <h3 className="mb-0 text2">Manager</h3>
            
            <div className="table-card mt-30">
                <h2 className="text10">Information</h2>
                {loader ? (
                    <div>
                        <CustomLoader />
                    </div>
                ) : (
                    <div className="w-100 d-flex mt-4">
                        <div className="d-flex w-50">
                            <div className="w-25">
                                <p className="text11">Status</p>
                                <p className="text11">User Role</p>
                                <p className="text11">User</p>
                                <p className="text11">First Name</p>
                                <p className="text11">Last Name</p>
                                <p className="text11">Department</p>
                                <p className="text11">Country</p>
                                <p className="text11">Mobile</p>
                                <button className="back-btn" onClick={() => navigate('/company/personnel')}>back</button>
                            </div>
                            <div>
                                {manager?.status === 1 ? (
                                    <h6 className="text12 mb-3 clr-11aa00">Active</h6>
                                ) : (
                                    <h6 className="text12 mb-3 clr-11aa00 text-danger">Inactive</h6>
                                )}
                                <h6 className="text12 mb-3 pt-1">Manager</h6>
                                <h6 className="text12 mb-3 pt-1">{manager?.email}</h6>
                                <h6 className="text12 mb-3 pt-1">{manager?.first_name}</h6>
                                <h6 className="text12 mb-3 pt-1">{manager?.last_name}</h6>
                                <h6 className="text12 mb-3 pt-2">{manager?.department_name ? manager?.department_name : "empty"}</h6>
                                <h6 className="text12 mb-3 pt-2">{manager?.country ? manager?.country : "empty"}</h6>
                                <h6 className="text12 mb-3 pt-2">{manager?.phone_number ? manager?.phone_number : 'empty'}</h6>
                                <button className="edit-btn" onClick={() => navigate(`/company/personnel/edit/manager/${id}`)}>Edit</button>
                            </div>
                        </div>
                        <div className="w-50 d-flex">
                            <div className="w-25">
                                {/* <p className="text11">Manager</p> */}
                                <p className="text11">Time Zone</p>

                            </div>
                            <div>
                                {/* <h6 className="text12 mb-3 pt-1">Joe Sripatana</h6> */}
                                <h6 className="text12 mb-3 pt-1">{manager?.timezone}</h6>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default ManagerProfile;