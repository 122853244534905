import React, { useEffect, useState } from "react";
import DataTableBase from "../../../components/common/DateTableBase";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { apiEndPoint, companyURL } from "../../../App";
import { useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../../../components/common/CustomLoader";
import { ToastContainer, toast } from "react-toastify";

// Function to get the date of the day before a provided date

function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " min" : "";
  
    return hoursText + minutesText;
}

const PersonalWorkDetail = () => {
    const [startDate, setStartDate] = useState();
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [allData, setAllData] = useState(null);

    const navigate = useNavigate();
     
    const [tog, setTog] = useState(false);
    const [sessionId, setSessionID] = useState(null);
    
    const [arr, setArr] = useState([]);
    const [pagination, setPagination] = useState(1);

    const [pending, setPending] = useState(true);
    const [pending2, setPending2] = useState(false);

    const [flag, setFlat] = useState(1)

    const {id, name} = useParams();
    const dt= new Date()
    const [current, setCurrent] = useState(formateDate(dt) )
    const [dayDate, setDayDate] = useState(null);


    function getDateBefore(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() - 1);
        const temDate = date.toISOString().slice(0, 10);
        console.log("tem date : ", temDate);
        setCurrent(temDate);
        setDayDate(temDate);
        // return date.toISOString().slice(0, 10);
    }
      
      // Function to get the date of the day after a provided date
      function getDateAfter(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const temDate = date.toISOString().slice(0, 10);
        console.log("day after : ", temDate);            
        setCurrent(temDate);
        setDayDate(temDate);
        // return date.toISOString().slice(0, 10);
      }
      
      // Function to get the previous week's first day based on a provided date
      function getPreviousWeekFirstDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() - 7);
        date.setDate(date.getDate() - (date.getDay() - 1));
        const temDate = date.toISOString().slice(0, 10);
        console.log("previous weeek  : ", temDate);            
        setCurrent(temDate);
        setDayDate(temDate);
        // return date.toISOString().slice(0, 10);
      }
      
      // Function to get the next week's first day based on a provided date
      function getNextWeekFirstDay(dateString) {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 7);
        date.setDate(date.getDate() + (7 - date.getDay() + 1));
        const temDate =  date.toISOString().slice(0, 10);
        console.log("next week : ", temDate);            
        setCurrent(temDate);
        setDayDate(temDate);
        // return date.toISOString().slice(0, 10);
      }
    
    const columns = [
        {
            name: 'Project',
            selector: row => row.project_name,
        },
        {
            name: 'Task',
            center: true,
            selector: row => row.task_heading,
        },
        // {
        //     name: 'Start time',
        //     center: true,
        //     selector: row => row.btime,
        // },
        {
            name: 'Duration',
            selector: row => convertMinutesToHoursAndMinutes(row?.duration),
            center: true,
            width: '300px' 
        },
        {
            name: 'Tools',
            center: true,
            selector: row => <>
                <svg onClick={() => { setSessionID(row?.id); setTog(true); }} className="me-3 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="21.111" height="19" viewBox="1566.706 322 21.111 19"><g data-name="Icon feather-monitor"><path d="M1568.817 322h16.89c1.165 0 2.11.945 2.11 2.111v10.556a2.111 2.111 0 0 1-2.11 2.11h-16.89a2.111 2.111 0 0 1-2.11-2.11V324.11c0-1.166.944-2.111 2.11-2.111Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 10"/><path d="M1573.04 341h8.444" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 11"/><path d="M1577.262 336.778V341" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 12"/></g></svg>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="17.183" height="21.148" viewBox="1607.818 319.852 17.183 21.148"><path d="M1609.14 326.46v13.218c0 .727.594 1.322 1.321 1.322h11.896c.727 0 1.322-.595 1.322-1.322v-13.217h-14.54Zm3.965 11.896h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.644 0h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.974-15.86h-4.296v-1.653a.994.994 0 0 0-.991-.991h-4.626a.994.994 0 0 0-.991.991v1.652h-4.296a.994.994 0 0 0-.992.992v1.652H1625v-1.652a.994.994 0 0 0-.991-.992Zm-5.617 0h-3.966v-1.306h3.966v1.305Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon metro-bin"/></svg> */}
            </>,
        },
    ];


    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];

    function formateDate2(dtt){
        const currentDate = new Date(dtt);
        const day = currentDate.getDate();
        const monthIndex = currentDate.getMonth();
        const year = currentDate.getFullYear();
        
        const formattedDate = `${day} ${months[monthIndex]} ${year}`;
        return formattedDate;
    }




    function formateDate(datee){
        const currentDate = new Date(datee);
        const day = currentDate.getDate();
        const monthIndex = currentDate.getMonth();
        const year = currentDate.getFullYear();
        
        const formattedDate = `${year}-${monthIndex+1}-${day}`;
        return formattedDate;
    }
      
      
    //   console.log(formattedDate);


    const fetchData = async () => {
        // Mock API call
        // const response = await fetch('your-api-endpoint');
        // const dataFromAPI = await response.json();
        // setData(dataFromAPI);
        const token = localStorage.getItem("token");

        axios.post(`${companyURL}/personnelSessionsByID?page=${pagination}`, {user_id: id},{
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            const temData = res?.data?.data?.data;
            setData(temData);
            setData2(temData);
            setAllData(res?.data?.data);
            // setPending(false);
        }).catch((err) => {
            // setPending(false);
            console.log("err : ", err);
        })
    };

    const handleButtonClick = async (row, actionType) => {
        // Call API to save the action in the database
        await saveActionToDatabase(row?.id, actionType);
        // Update the data after action is saved
        
    };
    
    const saveActionToDatabase = async (rowId, actionType) => {
        // Mock API call to save action to database
        // Replace this with your actual API endpoint and logic
        // await fetch(`your-save-action-api-endpoint`, {
        //   method: 'POST',
        //   body: JSON.stringify({ rowId, actionType }),
        //   headers: {
        //     'Content-Type': 'application/json'
        //   }
        // });
        const token = localStorage.getItem("token");

        axios.post(`${companyURL}/updateSessionColorByID`, {
            session_id: rowId, 
            colorValue: actionType
        },
        {headers: {
            Authorization: `Bearer ${token}`
        }}).then((res) => {
            console.log("response status api : ", res);
            toast.success("Status updated successfully");
            fetchData(rowId);
        }).catch((err) => {
            console.log("Error status api : ", err);
        })
    };


    
    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("Start date useeffect")
        if(startDate){
            setPending(true);
            
            setTog(false);
            setData2([]);
            setArr([]);
            setCurrent(startDate);
            console.log("start date start date : ", startDate);
            const dat = formateDate(startDate);
            console.log(dat);
            axios.post(`${companyURL}/personnelSessionsByID?page=${pagination}`, {user_id: id, date: dat},{
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                const temData = res?.data?.data?.data;
                setData(temData);
                setData2(temData);
                setAllData(res?.data?.data);
                setPending(false);
            }).catch((err) => {
                setPending(false);
                console.log("err : ", err);
            })
        } else {
            const date2 = new Date();
            
            axios.post(`${companyURL}/personnelSessionsByID?page=${pagination}`, {user_id: id, date: formateDate(date2)},{
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                const temData = res?.data?.data?.data;
                setData(temData);
                setData2(temData);
                setAllData(res?.data?.data);
                setPending(false);
            }).catch((err) => {
                setPending(false);
                console.log("err : ", err);
            })
        }
    }, [flag, startDate])


    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("date given : ", dayDate)

        if(dayDate){
            setPending(true);
            setTog(false);
            setData2([]);
            setArr([]);
            console.log("start date start date : ", startDate);
            const dat = formateDate(startDate);
            setCurrent(dayDate)
            console.log(dat);
            axios.post(`${companyURL}/personnelSessionsByID?page=${pagination}`, {user_id: id, date: dayDate},{
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                const temData = res?.data?.data?.data;
                setData(temData);
                setData2(temData);
                setAllData(res?.data?.data);
                setPending(false);
            }).catch((err) => {
                setPending(false);
                console.log("err : ", err);
            })
        }
    }, [dayDate])



    useEffect(() => {
        console.log("shot : ", sessionId);
        if(sessionId){
            const token = localStorage.getItem("token");
            setPending2(true);
            axios.post(`${companyURL}/projectShotsByID`, {session_id: sessionId},{
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("Screenshots res : ", res);
                const temData = res?.data?.data?.data;
                setData2(temData);
                const dataLen = Math.floor(temData?.length / 12);
                console.log("(temData?.length) % 12 : ", temData?.length % 12, dataLen)
                const size =  (temData?.length) % 12 === 0 ? dataLen : dataLen + 1;
                console.log(size);
                const newArray = Array(size).fill(1);
                setArr(newArray);
                setPending2(false);
            }).catch((err) => {
                setPending2(false);
                console.log("err : ", err);
            })
        }
    }, [sessionId])

    const handleChange = (i, value) => {
        let prevData = [...data];
        console.log("prevData[i] : ", prevData[i]);
        prevData[i] = {
            ...prevData[i],
            isChecked: value
        };
        console.log("prevData[i] after : ", prevData[i]);
        setData(prevData);
    }

    const handleFilter = (val) => {
        if(val === "all"){
            setData([...data2]);
        } else {
            const filteredData = [];
            data2?.map((item => {
                if(item?.isChecked === val){
                    filteredData.push(item);
                }
            })) 
            setData(filteredData);
        }
    }

    const prevFunc = () => {
        if(pagination > 1){
            setPending(true);
            setPagination(pagination - 1);
        }
    }
    
    const nextFunc = () => {
        if(pagination < allData?.last_page){
            setPending(true);
            setPagination(pagination + 1);
        }
    }


    return (
        <div className="customer-dashboard">
            <ToastContainer />
            <div className="d-flex">
                <div className="w-50 d-flex">
                    <div>
                        <h3 className="mb-0 text2">Work Dashboard</h3>
                        {/* <p className="text3">22 September 2023</p> */}
                    </div>
                    {/* <div>
                        <ul className="dashboard-bar-ul ms-2">
                            <li>
                                <p className="text4">Personnel</p>
                            </li>
                            <li>
                                <div className="p-relative">
                                    <select className="form-control users-list-select" placeholder="Poom">
                                        <option>Poom</option>
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div> */}
                </div>
                <div className="w-50">
                    {/* <ul className="dashboard-bar-ul">
                        <li>
                            <p className="text4">Select Day</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li>
                            <button className="filter-btn">Filter</button>
                        </li>
                    </ul> */}
                </div>
            </div>

            <div className="d-flex mt-4">
                <div>
                    <ul className="dashboard-bar-ul ms-2">
                        <li>
                            <p className="text4">Personnel</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <select className="form-control users-list-select py-2" placeholder="Poom">
                                    <option>{name}</option>
                                </select>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                <ul className="dashboard-bar-ul">
                        <li>
                            <p className="text4">Select Day</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr py-2"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li>
                            <button onClick={() => getPreviousWeekFirstDay(current)} className="filter-btn px-4 text-center" style={{width: '130px'}}>&#60;&#60; Week</button>
                        </li>
                        <li>
                            <button onClick={() => getDateBefore(current)} className="filter-btn px-4 text-center" style={{width: '130px'}}>&#60; Day</button>
                        </li>
                        <li>
                            <button onClick={() => getDateAfter(current)} className="filter-btn px-4 text-center" style={{width: '130px'}}>Day &gt;</button>
                        </li>
                        <li>
                            <button onClick={() => getNextWeekFirstDay(current)} className="filter-btn px-4 text-center" style={{width: '130px'}}>Week &gt;&gt;</button>
                        </li>
                    </ul>
                </div>
            </div>

            <p className="text8">Work Session {formateDate2(current)}</p>
            {tog && (
                <div className="table-card table-card2 mt-30">
                    {pending2 ? (
                        <CustomLoader />
                    ) : (
                        <>
                            
                            <div className="w-100 d-flex justify-content-center mb-3">
                                {arr?.map((item, index) => (
                                    <div onClick={() => setPagination(index + 1)} key={index} className={pagination === index + 1 ? 'bg-primary cursor-pointer mx-1 text-white rounded  py-2 px-3' : 'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                        {index + 1}
                                    </div>
                                ))}

                            </div>
                            <Row>
                                {data2?.map((item, index) => (
                                    index >= (pagination * 12) - 12 && index < pagination * 12  && (
                                        <Col md={4} key={index}>
                                            <div className="screen-img" style={{backgroundImage: `url(${apiEndPoint}/uploads/screenshots/${item?.image})`, backgroundSize: 'cover'}}>
                                                {/* <p>Screen</p> */}
                                            </div>
                                            <p className="text9 mt-1 mb-2">{item?.current_datetime}
                                                {/* <svg style={{float: 'right'}} xmlns="http://www.w3.org/2000/svg" width="13.683" height="16.841" viewBox="888.317 462 13.683 16.841"><path d="M889.37 467.263v10.525c0 .579.473 1.052 1.052 1.052h9.473c.579 0 1.052-.473 1.052-1.052v-10.525H889.37Zm3.157 9.472h-1.052v-7.367h1.052v7.367Zm2.105 0h-1.052v-7.367h1.052v7.367Zm2.105 0h-1.052v-7.367h1.052v7.367Zm2.105 0h-1.052v-7.367h1.052v7.367Zm2.369-12.63h-3.421v-1.316A.792.792 0 0 0 897 462h-3.683a.792.792 0 0 0-.79.79v1.315h-3.42a.792.792 0 0 0-.79.79v1.315H902v-1.316a.792.792 0 0 0-.79-.789Zm-4.474 0h-3.157v-1.04h3.157v1.04Z" fill="#b8b8b8" fill-rule="evenodd" data-name="Icon metro-bin"/></svg> */}
                                            </p>
                                        </Col>
                                    )
                                ))}                    
                            </Row>
                        </>   
                    )}              
                </div>
            )}


            <div className="table-card mt-30">
                {/* <DataTableBase 
                    title="Session"
                    columns={columns}
                    data={data}
                    progressPending={pending}
                /> */}
            {pending ? (
                <CustomLoader />
            ) : (
                <>
                        <div className="d-flex justify-content-end mb-3">
                            <div onClick={() => {handleFilter('all');}} className="pointer text-center white-radio-div bg-blue">All</div>
                            <div onClick={() => {handleFilter(0);}} className="pointer white-radio-div"></div>
                            <div onClick={() => {handleFilter(1);}} className="pointer green-radio-div"></div>
                            <div onClick={() => {handleFilter(2);}} className="pointer yellow-radio-div"></div>
                            <div onClick={() => {handleFilter(3);}} className="pointer red-radio-div"></div>
                        </div>
                        <table className="session-table">
                            <thead>
                                <tr>
                                    <th>Actions</th>
                                    <th>Project</th>
                                    <th>Task</th>
                                    <th>Duration</th>
                                    <th>Tools</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((item, index) => (
                                    <tr key={index} className={`${index+1 === data?.length && "remove-border-bottom"} ${item?.isChecked === 0 ? "white-row" : item?.isChecked === 1 ? "green-row" : item?.isChecked === 2 ? "yellow-row" : item?.isChecked === 3 ? "red-row" : ""}`}>
                                        <td>
                                            {/* <div className="d-flex">
                                                <div onClick={() => {handleChange(index, 0); handleButtonClick(item, 0);}} className="pointer white-radio-div"></div>
                                                <div onClick={() => {handleChange(index, 1); handleButtonClick(item, 1);}} className="pointer green-radio-div"></div>
                                                <div onClick={() => {handleChange(index, 2); handleButtonClick(item, 2);}} className="pointer yellow-radio-div"></div>
                                                <div onClick={() => {handleChange(index, 3); handleButtonClick(item, 3);}} className="pointer red-radio-div"></div>
                                            </div> */}
                                            
                                            <div className="d-flex">
                                                {item?.isChecked === 0 ? ( 
                                                    <div className="pointer white-radio-div"></div>
                                                ) : item?.isChecked === 1 ? (
                                                    <div className="pointer green-radio-div"></div>
                                                ) : item?.isChecked === 2 ?
                                                    <div className="pointer yellow-radio-div"></div>
                                                : <div className="pointer red-radio-div"></div>
                                                }
                                            </div>

                                            {/* <input type="radio" defaultChecked={item?.isChecked === 0 && true} onChange={() => handleButtonClick(item, 0)} className="radio-white" name={`type${index}`} />
                                            <input type="radio" defaultChecked={item?.isChecked === 1 && true} onChange={() => handleButtonClick(item, 1)} className="radio-success mx-2" name={`type${index}`} />
                                            <input type="radio" defaultChecked={item?.isChecked === 2 && true} onChange={() => handleButtonClick(item, 2)} className="radio-yellow me-2" name={`type${index}`} />
                                            <input type="radio" defaultChecked={item?.isChecked === 3 && true} onChange={() => handleButtonClick(item, 3)} className="radio-danger" name={`type${index}`} />                                                 */}
                                        </td>
                                        <td>{item?.project_name}</td>
                                        <td>{item?.task_heading}</td>
                                        <td>{convertMinutesToHoursAndMinutes(Number(item?.duration))}</td>
                                        <td>
                                            <svg  onClick={() => navigate(`/company/sessions/${id}/screenshots/${item?.id}`)} className="me-3 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="21.111" height="19" viewBox="1566.706 322 21.111 19"><g data-name="Icon feather-monitor"><path d="M1568.817 322h16.89c1.165 0 2.11.945 2.11 2.111v10.556a2.111 2.111 0 0 1-2.11 2.11h-16.89a2.111 2.111 0 0 1-2.11-2.11V324.11c0-1.166.944-2.111 2.11-2.111Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 10"/><path d="M1573.04 341h8.444" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 11"/><path d="M1577.262 336.778V341" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 12"/></g></svg>
                                            <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="17.183" height="21.148" viewBox="1607.818 319.852 17.183 21.148"><path d="M1609.14 326.46v13.218c0 .727.594 1.322 1.321 1.322h11.896c.727 0 1.322-.595 1.322-1.322v-13.217h-14.54Zm3.965 11.896h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.644 0h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.974-15.86h-4.296v-1.653a.994.994 0 0 0-.991-.991h-4.626a.994.994 0 0 0-.991.991v1.652h-4.296a.994.994 0 0 0-.992.992v1.652H1625v-1.652a.994.994 0 0 0-.991-.992Zm-5.617 0h-3.966v-1.306h3.966v1.305Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
                                        </td>
                                    </tr>                            
                                ))}
                            </tbody>
                        </table>
                        <div className="text-end mt-3">
                            <button onClick={prevFunc} className="prev-next-btn">PREV</button>
                            <button className="current-page-btn mx-2">{pagination}</button>
                            <button onClick={nextFunc} className="prev-next-btn">NEXT</button>
                        </div>
                        
                        {/* <div className="w-100 d-flex justify-content-end mb-3 mt-4">
                            <div onClick={prevFunc} className={'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                Prev
                            </div>                        
                            <div className={'bg-0facd5 cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                {pagination}
                            </div>
                            <div onClick={nextFunc} className={'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                Next
                            </div>
                        </div> */}
                    </>
            )}
            </div>
        </div>
    )
}

export default PersonalWorkDetail;

