import React, { useState } from "react";
import logo from "../assets/nav-logo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import { apiEndPoint } from "../App";
import CustomLoader from "../components/common/CustomLoader";
import { Spinner } from "react-bootstrap";

const Signup = () => {
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState(null);
    const [err, setErr] = useState(null);

    const {register, handleSubmit, reset} = useForm();


    const onSubmit = (data) => {
        setLoading(true);
        console.log("data : ", data);
        const token = localStorage.getItem('token');
        axios.post(`${apiEndPoint}/api/register`, data, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("res : ", res);
            reset({email: '', password: '', company_name: '', country: ''});
            setMsg('Company Registered Successfully.');
            setTimeout(() => {
                setMsg(null);
            }, 5000);
            setLoading(false);
        }).catch((err) => {
            setErr(err?.response?.data?.error[0]);
            setLoading(false);
            console.log("Error : ", err);
        })
    }

    return (
        <div className="signup">
            <div className="signup-topbar text-center text-white">
                <h1 className="pt-4">Screen Pc</h1>
                <h2>Say Hello to Painless Productivity.</h2>
                {/* <img src={logo} alt="logo" /> */}
                <div className="signup-form">
                    {/* <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label for="floatingInput">Email address</label>
                    </div> */}
                    {loading ? (
                        <div style={{paddingTop: '160px'}}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                        ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3 text-dark text-start">
                                <label className="mb-1">Company Name</label>
                                <input type="text" {...register("company_name", {required: true})} className="form-control" placeholder="Enter your company name" />
                            </div>
                            <div className="mb-3 text-dark text-start">
                                <label className="mb-1">Email</label>
                                <input type="email" {...register("email", {required: true})} className="form-control" placeholder="Enter your email" />
                            </div>
                            <div className="mb-3 text-dark text-start">
                                <label className="mb-1">country</label>
                                <input type="text" {...register("country", {required: true})} className="form-control" placeholder="Enter your country" />
                            </div>
                            <div className="mb-3 text-dark text-start">
                                <label className="mb-1">Password</label>
                                <input type="password" {...register("password", {required: true})} className="form-control" placeholder="Enter password" />
                            </div>
                            <button className="signup-btn">SIGN UP</button>
                        </form>
                    )}
                    {msg && <p className="text-success">{msg}</p>}
                    {err && <p className="text-danger">{err}</p>}
                </div>
            </div>
        </div>
    )
}

export default Signup;