import React, { useEffect, useState } from "react";
import { companyURL } from "../../../App";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../../../components/common/CustomLoader";

const UserProfile = () => {
    const [user, setUser] = useState(null);

    const navigate = useNavigate();
    const {id} = useParams();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/viewPersonnelUser`,{user_id: id}, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setUser(res?.data?.data[0]);
            setLoading(false);
            // setManagers(res?.data?.data);
            // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
            // setUsers(res?.data?.data);
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
        }).catch((err) => {
            setLoading(false);
            console.log("err : ", err);
        })
    }, [])
    
    
    return (
        <div className="customer-dashboard">
            <h3 className="mb-0 text2">Employee</h3>
            
            <div className="table-card mt-30">
                <h2 className="text10">Information</h2>
                {loading ? (
                    <div>
                        <CustomLoader />
                    </div>
                ) : (
                    <div className="w-100 d-flex mt-4">
                        <div className="d-flex w-50">
                            <div className="w-25">
                                <p className="text11">Status</p>
                                <p className="text11">User Role</p>
                                <p className="text11">User</p>
                                <p className="text11">First Name</p>
                                <p className="text11">Last Name</p>
                                <p className="text11">Department</p>
                                <p className="text11">Country</p>
                                <p className="text11">Mobile</p>
                                <button className="back-btn" onClick={() => navigate('/company/personnel')}>back</button>
                            </div>
                            <div>
                                {user?.status === 1 ? (
                                    <h6 className="text12 mb-3 clr-11aa00">Active</h6>
                                ) : (
                                    <h6 className="text12 mb-3 clr-11aa00 text-danger">Inactive</h6>
                                )}
                                <h6 className="text12 mb-3 pt-1">Employee</h6>
                                <h6 className="text12 mb-3 pt-1">{user?.email ? user?.email : "empty"}</h6>
                                <h6 className="text12 mb-3 pt-1">{user?.first_name ? user?.first_name : "empty"}</h6>
                                <h6 className="text12 mb-3 pt-1">{user?.last_name ? user?.last_name : "empty"}</h6>
                                <h6 className="text12 mb-3 pt-2">{user?.department_name ? user?.department_name : "empty"}</h6>
                                <h6 className="text12 mb-3 pt-2">{user?.country ? user?.country: "empty"}</h6>
                                <h6 className="text12 mb-3 pt-2">{user?.phone_number ? user?.phone_number : "empty"}</h6>
                                <button className="edit-btn" onClick={() => navigate(`/company/personnel/edit/employee/${id}`)}>Edit</button>
                            </div>
                        </div>
                        <div className="w-50 d-flex">
                            <div className="w-25">
                                <p className="text11">Manager</p>
                                {/* <p className="text11">Time Zone</p> */}

                            </div>
                            <div>
                                <h6 className="text12 mb-3 pt-1">{user?.manager_first_name} {user?.manager_last_name}</h6>
                                {/* <h6 className="text12 mb-3 pt-1">{user?.timezone}</h6> */}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserProfile;