import Spinner from 'react-bootstrap/Spinner';
import React from 'react';
import DataTable from 'react-data-table-component';
// import Checkbox from '@material-ui/core/Checkbox';

// import ArrowDownward from '@material-ui/icons/ArrowDownward';

// const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

function DataTableBase(props) {
    return (
        <DataTable
            // pagination
            // selectableRowsComponent={Checkbox}
            // selectableRowsComponentProps={selectProps}
            // sortIcon={sortIcon}
            // dense
            progressComponent={<CustomLoader />}
            {...props}
        />
    );
}

export default DataTableBase;



function CustomLoader() {
  return (
    <>
      <Spinner animation="border" variant="primary" />
      {/* <Spinner animation="grow" variant="primary" /> */}
    </>
  );
}
