import React, { useEffect, useState } from "react";
import DataTableBase from "../../../components/common/DateTableBase";
import DatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import CustomLoader from "../../../components/common/CustomLoader";
import { apiEndPoint } from "../../../App";


function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " min" : "";
  
    return hoursText + minutesText;
}


const WorkDetail = () => {
    const [startDate, setStartDate] = useState();
    const [data, setData] = useState(null);

    const [arr, setArr] = useState([]);
    const [pagination, setPagination] = useState(1);

    const [sessions, setSessions] = useState([]);
    const [sessionId, setSessionId] = useState(null);
    const [pending, setPending] = useState(true);

    const [tog, setTog] = useState(false);

    const columns = [
        {
            name: 'Project',
            selector: row => row?.project_name,
        },
        {
            name: 'Task',
            center: true,
            selector: row => row?.task_name,
        },
        {
            name: 'Start time',
            center: true,
            selector: row => row?.start_date_time,
        },
        {
            name: 'Duration',
            selector: row => convertMinutesToHoursAndMinutes(row?.duration),
            center: true,
            width: '300px' 
        },
        {
            name: 'Tools',
            center: true,
            selector: row => <>
                <svg onClick={() => {setSessionId(row?.id); setTog(true)}} className="me-3" xmlns="http://www.w3.org/2000/svg" width="21.111" height="19" viewBox="1566.706 322 21.111 19"><g data-name="Icon feather-monitor"><path d="M1568.817 322h16.89c1.165 0 2.11.945 2.11 2.111v10.556a2.111 2.111 0 0 1-2.11 2.11h-16.89a2.111 2.111 0 0 1-2.11-2.11V324.11c0-1.166.944-2.111 2.11-2.111Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 10"/><path d="M1573.04 341h8.444" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 11"/><path d="M1577.262 336.778V341" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 12"/></g></svg>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="17.183" height="21.148" viewBox="1607.818 319.852 17.183 21.148"><path d="M1609.14 326.46v13.218c0 .727.594 1.322 1.321 1.322h11.896c.727 0 1.322-.595 1.322-1.322v-13.217h-14.54Zm3.965 11.896h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.644 0h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.974-15.86h-4.296v-1.653a.994.994 0 0 0-.991-.991h-4.626a.994.994 0 0 0-.991.991v1.652h-4.296a.994.994 0 0 0-.992.992v1.652H1625v-1.652a.994.994 0 0 0-.991-.992Zm-5.617 0h-3.966v-1.306h3.966v1.305Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon metro-bin"/></svg> */}
            </>,
        },
    ];


    function dateFormat(dt){
        const currentDate = new Date(dt);
        const day = currentDate.getDate();
        const monthIndex = currentDate.getMonth();
        const year = currentDate.getFullYear();
      
        const formattedDate = `${year}-${monthIndex+1}-${day}`;
        return formattedDate;
    }


    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
      ];
      
      const currentDate = new Date();
      const day = currentDate.getDate();
      const monthIndex = currentDate.getMonth();
      const year = currentDate.getFullYear();
      
      const formattedDate = `${day} ${months[monthIndex]} ${year}`;
      
    //   console.log(formattedDate);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(sessionId){
            console.log("session id :", sessionId)
            const date4 = new Date();
            axios.post(`${apiEndPoint}/api/user/getScreenShotsList`, {session_id: sessionId}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                const temData = res?.data?.projects;
                setData(temData);
                const dataLen = Math.floor(temData?.length / 12);
                console.log("(temData?.length) % 12 : ", temData?.length % 12, dataLen)
                const size =  (temData?.length) % 12 === 0 ? dataLen : dataLen + 1;
                console.log(size);
                const newArray = Array(size).fill(1);
                setArr(newArray);
            }).catch((err) => {
                console.log("err : ", err);
            })
        }        
    }, [sessionId])

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(startDate){
            setTog(false); setData(null); setArr([]); setSessionId(null);
            console.log("start date starte date : ", dateFormat(startDate))
            axios.post(`${apiEndPoint}/api/user/getSessionList`, {date: dateFormat(startDate)}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("get sessions res : ", res);
                setSessions(res?.data?.data);
                setPending(false);
            }).catch((err) => {
                console.log("err : ", err);
                setPending(false);
            })
        } else {
            const date3 = new Date();  
            axios.post(`${apiEndPoint}/api/user/getSessionList`, {date: dateFormat(date3)}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                setPending(false);
                console.log("get sessions res : ", res);
                setSessions(res?.data?.data);
            }).catch((err) => {
                setPending(false);
                console.log("err : ", err);
            })
        }
    }, [startDate])


    return (
        <div className="customer-dashboard">
            <div className="d-flex">
                <div className="w-50 d-flex">
                    <div>
                        <h3 className="mb-0 text2">Work Dashboard</h3>
                        {/* <p className="text3">22 September 2023</p> */}
                    </div>
                    {/* <div>
                        <ul className="dashboard-bar-ul ms-2">
                            <li>
                                <p className="text4">Personnel</p>
                            </li>
                            <li>
                                <div className="p-relative">
                                    <select className="form-control users-list-select" placeholder="Poom">
                                        <option>Poom</option>
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div> */}
                </div>
                <div className="w-50">
                    <ul className="dashboard-bar-ul">
                        <li>
                            <p className="text4">Select Day</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        {/* <li>
                            <button className="filter-btn">Filter</button>
                        </li> */}
                    </ul>
                </div>
            </div>

            <p className="text8">Work Session {formattedDate}</p>
            {tog && (
                <div className="table-card table-card2 mt-30 p-relative2">
                {data ? (
                    <>
                        <div className="w-100 d-flex justify-content-center mb-3">
                            {arr?.map((item, index) => (
                                <div onClick={() => setPagination(index + 1)} key={index} className={pagination === index + 1 ? 'bg-primary cursor-pointer mx-1 text-white rounded  py-2 px-3' : 'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                    {index + 1}
                                </div>
                            ))}

                        </div>
                        {/* <button className="cross-btn" onClick={() =>{setTog(false); setData(null); setArr([]); setSessionId(null); }}>X</button> */}
                        <Row>
                            {data?.map((item, index) => (
                                index >= (pagination * 12) - 12 && index < pagination * 12  && (
                                    <Col md={4} key={index}>
                                        <div className="screen-img" style={{backgroundImage: `url(https://backend.screenpc.com/uploads/screenshots/${item?.image})`, backgroundSize: 'cover'}}>
                                            {/* <p>Screen</p> */}
                                        </div>
                                        <p className="text9 mt-1 mb-2">{item?.current_datetime}
                                            {/* <svg style={{float: 'right'}} xmlns="http://www.w3.org/2000/svg" width="13.683" height="16.841" viewBox="888.317 462 13.683 16.841"><path d="M889.37 467.263v10.525c0 .579.473 1.052 1.052 1.052h9.473c.579 0 1.052-.473 1.052-1.052v-10.525H889.37Zm3.157 9.472h-1.052v-7.367h1.052v7.367Zm2.105 0h-1.052v-7.367h1.052v7.367Zm2.105 0h-1.052v-7.367h1.052v7.367Zm2.105 0h-1.052v-7.367h1.052v7.367Zm2.369-12.63h-3.421v-1.316A.792.792 0 0 0 897 462h-3.683a.792.792 0 0 0-.79.79v1.315h-3.42a.792.792 0 0 0-.79.79v1.315H902v-1.316a.792.792 0 0 0-.79-.789Zm-4.474 0h-3.157v-1.04h3.157v1.04Z" fill="#b8b8b8" fill-rule="evenodd" data-name="Icon metro-bin"/></svg> */}
                                        </p>
                                    </Col>
                                )
                            ))}                    
                        </Row>
                    </>
                ) : (
                    <CustomLoader />
                )}
                </div>
            )}


            <div className="table-card mt-30">
                <DataTableBase 
                    title="Session"
                    columns={columns}
                    data={sessions}
                    progressPending={pending}
                />
            </div>
        </div>
    )
}

export default WorkDetail;