import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DataTableBase from "../../../components/common/DateTableBase";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { companyURL } from "../../../App";
import { checkProjectProgress } from "../../../utility/Index";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";

function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " mins" : "";
  
    return hoursText + minutesText;
}


const Projects = ({setProject}) => {

    // console.log(Math.ceil(((839 / (100 * 60)) * 100) * 100) / 100)

    // const [startDate, setStartDate] = useState();

    const [projects, setProjects] = useState([]);
    // const [dailyProgress, setDailyProgress] = useState([]);
    
    const [pending, setPending] = useState(true);
    const [pagination, setPagination] = useState(1);

    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const [milstone, setMilestone] = useState([]);

    const [selectMilstone, setSelectMilstone] = useState({});

    const [flag, setFlag] = useState(1);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(true);

    const handleOptGroupSelect = (e, id) => {
        console.log("handle change : ", e.target.value);
        setSelectMilstone({project_id: id, sub_milestone_id: e.target.value});
        setShow(true);
    }
    
    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/getMileStoneList`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("Milestone list res : ", res);
            setMilestone(res?.data?.data)
            setLoading3(false);
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
        }).catch((err) => {
            setLoading3(false);
            console.log("Mile stone list err : ", err);
        })
    }, [flag])


    const updateMilestoneFun = () => {
        setLoading2(true);
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/updateProjectPercent`, selectMilstone, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("Milestone list res : ", res);
            setFlag(flag + 1);
            setLoading2(false);
            setShow(false);
            toast.success("Mile Stone Updated Successfully");
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
        }).catch((err) => {
            setLoading2(false);
            console.log("Mile stone list err : ", err);
        })
    }

    const columns2 = [
        {
            name: 'Project Name',
            selector: row => row?.project_name,
        },
        {
            name: 'Actual Time',
            selector: row => row?.timelog_sum_duration === null ? 0 : convertMinutesToHoursAndMinutes(row?.timelog_sum_duration),
        },
        // {
        //     name: 'Break Time',
        //     selector: row => row?.btime,
        // },
        {
            name: <b className="working-th">Progress</b>,
            // selector: row => <div className="text-center"><div className="performancediv"><div className="performnce-value" style={{backgroundColor: checkProjectProgress(row), width: `${row?.timelog_sum_duration ? (row?.timelog_sum_duration / (Number(row?.hours_allocated) * 60)) * 100 : 0}%`}}><span style={{letterSpacing: '-1px'}} className="text5">{row?.timelog_sum_duration ? Math.ceil(((row?.timelog_sum_duration / (Number(row?.hours_allocated) * 60)) * 100) * 100) / 100 : 0}%</span></div> </div></div>,        
            selector: row => <div className="text-center"><div className="performancediv"><div className="performnce-value" style={{backgroundColor: checkProjectProgress(row), width: `${row?.percent_complete ? row?.percent_complete : 0}%`}}><span style={{letterSpacing: '-1px'}} className="text5">{row?.timelog_sum_duration ? Math.ceil(((row?.timelog_sum_duration / (Number(row?.hours_allocated) * 60)) * 100) * 100) / 100 : 0}%</span></div> </div></div>,        
            width: '300px' 
        },
        // {
        //     name: 'Tools',
        //     selector: row => <svg xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326"><path d="M8.813.025C3.953.025 0 3.978 0 8.838c0 4.86 3.953 8.813 8.813 8.813 1.485 0 2.946-.353 4.18-1.033.098.12.208.229.327.328l2.518 2.518a2.568 2.568 0 1 0 3.626-3.626l-2.518-2.518a2.518 2.518 0 0 0-.403-.327c.68-1.234 1.108-2.67 1.108-4.18C17.65 3.953 13.698 0 8.838 0Zm0 2.518c3.5 0 6.295 2.795 6.295 6.295 0 1.662-.605 3.198-1.662 4.33l-.076.076a2.518 2.518 0 0 0-.327.328c-1.108 1.007-2.619 1.586-4.255 1.586a6.265 6.265 0 0 1-6.295-6.295c0-3.5 2.795-6.295 6.295-6.295Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>,
        // },
        {
            name: 'Total Time',
            width: '120px',
            selector: row => row?.hours_allocated+' Hrs',
        },
        {
            name: 'Sessions',
            width: '140px',
            selector: row => <button className="edit-btn px-3" onClick={() => navigate(`/company/sessions/${row?.id}`)}>Sessions</button>
        },
        {
            name: 'Detail',
            selector: row => <button className="edit-btn px-3" onClick={() => { navigate(`/company/project/detail/${row?.id}`); }}>Detail</button>
        },
        {
            name: 'Tasks',
            selector: row => <button className="edit-btn px-3" onClick={() => { navigate(`/company/project/tasks/${row?.id}`); }}>Tasks</button>
        },
        {
            name: "Mile Stone",
            width: '200px',
            selector: row => <select class="form-control" value={row?.sub_milestone_id} onChange={(e) => handleOptGroupSelect(e, row?.id)}>
                {/* {console.log("miltone : ", milstone)} */}
                {milstone?.map((item, index) => (
                    <optgroup label={item?.name} key={index}>
                        {item?.sub_milestones?.map((sub_item, ind) => (
                            <option value={sub_item?.id} key={index+ind}>{sub_item?.name}</option>
                        ))}
                    </optgroup>
                ))}
        </select>            
        }
    ];
    
    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/projects?page=${pagination}`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setProjects(res?.data?.data);
            setPending(false);
        }).catch((err) => {
            console.log("err : ", err);
        })
    }, [pagination, flag])

    // console.log("projects", dailyProgress);

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];
      
    const currentDate = new Date();
    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
    
    const formattedDate = `${day} ${months[monthIndex]} ${year}`;


    const prevFunc = () => {
        if(pagination > 1){
            setPending(true);
            setPagination(pagination - 1);
        }
    }
    
    const nextFunc = () => {
        if(pagination < projects?.last_page){
            setPending(true);
            setPagination(pagination + 1);
        }
    }


    return (
        <div className="customer-dashboard">
            <ToastContainer />
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Are you sure you want to update this?</h2>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    No
                </Button>
                {loading2 ? (
                    <Spinner className="ms-3" />
                ) : (
                <Button className="bg-0facd5" onClick={updateMilestoneFun} variant="primary">
                    Yes
                </Button>
                )}
                </Modal.Footer>
            </Modal>
            <div className="d-flex">
                <div className="w-25">
                    <h3 className="mb-0 text2">Project <button className="edit-btn ms-4 px-3" onClick={() => navigate('/company/project/add')}>Add Project</button></h3>
                    {/* <p className="text3">{formattedDate}</p> */}
                </div>
                {/* <div className="w-75">
                    <ul className="dashboard-bar-ul">
                        <li>
                            <p className="text4">Select Range</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li> - </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li>
                            <button className="filter-btn">Filter</button>
                        </li>
                    </ul>
                </div> */}
            </div>


            <div className="table-card mt-30">
                {/* {projects && ( */}
                    <DataTableBase 
                        title="Project"
                        columns={columns2}
                        data={projects && projects?.data}
                        progressPending={pending}
                    />

                {/* )} */}
                {!pending && (
                    <div className="w-100 d-flex justify-content-end mb-3 mt-4">
                        <div onClick={prevFunc} className={'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                            Prev
                        </div>                        
                        <div className={'bg-0facd5 cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                            {pagination}
                        </div>
                        <div onClick={nextFunc} className={'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                            Next
                        </div>
                    </div>
                )}
            </div>

        </div>
    )
}

export default Projects;