import React from "react";
import DataTableBase from "../../../components/common/DateTableBase";
import { useNavigate } from "react-router-dom";



const dataa = [
    {
        id: 1,
        name: "Griff",
        status: "Active",
        wtime: "10 November 2020",
        btime: "-",
        working: 90,
        ptime: "2y 10m 12d"
    },
    {
        id: 2,
        name: "Poom",
        status: "Deactive",
        wtime: "11 December 2021",
        btime: "02 October 2023",
        working: 40,
        ptime: "1y 8m 29d"
    },
]

const CustomerPersonnel = () => {
    const navigate = useNavigate();
    
    const columns = [
        {
            name: 'Personnel Name',
            selector: row => row.name,
        },
        {
            name: 'Status',
            selector: row => row.status === "Active"? <span className="text6">{row.status}</span> : <span className="text7">{row.status}</span>,
            center: true
        },
        {
            name: 'Start working',
            selector: row => row.wtime,
            center: true
        },
        {
            name: 'End work',
            selector: row => row?.btime,
            width: '300px', 
            center: true,
        },
        {
            name: 'Day',
            center: true,
            selector: row => row.ptime,
        },
        {
            name: 'Tools',
            center: true,
            selector: row => <>
                <svg className="me-1 cursor-pointer" onClick={() => navigate('/workdetail')} xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326"><path d="M8.813.025C3.953.025 0 3.978 0 8.838c0 4.86 3.953 8.813 8.813 8.813 1.485 0 2.946-.353 4.18-1.033.098.12.208.229.327.328l2.518 2.518a2.568 2.568 0 1 0 3.626-3.626l-2.518-2.518a2.518 2.518 0 0 0-.403-.327c.68-1.234 1.108-2.67 1.108-4.18C17.65 3.953 13.698 0 8.838 0Zm0 2.518c3.5 0 6.295 2.795 6.295 6.295 0 1.662-.605 3.198-1.662 4.33l-.076.076a2.518 2.518 0 0 0-.327.328c-1.108 1.007-2.619 1.586-4.255 1.586a6.265 6.265 0 0 1-6.295-6.295c0-3.5 2.795-6.295 6.295-6.295Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>
                <svg className="ms-1" xmlns="http://www.w3.org/2000/svg" width="22.871" height="20.326" viewBox="1568.803 293.674 22.871 20.326"><path d="m1584.789 296.974 3.581 3.581c.151.151.151.397 0 .548l-8.672 8.672-3.684.41a.772.772 0 0 1-.854-.855l.409-3.684 8.672-8.672c.15-.151.397-.151.548 0Zm6.432-.91-1.937-1.937a1.553 1.553 0 0 0-2.192 0l-1.406 1.405a.388.388 0 0 0 0 .548l3.582 3.582c.15.15.397.15.548 0l1.405-1.406a1.553 1.553 0 0 0 0-2.192Zm-7.17 11.353v4.042h-12.707v-12.707h9.125a.488.488 0 0 0 .337-.138l1.588-1.589a.476.476 0 0 0-.337-.814h-11.348a1.906 1.906 0 0 0-1.906 1.906v13.977c0 1.052.853 1.906 1.906 1.906h13.977a1.906 1.906 0 0 0 1.906-1.906v-6.266a.477.477 0 0 0-.814-.337l-1.589 1.588a.488.488 0 0 0-.139.338Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon awesome-edit"/></svg>
            </>,
        },
    ];



    return (
        <div className="customer-dashboard">
            <h3 className="mb-0 text2">Dashboard</h3>
            
            <div className="table-card mt-30">
                <DataTableBase 
                    title="Personnel total : 2"
                    columns={columns}
                    data={dataa}
                />
            </div>
        </div>
    )
}

export default CustomerPersonnel;