import React, { useEffect, useState } from "react";
import DataTableBase from "../../../components/common/DateTableBase";
import DatePicker from "react-datepicker";
import { Carousel, Col, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { companyURL } from "../../../App";
import { useNavigate, useParams } from "react-router-dom";
import CustomLoader from "../../../components/common/CustomLoader";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modal from 'react-bootstrap/Modal';
import { MdCancel } from "react-icons/md";

function dateFormat(dt){
    const currentDate = new Date(dt);
    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
  
    const formattedDate = `${year}-${monthIndex+1}-${day}`;
    return formattedDate;
}

function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " min" : "";
  
    return hoursText + minutesText;
}

const ProjectSessionScreenShots = () => {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    
    const [arr, setArr] = useState([]);
    const [pagination, setPagination] = useState(1);

    const {id, sid} = useParams();

    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = (ind) => {
        setIndex(ind);
        setShow(true);
    };
    

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };


    // const fetchData = async () => {
    //     // Mock API call
    //     // const response = await fetch('your-api-endpoint');
    //     // const dataFromAPI = await response.json();
    //     // setData(dataFromAPI);
    //     const token = localStorage.getItem("token");

    //     axios.post(`${companyURL}/projectSessionsByID`, {project_id: id},{
    //         headers: {'Authorization': `Bearer ${token}`}
    //     }).then((res) => {
    //         console.log("res : ", res);
    //         const temData = res?.data?.data;
    //         setData(temData);
    //         // setPending(false);
    //     }).catch((err) => {
    //         // setPending(false);
    //         console.log("err : ", err);
    //     })
    // };            


    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];
      
      const currentDate = new Date();
      const day = currentDate.getDate();
      const monthIndex = currentDate.getMonth();
      const year = currentDate.getFullYear();
      
      const formattedDate = `${day} ${months[monthIndex]} ${year}`;
      
    //   console.log(formattedDate);

    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     if(startDate){
    //         setTog(false); setData2([]); setArr([]); setSessionID(null);
    //         setPending(true);
    //         axios.post(`${companyURL}/projectSessionsByID`, {project_id: id,  date: dateFormat(startDate)},{
    //             headers: {'Authorization': `Bearer ${token}`}
    //         }).then((res) => {
    //             console.log("res : ", res);
    //             const temData = res?.data?.data;
    //             setData(temData);
    //             setPending(false);
    //         }).catch((err) => {
    //             setPending(false);
    //             console.log("err : ", err);
    //         })
    //     } else {
    //         axios.post(`${companyURL}/projectSessionsByID`, {project_id: id},{
    //             headers: {'Authorization': `Bearer ${token}`}
    //         }).then((res) => {
    //             console.log("res : ", res);
    //             const temData = res?.data?.data;
    //             setData(temData);
    //             setPending(false);
    //         }).catch((err) => {
    //             setPending(false);
    //             console.log("err : ", err);
    //         })
    //     }
    // }, [startDate])

    const [pending, setPending] = useState(false);
    const [pending2, setPending2] = useState(true);
    const [minutes, setMinutes] = useState(null);

    useEffect(() => {
        console.log("shot")
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/projectShotsByID`, {session_id: sid},{
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("Screenshots res : ", res);
            const temData = res?.data?.data[0]?.screenshots;
            setData2(res?.data?.data[0]);
            const dataLen = Math.floor(temData?.length / 12);
            console.log("(temData?.length) % 12 : ", temData?.length % 12, dataLen)
            const size =  (temData?.length) % 12 === 0 ? dataLen : dataLen + 1;
            console.log(size);
            const newArray = Array(size).fill(1);
            setArr(newArray);
            setPending2(false);
        }).catch((err) => {
            setPending2(false);
            console.log("err : ", err);
        })
    }, [])
      

    const updateTimeFunc = () => {
        if(minutes){
            setPending(true);
            const token = localStorage.getItem("token");
            axios.post(`${companyURL}/updateSessionDeuratonByID`, {session_id: sid, duration: minutes},{
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("update session duration res : ", res);
                setMinutes(null);
                toast.success(res?.data?.message);
                setPending(false);
                
            }).catch((err) => {
                setPending(false);
                console.log("update session duration err : ", err);
            })
        }
    }

    const prevFunc = () => {
        if(pagination > 1){
            setPagination(pagination - 1);
        }
    }
    const nextFunc = () => {
        if(pagination < arr?.length){
            setPagination(pagination + 1);
        }
    }

    const handleButtonClick = async (actionType) => {
        // Call API to save the action in the database
        await saveActionToDatabase(sid, actionType);
        // Update the data after action is saved
        
    };
    
    const saveActionToDatabase = async (rowId, actionType) => {
        const token = localStorage.getItem("token");

        axios.post(`${companyURL}/updateSessionColorByID`, {
            session_id: rowId, 
            colorValue: actionType
        },
        {headers: {
            Authorization: `Bearer ${token}`
        }}).then((res) => {
            console.log("response status api : ", res);
            toast.success("Status updated successfully");
            // fetchData();
        }).catch((err) => {
            console.log("Error status api : ", err);
        })
    };

    return (
        <div className="customer-dashboard">
            <ToastContainer />
                {!pending2 && (

                    <div className="d-flex mt-2">
                        <div className="w-20 me-2">
                            <div className="d-flex dashboard-card">
                                <div className="w-751">
                                    <h4 className="text-white">Project</h4>
                                    <h1 className="text-white">{data2 && data2?.project?.project_name}</h1>
                                </div>
                                <div className="w-251"></div>
                            </div>
                        </div>

                        <div className="mx-2 w-20">
                            <div className="d-flexx dashboard-card">
                                <div className="w-751">
                                    <h4 className="text-white">User</h4>
                                    <h1 className="text-white w-100">{data2 && data2?.user?.first_name} 
                                        <span style={{float: 'right'}}>                                            
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="19.174" height="20"><path d="M9.587 9.53c2.427 0 4.394-2.134 4.394-4.765C13.98 2.133 12.014 0 9.587 0S5.193 2.133 5.193 4.765c0 2.631 1.967 4.765 4.394 4.765Zm0 2.093C3.745 11.623 0 15.12 0 16.822V20h19.174v-3.178c0-2.059-3.546-5.199-9.587-5.199Z" fill="#fff" fill-rule="evenodd" data-name="person-svgrepo-com (1)"/></svg>
                                        </span>
                                    </h1>
                                </div>
                                <div className="w-251"></div>
                            </div>
                        </div>
                        
                        <div className="w-20 mx-2">
                            <div className="d-flex dashboard-card">
                                <div className="w-751">
                                    <h4 className="text-white">Duration</h4>
                                    <h3 className="text-white pt-2">{data2 && convertMinutesToHoursAndMinutes(Number(data2?.duration))}</h3>
                                </div>
                                <div className="w-251"></div>
                            </div>                    
                        </div>

                        <div className="w-20 ms-2">
                            <div className="d-flex dashboard-card">
                                <div className="w-751">
                                    <h4 className="text-white">Hour Rate</h4>
                                    <h1 className="text-white">${data2 && data2?.user?.hour_rate}</h1>
                                </div>
                                <div className="w-251"></div>
                            </div>
                        </div>
                        <div className="w-20 ms-2">
                            <div className="d-flex dashboard-card">
                                <div className="w-751">
                                    <h4 className="text-white">Cost</h4>
                                    <h1 className="text-white">${data2 && Number((Number(data2?.duration) / 60) * Number(data2?.user?.hour_rate)).toFixed(2)}</h1>
                                </div>
                                <div className="w-251"></div>
                            </div>
                        </div>
                    </div>
                )}
            
            {/* <div className="d-flex">
                <div className="w-100 d-flex">
                    <div className="table-card table-card2">
                        <div className="d-flex border-bottom-2px mb-3">
                            <div className="w-50">
                                <h3 className="mb-3 text2">Project: <b>{data2 && data2?.project?.project_name}</b></h3>                        
                            </div>
                            <div className="w-50">
                                <h3 className="mb-3 text2">User: <b> {data2 && data2?.user?.first_name} </b></h3>                        
                            </div>
                        </div>
                        <div className="d-flex border-bottom-2px mb-3">
                            <div className="w-50">
                                <h3 className="mb-3 text2">Hour Rate: <b>{data2 && data2?.user?.hour_rate}</b> </h3>                        
                            </div>
                            <div className="w-50">
                                <h3 className="mb-3 text2">Cost: <b>{data2 && (Number(data2?.duration) / 60) * Number(data2?.user?.hour_rate)}</b> </h3>                        
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="w-50">
                                <h3 className="mb-3 text2">Duration: <b></b></h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}

            <p className="text8 mt-4">Work Session {formattedDate}</p>
            <div className="table-card table-card2 mt-30 p-relative2">                
                <div className="d-flex mb-4 justify-content-between">
                    <div className="d-flex">
                        <div className="pt-1">
                            <b>Update Duration: </b>
                        </div>
                        <div className="mx-2 ms-3">
                            <input type="number" style={{width: "85px"}} defaultValue={minutes} onChange={(e) => setMinutes(e.target.value)} className="form-control" />
                        </div>
                        <div className="pt-1">Mins</div>
                        <div className="ms-3">
                            {pending ? (
                                <div>
                                    <Spinner />
                                </div>
                            ) : (
                                <button className="filter-btn px-3 py-2" onClick={updateTimeFunc}>Update</button>
                            )}
                        </div>
                    </div>
                    <div className="w-355x">
                        <div className="d-flex">
                            <div onClick={() => { handleButtonClick(0); }} className="pointer white-radio-div"></div>
                            <div onClick={() => { handleButtonClick(1); }} className="pointer green-radio-div"></div>
                            <div onClick={() => { handleButtonClick(2); }} className="pointer yellow-radio-div"></div>
                            <div onClick={() => { handleButtonClick(3); }} className="pointer red-radio-div"></div>
                        </div>
                    </div>
                    <div className="w-25x">
                        <div className="w-100 d-flex justify-content-end mb-3">
                            <div onClick={prevFunc} className={'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                Prev
                            </div>
                            {/* {arr?.map((item, index) => (
                                <div onClick={() => setPagination(index + 1)} key={index + 1} className={pagination === index + 1 ? 'bg-primary cursor-pointer mx-1 text-white rounded  py-2 px-3' : 'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                    {index + 1}
                                </div>
                            ))} */}
                                <div className={'bg-0facd5 cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                    {pagination}
                                </div>
                            <div onClick={nextFunc} className={'bg-secondary cursor-pointer mx-1 text-white rounded  py-2 px-3'}>
                                Next
                            </div>
                        </div>
                    </div>
                </div>
                {pending2 ? (
                    <CustomLoader />
                ) : (
                    <>
                        
                        {/* <button className="cross-btn" onClick={() =>{setTog(false); setData2([]); setArr([]); setSessionID(null); }}>X</button> */}
                        <Row>
                            {data2?.screenshots?.map((item, index) => (
                                index >= (pagination * 12) - 12 && index < pagination * 12  && (
                                    <Col md={4} key={index}>
                                        <div onClick={() => {handleShow(index)}} className="screen-img pointer" style={{backgroundImage: `url(https://backend.screenpc.com/uploads/screenshots/${item?.image})`, backgroundSize: 'cover'}}>
                                            {/* <p>Screen</p> */}
                                        </div>
                                        <p className="text9 mt-1 mb-2">{item?.current_datetime}
                                            <svg style={{float: 'right'}} xmlns="http://www.w3.org/2000/svg" width="13.683" height="16.841" viewBox="888.317 462 13.683 16.841"><path d="M889.37 467.263v10.525c0 .579.473 1.052 1.052 1.052h9.473c.579 0 1.052-.473 1.052-1.052v-10.525H889.37Zm3.157 9.472h-1.052v-7.367h1.052v7.367Zm2.105 0h-1.052v-7.367h1.052v7.367Zm2.105 0h-1.052v-7.367h1.052v7.367Zm2.105 0h-1.052v-7.367h1.052v7.367Zm2.369-12.63h-3.421v-1.316A.792.792 0 0 0 897 462h-3.683a.792.792 0 0 0-.79.79v1.315h-3.42a.792.792 0 0 0-.79.79v1.315H902v-1.316a.792.792 0 0 0-.79-.789Zm-4.474 0h-3.157v-1.04h3.157v1.04Z" fill="#b8b8b8" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
                                        </p>
                                    </Col>
                                )
                            ))}                    
                        </Row>                        
                    </>
                )}
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
                <div className="ss-model-div">
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        {data2?.screenshots?.map((item, ind) => (
                            // ind >= (pagination * 12) - 12 && ind < pagination * 12  && (
                                <Carousel.Item key={ind}>
                                    <img  width={"100%"} src={`https://backend.screenpc.com/uploads/screenshots/${item?.image}`} />                                
                                </Carousel.Item>
                            // )
                        ))}
                            {/* <img src="" />
                            <ExampleCarouselImage text="First slide" />
                            <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        {/* <Carousel.Item>
                            <ExampleCarouselImage text="Second slide" />
                            <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <ExampleCarouselImage text="Third slide" />
                            <Carousel.Caption>
                            <h3>Third slide label</h3>
                            <p>
                                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                            </p>
                            </Carousel.Caption>
                        </Carousel.Item> */}
                    </Carousel >
                    <MdCancel onClick={handleClose} color="#0FACD5" className="model-cross-icon pointer" size={"0.6cm"} />
                    {/* <svg className="model-cross-icon" /> */}
                </div>
            </Modal>
        </div>
    )
}

export default ProjectSessionScreenShots;