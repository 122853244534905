import React, { useEffect } from "react";
import Topbar from "../../components/common/Topbar";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../../components/common/Navbar";

const Manager = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem('role');
        const token = localStorage.getItem('m_token');
        if(!token){
            navigate('/login');        
        }
        if(role !== "Manager"){
            navigate('/');        
        }
    }, [])
    
    return (
        <div className="w-100 d-flex">
            <div>
                <Navbar type={3} />
            </div>
            <div className="content-div">
                <Topbar type="1" />
                {/* <CustomerManagerDashboard /> */}
                <Outlet />
            </div>
        </div>
    )
}

export default Manager;