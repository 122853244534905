import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DataTableBase from "../../../components/common/DateTableBase";
import axios from "axios";
import { apiEndPoint } from "../../../App";

function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " mins" : "";
  
    return hoursText + minutesText;
}

const columns0 = [
    {
        name: "Working Time",
        selector: row => row?.time === 0 ? 0 : convertMinutesToHoursAndMinutes(row?.time),
    },
    {
        name: "Status",
        selector: row => row?.status === 1? <span className="text6">Online</span> : <span className="text7">Offline</span>,
    },
    // {
    //     name: 'Working Time',
    //     selector: row => convertMinutesToHoursAndMinutes(row?.duration),
    //     // selector: row => {
    //     //     let sum = 0;
    //     //     row?.today_timelogs?.map(item => {
    //     //         sum = sum + Number(item?.duration);
    //     //     })
            
    //     //     return convertMinutesToHoursAndMinutes(sum);
    //     // },    
    // },
    // {
    //     name: 'Current Stauts',
    //     selector: row => row.status === "1"? <span className="text6">Active</span> : <span className="text7">Deactive</span>,
    // }
]

const columns = [
    {
        name: 'Project Name',
        selector: row => row.name,
    },
    {
        name: 'Working Time',
        selector: row => row.wtime,
    },
    {
        name: 'Break Time',
        selector: row => row.btime,
    },
    {
        name: <><b className="working-th">Working</b><b className="working-th break-th ms-2">Break</b></>,
        // selector: row => <div className="text-center"><div className="performancediv"><div className="performnce-value" style={{width: `${row?.working}%`}}><span className="text5">{row.working}%</span></div> </div></div>,
        selector: row => <div className="text-end">
            <div className="performancediv performancediv-break d-flex">
                <div className="performnce-value text-center" style={{width: `${row?.working ? row?.working : '90' }%`}}>
                    <span className="text5">{row?.wtime}</span>
                </div>
                {/* <div className="text-center" style={{width: `${100 - row?.working}%`}}> */}
                <div className="text-center" style={{width: `${10}%`}}>
                    <span className="text5">{row?.btime}</span> 

                </div>
            </div>
            </div>,
        width: '300px' 
    },
    {
        name: 'Project time',
        selector: row => row.ptime,
    },
];

const columns2 = [
    {
        name: 'Project Name',
        selector: row => row?.project_name,
    },
    {
        name: 'Working Time',
        selector: row => {
            let sum = 0;
            row?.timelog?.map(item => {
                sum = sum + Number(item?.duration);
            })
            if(sum === 0){
                return 0;
            }
            return convertMinutesToHoursAndMinutes(sum);
        },
    },
    // {
    //     name: 'Break Time',
    //     selector: row => row?.btime,
    // },
    // {
    //     name: <b className="working-th">Working</b>,
    //     selector: row => <div className="text-center"><div className="performancediv"><div className="performnce-value" style={{width: `${row?.working ? 23 : 90}%`}}><span className="text5">{row?.working ? 90 : 90}%</span></div> </div></div>,        
    //     width: '300px' 
    // },
    // {
    //     name: 'Tools',
    //     selector: row => <svg xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326"><path d="M8.813.025C3.953.025 0 3.978 0 8.838c0 4.86 3.953 8.813 8.813 8.813 1.485 0 2.946-.353 4.18-1.033.098.12.208.229.327.328l2.518 2.518a2.568 2.568 0 1 0 3.626-3.626l-2.518-2.518a2.518 2.518 0 0 0-.403-.327c.68-1.234 1.108-2.67 1.108-4.18C17.65 3.953 13.698 0 8.838 0Zm0 2.518c3.5 0 6.295 2.795 6.295 6.295 0 1.662-.605 3.198-1.662 4.33l-.076.076a2.518 2.518 0 0 0-.327.328c-1.108 1.007-2.619 1.586-4.255 1.586a6.265 6.265 0 0 1-6.295-6.295c0-3.5 2.795-6.295 6.295-6.295Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>,
    // },
    {
        name: 'Project Time',
        selector: row => row?.hours_allocated+' Hrs',
    },
];

const dataa = [
    {
        id: 1,
        name: "Find My Car",
        wtime: "00:55",
        btime: "00:00",
        working: 90,
        ptime: "04:00"
    },
    {
        id: 2,
        name: "BIGLOT",
        wtime: "1:23:45",
        btime: "02:00",
        working: 40,
        ptime: "04:00:00"
    },
]
const dataa2 = [
    {
        id: 1,
        name: "Griff",
        wtime: "07:30",
        btime: "00:30",
        working: 90,
        ptime: "04:00"
    },
    {
        id: 2,
        name: "BIGLOT",
        wtime: "06:00",
        btime: "02:00",
        working: 75,
        ptime: "04:00:00"
    },
]

const CustomerDashboard = () => {
    const [startDate, setStartDate] = useState();
    const [pending, setPending] = useState(true);

    const [projects, setProjects] = useState([]);
    const [dailyProgress, setDailyProgress] = useState([]);
    

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${apiEndPoint}/api/user/getTimeLogByProjects`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setProjects(res?.data?.data?.all);
            // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
            setDailyProgress([{time: res?.data?.data?.today, status: res?.data?.data?.user_status}]);
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
            setPending(false);
        }).catch((err) => {
            console.log("err : ", err);
        })
    }, [])

    console.log("projects", dailyProgress);

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];
      
    const currentDate = new Date();
    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
    
    const formattedDate = `${day} ${months[monthIndex]} ${year}`;


    return (
        <div className="customer-dashboard">
            <div className="d-flex">
                <div className="w-25">
                    <h3 className="mb-0 text2">Dashboard</h3>
                    <p className="text3">{formattedDate}</p>
                </div>
                <div className="w-75">
                    {/* <ul className="dashboard-bar-ul">
                        <li>
                            <p className="text4">Select Range</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li> - </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li>
                            <button className="filter-btn">Filter</button>
                        </li>
                    </ul> */}
                </div>
            </div>


            <div className="table-card mt-30">
                {/* {dailyProgress && ( */}
                    <DataTableBase 
                        title="Your Time"
                        columns={columns0}
                        data={dailyProgress}
                        progressPending={pending}
                    />
                {/* )} */}
            </div>
            <div className="table-card mt-30">
                {/* {projects && ( */}
                    <DataTableBase 
                        title="Project"
                        columns={columns2}
                        data={projects && projects}
                        progressPending={pending}
                    />

                {/* )} */}
            </div>

        </div>
    )
}

export default CustomerDashboard;