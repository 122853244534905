import React, { useState } from "react";
import logo from "../../assets/logo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiEndPoint } from "../../App";

const AdminLogin = () => {
    const {register, handleSubmit, watch, formState: { errors },} = useForm();
    const [role, setRole] = useState("admin");
    const navigate = useNavigate();

    const roleWatch = watch("role");
    
    console.log("Role watch : ", roleWatch);

    console.log("role : ", role);
    const onSubmit = (data) => {
        console.log("Data : ", data);
        axios.post(`${apiEndPoint}/api/admin/login`, data).then((res) => {
            console.log("Admin login Response : ", res);
            localStorage.setItem("pca_token", res?.data?.token);
            localStorage.setItem("email", res?.data?.email);
            localStorage.setItem("company_name", `${res?.data?.name}`);
            // localStorage.setItem("first_name", res?.data?.first_name);
            // localStorage.setItem("last_name", res?.data?.last_name);
            // localStorage.setItem("mobile", res?.data?.mobile_number);
            localStorage.setItem("role", res?.data?.role);
            // localStorage.setItem("company_name", res?.data?.company_name);
            localStorage.setItem("pca_tab", "1");
            navigate('/admin');

        }).catch((err) => {
            console.log("Error : ", err);
            toast.error(err?.code === "ERR_NETWORK" ? "Please check your internet connection" : err?.response?.data?.error[0]);
        })
    }

    return (
        <div className="login-page">
            <form onSubmit={handleSubmit(onSubmit)}>
                <img src={logo} className="logo" alt="logo" />
                <div className="login-div">
                    
                    {/* <div className="d-flex mb-4">
                        <div className="w-25 align-start">
                            <span>Login As</span>
                        </div>
                        <div className="w-75">
                            <select {...register("loginAs")} className="form-control">
                                <option value="Company">Company</option>
                                <option value="Manager">Manager</option>
                                <option value="User">Employee</option>
                            </select>
                        </div>
                    </div> */}

                    <div className="d-flex">
                        <div className="w-25 align-start">
                            <span>Email</span>
                        </div>
                        <div className="w-75">
                            <input type="email" {...register("email", {required: true})} placeholder="Enter your email" className="form-control w-100" />
                        </div>
                    </div>
                    <div className="d-flex mt-4">
                        <div className="w-25 align-start">
                            <span>Password</span>
                        </div>
                        <div className="w-75">
                            <input autoComplete="new-password" type="password" {...register("password", {required: true})} placeholder="Enter your password" className="form-control w-100" />
                        </div>
                    </div>
                    
                    

                    <div className="d-flex mt-3">
                        <div className="w-25"></div>
                        <div>
                            <div className="form-check align-start">
                                <input className="form-check-input checkbox-options form-checkbox-input" type="checkbox" value="" id="flexCheckChecked" />
                                <label className="form-check-label ms-3" htmlFor="flexCheckChecked">
                                    Stay logged in
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-3">
                        <div className="w-25 align-start">
                            {/* <span>Password</span> */}
                        </div>
                        <div className="w-75">
                            <button className="login-btn" type="submit">Login</button>                            
                            <p className="register-or-login">{roleWatch === "admin" ? (<> <span className="me-1">Register</span> or </>) : ''} <span className="ms-1">Forget password?</span></p>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer />
        </div>
    )
}

export default AdminLogin;