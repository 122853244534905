import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DataTableBase from "../../../components/common/DateTableBase";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { companyURL, imgEndPoint } from "../../../App";
import { checkProjectProgress } from "../../../utility/Index";
import { FaRegUserCircle } from "react-icons/fa";

function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " mins" : "";
  
    return hoursText + minutesText;
}




const data2 = [
    {
        id: 1,
        task_name: "UI/UX"
    },
    {
        id: 2,
        task_name: "Front End Desing"
    }
]

const ProjectsDetail = () => {
    const [startDate, setStartDate] = useState();
    const [pending, setPending] = useState(true);

    const {id} = useParams();

    const navigate = useNavigate();

    const [project, setProject] = useState(null);
    const [users, setUsers] = useState([]);

    // const [dailyProgress, setDailyProgress] = useState([]);


    const columns2 = [
        {
            name: 'User Name',
            selector: row => `${row?.first_name} ${row?.last_name}`,
        },
        {
            name: 'Profile',
            selector: row => row?.profileImage ? <img src={`${imgEndPoint}/${row?.profileImage}`} className="profile-table-view" alt="profile" /> : <FaRegUserCircle size="32px" />,
        },
        // {
        //     name: 'Personnel',
        //     selector: row => {            
        //     const inputString = row?.team_list;
            
        //     const arr = inputString?.split(',');
    
        //         // const numb = Number(firstCharacter);
        //         console.log("Array length ", arr?.length)
    
        //         if (arr?.length > 0) {
        //             return arr.map((item, index) => (
        //                  <svg key={index + 1} onClick={() => navigate(`/company/personnel/employee/${item}`)} className="ms-2 pointer" xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="1036 691.95 41 41"><g data-name="Group 227"><path d="M1036 712.4a20.5 20.5 0 1 1 0 .1z" fill="#2362aa" fill-rule="evenodd" data-name="Ellipse 1"/><g data-name="Icon feather-user"><path d="M1066.093 724.053v-2.44a4.88 4.88 0 0 0-4.88-4.88h-9.76a4.88 4.88 0 0 0-4.88 4.88v2.44" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 2"/><path d="M1061.213 706.973a4.88 4.88 0 1 1-9.76 0 4.88 4.88 0 0 1 9.76 0Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#707070" fill="transparent" data-name="Path 3"/></g></g></svg>                    
        //             ));
        //           }
              
                
        //     },
        // },
    ];
    

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/projectByID`, {project_id: id}, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setProject(res?.data?.data?.project);
            setUsers(res?.data?.data?.users);
            setPending(false);
        }).catch((err) => {
            console.log("err : ", err);
        })
    }, [])

    // console.log("projects", dailyProgress);

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];

    function formatDate(dt){
       const currentDate = new Date(dt);
       const day = currentDate.getDate();
       const monthIndex = currentDate.getMonth();
       const year = currentDate.getFullYear();
       
       const formattedDate = `${day} ${months[monthIndex]} ${year}`;
      return formattedDate;
    }
    

    console.log("Project : ", project);



    return (
        <div className="customer-dashboard">
            <div className="d-flex">
                <div className="w-25">
                    <h3 className="mb-0 text2">Project Detail</h3>
                    {/* <p className="text3">{formattedDate}</p> */}
                </div>
                {/* <div className="w-75">
                    <ul className="dashboard-bar-ul">
                        <li>
                            <p className="text4">Select Range</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li> - </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li>
                            <button className="filter-btn">Filter</button>
                        </li>
                    </ul>
                </div> */}
            </div>


            <div className="table-card mt-30">
                <h2 className="text15 mt-2">Project details</h2>
                <div className="d-flex w-100 mt-4">
                    <div className="d-flex w-50">
                        <div className="w-35">
                            <p className="text16 mb-2">Project name</p>
                            <p className="text16 mb-2">Progress</p>
                            <p className="text16 mb-2">Allocated Hours</p>
                            <p className="text16 mb-2">Budget</p>
                            <p className="text16 mb-2">Current Month Working</p>
                            <p className="text16 mb-2">Total Working</p>
                        </div>
                        <div>
                            <p className="text17 mb-2">{project?.project_name}</p>
                            <p className="text17 mb-2">
                                <div className="text-center">
                                    <div className="performancediv">
                                        <div className="performnce-value" style={{backgroundColor: checkProjectProgress(project), width: `${project?.percent_complete ? project?.percent_complete : 0}%`}}>
                                            <span style={{letterSpacing: '-1px'}} className="text5">{project?.timelog_sum_duration ? Math.ceil(((project?.timelog_sum_duration / (Number(project?.hours_allocated) * 60)) * 100) * 100) / 100 : 0}%</span>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <p className="text17 mb-2">{project?.hours_allocated}</p>
                            <p className="text17 mb-2">{project?.project_budget}</p>
                            <p className="text17 mb-2">{project?.duration_month ? convertMinutesToHoursAndMinutes(project?.duration_month) : '0 Hrs 0 Mins' }</p>
                            <p className="text17 mb-2">{project?.timelog_sum_duration ? convertMinutesToHoursAndMinutes(project?.timelog_sum_duration) : '0 Hrs 0 Mins'} </p>
                            {/* {project?.tasks?.map((item, index) => (
                                <p className="text17 mb-0" key={index + 1}>{item?.heading}</p>
                            ))} */}
                            
                        </div>
                    </div>
                    <div className="d-flex w-50">
                        <div className="w-35">
                            <p className="text16 mb-2">Manager</p>
                            <p className="text16 mb-2">Started At</p>
                            <p className="text16 mb-2">Deadline</p>
                            <p className="text16 mb-2">Phase</p>
                        </div>
                        <div>
                            <p className="text17 mb-2">Joe Sripatana</p>
                            <p className="text17 mb-2">{formatDate(project?.start_date)}</p>
                            <p className="text17 mb-2">{formatDate(project?.deadline)}</p>
                            <p className="text17 mb-2">{project?.milestone_title+` > `+project?.sub_milestone_title}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="edit-btn mb-2" onClick={() => navigate(`/company/project/edit/${id}`)}>Edit</button>
                </div>
            </div>
            <div className="table-card mt-30">
                {/* {projects && ( */}
                    <DataTableBase 
                        title="Users"
                        columns={columns2}
                        progressPending={pending}
                        data={users}
                    />

                {/* )} */}
            </div>

        </div>
    )
}

export default ProjectsDetail;