import React, { useEffect, useState } from "react";
import DataTableBase from "../../../components/common/DateTableBase";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiEndPoint, companyURL } from "../../../App";


function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " mins" : "";
  
    return hoursText + minutesText;
}

function formateDate(date1){
    if(date1 === null){
        return "null"
    } else {
        const inputDate = new Date(date1);
    
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = inputDate.toLocaleDateString('en-US', options);
    
        return formattedDate; // Output: "September 26, 2023"
    }
}



const PersonalUser = () => {
    const [users, setUsers] = useState([]);
    const [employees, setEmployees] = useState([]);

    const [pending, setPending] = useState(true);
    const [pending2, setPending2] = useState(true);
    
    const navigate = useNavigate();

    const columns1 = [
        {
            name: "Personnel user",
            // selector: row => convertMinutesToHoursAndMinutes(row?.time),
            selector: row => `${row?.first_name} ${row?.last_name}`,
        },
        {
            name: "Status",
            selector: row => row.status === 1? <span className="text6">Active</span> : <span className="text7">Inactive</span>,
        },
        {
            name: "Start working",
            selector: row => formateDate(row?.created_at),
        },
        {
            name: "Tools",
            selector: row => <>
                <svg className="cursor-pointer" onClick={() => navigate(`/company/personnel/employee/${row?.id}`)} xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326" viewBox="1538.477 293.674 20.326 20.326"><path d="M1548.64 305.107a5.718 5.718 0 0 0 5.717-5.716 5.718 5.718 0 0 0-5.717-5.717 5.718 5.718 0 0 0-5.717 5.717 5.718 5.718 0 0 0 5.717 5.716Zm5.082 1.27h-2.188a6.918 6.918 0 0 1-5.788 0h-2.187a5.081 5.081 0 0 0-5.082 5.082v.635c0 1.052.854 1.906 1.906 1.906h16.515a1.906 1.906 0 0 0 1.905-1.906v-.635a5.081 5.081 0 0 0-5.081-5.081Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon awesome-user-alt"/></svg>
                <svg onClick={() => navigate(`/company/personnel/edit/employee/${row?.id}`)} className="cursor-pointer mx-2" xmlns="http://www.w3.org/2000/svg" width="22.871" height="20.326" viewBox="1568.803 293.674 22.871 20.326"><path d="m1584.789 296.974 3.581 3.581c.151.151.151.397 0 .548l-8.672 8.672-3.684.41a.772.772 0 0 1-.854-.855l.409-3.684 8.672-8.672c.15-.151.397-.151.548 0Zm6.432-.91-1.937-1.937a1.553 1.553 0 0 0-2.192 0l-1.406 1.405a.388.388 0 0 0 0 .548l3.582 3.582c.15.15.397.15.548 0l1.405-1.406a1.553 1.553 0 0 0 0-2.192Zm-7.17 11.353v4.042h-12.707v-12.707h9.125a.488.488 0 0 0 .337-.138l1.588-1.589a.476.476 0 0 0-.337-.814h-11.348a1.906 1.906 0 0 0-1.906 1.906v13.977c0 1.052.853 1.906 1.906 1.906h13.977a1.906 1.906 0 0 0 1.906-1.906v-6.266a.477.477 0 0 0-.814-.337l-1.589 1.588a.488.488 0 0 0-.139.338Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon awesome-edit"/></svg>
                <svg className="cursor-pointer" onClick={() => navigate(`/company/personnel/workdetail/${row?.id}/${row?.first_name} ${row?.last_name}`)} xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326"><path d="M8.813.025C3.953.025 0 3.978 0 8.838c0 4.86 3.953 8.813 8.813 8.813 1.485 0 2.946-.353 4.18-1.033.098.12.208.229.327.328l2.518 2.518a2.568 2.568 0 1 0 3.626-3.626l-2.518-2.518a2.518 2.518 0 0 0-.403-.327c.68-1.234 1.108-2.67 1.108-4.18C17.65 3.953 13.698 0 8.838 0Zm0 2.518c3.5 0 6.295 2.795 6.295 6.295 0 1.662-.605 3.198-1.662 4.33l-.076.076a2.518 2.518 0 0 0-.327.328c-1.108 1.007-2.619 1.586-4.255 1.586a6.265 6.265 0 0 1-6.295-6.295c0-3.5 2.795-6.295 6.295-6.295Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>
            </>,
        }
    ]

    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     axios.get(`${companyURL}/getPersonnelUser`, {
    //         headers: {'Authorization': `Bearer ${token}`}
    //     }).then((res) => {
    //         console.log("res : ", res);
    //         // setManagers(res?.data?.projects);
    //         // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
    //         setUsers(res?.data?.data);
    //         setPending2(false);
    //         // console.log(res?.data?.data?.today[0]?.today_timelogs);
    //     }).catch((err) => {
    //         console.log("err : ", err);
    //     })
    // }, [])

    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     axios.get(`${companyURL}/getPersonnelManager`, {
    //         headers: {'Authorization': `Bearer ${token}`}
    //     }).then((res) => {
    //         console.log("res : ", res);
    //         setManagers(res?.data?.data);
    //         setPending(false);
    //         // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
    //         // setUsers(res?.data?.data);
    //         // console.log(res?.data?.data?.today[0]?.today_timelogs);
    //     }).catch((err) => {
    //         console.log("err : ", err);
    //     })
    // }, [])

    useEffect(() => {
        const token = localStorage.getItem("m_token");
        axios.get(`${apiEndPoint}/api/manager/getPersonnelUser`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("personal users res : ", res);
            setEmployees(res?.data?.data);
            setPending(false);
            // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
            // setUsers(res?.data?.data);
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
        }).catch((err) => {
            console.log("err : ", err);
        })
    }, [])
    
    // useEffect(() => {
    //     const token = localStorage.getItem("m_token");
    //     axios.post(`${apiEndPoint}/api/manager/viewPersonnelUser`, {user_id: 12}, {
    //         headers: {'Authorization': `Bearer ${token}`}
    //     }).then((res) => {
    //         console.log("users detail res : ", res);
    //         setEmployees(res?.data?.data);
    //         setPending(false);
    //         // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
    //         // setUsers(res?.data?.data);
    //         // console.log(res?.data?.data?.today[0]?.today_timelogs);
    //     }).catch((err) => {
    //         console.log("err : ", err);
    //     })
    // }, [])
    
    const columns0 = [
        {
            name: "User Name",
            // selector: row => convertMinutesToHoursAndMinutes(row?.time),
            selector: row => `${row?.first_name} ${row?.last_name}`,
        },
        {
            name: "Email",
            // selector: row => convertMinutesToHoursAndMinutes(row?.time),
            selector: row => row?.email,
        },
        {
            name: "Manager",
            // selector: row => convertMinutesToHoursAndMinutes(row?.time),
            selector: row => row?.manager_id,
        },
        {
            name: "Status",
            selector: row => row?.status === 1? <span className="text6">Active</span> : <span className="text7">Inactive</span>,
        },
        {
            name: "View",
            selector: row => <button className="approve-btn" onClick={() => {navigate(`/manager/detail/${row?.id}`)}}>Approve</button>
        }
        // {
        //     name: "End Work",
        //     selector: row => row?.endWork,
        // },
        // {
        //     name: "Day",
        //     selector: row => row?.day,
        // },
        // {
        //     name: "Tools",
        //     selector: row => <>
        //         <svg className="cursor-pointer" onClick={() => navigate(`/company/personnel/manager/${row?.id}`)} xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326" viewBox="1538.477 293.674 20.326 20.326"><path d="M1548.64 305.107a5.718 5.718 0 0 0 5.717-5.716 5.718 5.718 0 0 0-5.717-5.717 5.718 5.718 0 0 0-5.717 5.717 5.718 5.718 0 0 0 5.717 5.716Zm5.082 1.27h-2.188a6.918 6.918 0 0 1-5.788 0h-2.187a5.081 5.081 0 0 0-5.082 5.082v.635c0 1.052.854 1.906 1.906 1.906h16.515a1.906 1.906 0 0 0 1.905-1.906v-.635a5.081 5.081 0 0 0-5.081-5.081Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon awesome-user-alt"/></svg>
        //         <svg onClick={() => navigate(`/company/personnel/edit/manager/${row?.id}`)} className="mx-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="22.871" height="20.326" viewBox="1568.803 293.674 22.871 20.326"><path d="m1584.789 296.974 3.581 3.581c.151.151.151.397 0 .548l-8.672 8.672-3.684.41a.772.772 0 0 1-.854-.855l.409-3.684 8.672-8.672c.15-.151.397-.151.548 0Zm6.432-.91-1.937-1.937a1.553 1.553 0 0 0-2.192 0l-1.406 1.405a.388.388 0 0 0 0 .548l3.582 3.582c.15.15.397.15.548 0l1.405-1.406a1.553 1.553 0 0 0 0-2.192Zm-7.17 11.353v4.042h-12.707v-12.707h9.125a.488.488 0 0 0 .337-.138l1.588-1.589a.476.476 0 0 0-.337-.814h-11.348a1.906 1.906 0 0 0-1.906 1.906v13.977c0 1.052.853 1.906 1.906 1.906h13.977a1.906 1.906 0 0 0 1.906-1.906v-6.266a.477.477 0 0 0-.814-.337l-1.589 1.588a.488.488 0 0 0-.139.338Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon awesome-edit"/></svg>
        //         <svg className="cursor-pointer" onClick={() => navigate(`/company/personnel/workdetail/${row?.id}/${row?.first_name} ${row?.last_name}`)} xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326"><path d="M8.813.025C3.953.025 0 3.978 0 8.838c0 4.86 3.953 8.813 8.813 8.813 1.485 0 2.946-.353 4.18-1.033.098.12.208.229.327.328l2.518 2.518a2.568 2.568 0 1 0 3.626-3.626l-2.518-2.518a2.518 2.518 0 0 0-.403-.327c.68-1.234 1.108-2.67 1.108-4.18C17.65 3.953 13.698 0 8.838 0Zm0 2.518c3.5 0 6.295 2.795 6.295 6.295 0 1.662-.605 3.198-1.662 4.33l-.076.076a2.518 2.518 0 0 0-.327.328c-1.108 1.007-2.619 1.586-4.255 1.586a6.265 6.265 0 0 1-6.295-6.295c0-3.5 2.795-6.295 6.295-6.295Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>
        //     </>,
        // }
        // {
        //     name: 'Working Time',
        //     selector: row => convertMinutesToHoursAndMinutes(row?.duration),
        //     // selector: row => {
        //     //     let sum = 0;
        //     //     row?.today_timelogs?.map(item => {
        //     //         sum = sum + Number(item?.duration);
        //     //     })
                
        //     //     return convertMinutesToHoursAndMinutes(sum);
        //     // },    
        // },
        // {
        //     name: 'Current Stauts',
        //     selector: row => row.status === "1"? <span className="text6">Active</span> : <span className="text7">Deactive</span>,
        // }
    ]

    
    return (
        <div className="personal-page">
            <div className="d-flex w-100">
                <div className="w-50">
                    <h3 className="text13">
                        Personnel

                        {/* <button onClick={() => navigate('/company/personnel/add')} className="invite-personnel-btn">Invite Personnel</button> */}
                    </h3>
                </div>
                {/* <div className="w-50">
                    <div className="w-50" style={{float: 'right', position:'relative'}}>
                        <input type="text" className="form-control py-12 pe-4 w-100" />
                        <svg className="searh-icon" xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326" viewBox="1638 128 20.326 20.326"><path d="M1646.813 128.025c-4.86 0-8.813 3.953-8.813 8.813 0 4.86 3.953 8.813 8.813 8.813 1.485 0 2.946-.353 4.18-1.033.098.12.208.229.327.328l2.518 2.518a2.568 2.568 0 1 0 3.626-3.626l-2.518-2.518a2.518 2.518 0 0 0-.403-.327c.68-1.234 1.108-2.67 1.108-4.18 0-4.86-3.953-8.813-8.813-8.813Zm0 2.518c3.5 0 6.295 2.795 6.295 6.295 0 1.662-.605 3.198-1.662 4.33l-.076.076a2.518 2.518 0 0 0-.327.328c-1.108 1.007-2.619 1.586-4.255 1.586a6.265 6.265 0 0 1-6.295-6.295c0-3.5 2.795-6.295 6.295-6.295Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>
                    </div>
                </div> */}
            </div>

            <div className="table-card mt-30">
                    <DataTableBase 
                        title={`Employee total : ${employees?.length}`}
                        columns={columns0}
                        data={employees}
                        progressPending={pending}
                    />
            </div>
            {/* <div className="table-card mt-30">
                    <DataTableBase 
                        title={`Employee total : ${users?.length}`}
                        columns={columns1}
                        data={users}
                        progressPending={pending2}
                    />
            </div> */}
        </div>
    )
}


export default PersonalUser;