import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DataTableBase from "../../../components/common/DateTableBase";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { companyURL } from "../../../App";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomLoader from "../../../components/common/CustomLoader";
import { Col, Row } from "react-bootstrap";


const ReportManager = () => {
    const [department, setDepartment] = useState(null);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [departments, setDepartments] = useState([]);
    
    const [pending, setPending] = useState(true);
    const [id, setId] = useState(null);

   const [flag, setFlag] = useState(1);
    const [loading, setLoading] = useState(false);

    const showSuccessAlert = (msg) => {
        console.log("success alert")
        toast.success(msg);
    };

    // Function to show an error alert
    const showErrorAlert = (err) => {
        toast.error(err);
    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => {
        setShow2(true);
    }   
    
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);


    const columns = [
        {
            name: 'Manager Name',
            selector: row => row?.title,
        },
        {
            name: 'View',
            selector: row => <button className="edit-btn" onClick={() => {handleShow2()}}>View</button>
        }
    ];
    
    useEffect(() => {
        console.log("flag updated")
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/departments`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            const temDepartment = res?.data?.data;
            setDepartments([...temDepartment]);
            setPending(false);
        }).catch((err) => {
            showErrorAlert(err?.response?.data?.error[0])
            console.log("err : ", err);
        })
    }, [flag])


    const onSubmit = () => {
        if(department){
            setLoading(true);
            const token = localStorage.getItem("token");
            axios.post(`${companyURL}/createDepartment`, {title: department}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                setFlag(flag + 1);
                setLoading(false);
                showSuccessAlert('Department created successfully');
            }).catch((err) => {
                setLoading(false);
                showErrorAlert(err?.response?.data?.error[0])
                console.log("err : ", err);
            })
            handleClose();
        }
    }

    const onSubmit2 = () => {
        if(department){
            setLoading(true);
            const token = localStorage.getItem("token");
            axios.post(`${companyURL}/updateDepartment`, {title: department, id: id}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                setFlag(flag + 1);
                setLoading(false);
                showSuccessAlert('Department updated successfully');
            }).catch((err) => {
                setLoading(false);
                showErrorAlert(err?.response?.data?.error[0])
                console.log("err : ", err);
            })
            handleClose2();
        }
    }

    const delDepartment = () => {
        if(id){
            setLoading(true);
            const token = localStorage.getItem("token");
            axios.post(`${companyURL}/deleteDepartment`, {id: id}, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                setFlag(flag + 1);
                setLoading(false);
                showSuccessAlert('Department Deleted Successfully');
            }).catch((err) => {
                console.log("err : ", err);
                setLoading(false);
                showErrorAlert(err?.response?.data?.error[0])
            })
            
        }
        handleClose3();
    }


    return (
        <div className="customer-dashboard">
            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>Manager Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label>Heading</label>
                        <input 
                            type="text" value={department}
                            onChange={(e) => setDepartment(e.target.value)} 
                            className="form-control custom-form-select" 
                            placeholder="Enter task title" 
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Close
                </Button>
                <Button className="bg-0facd5" onClick={onSubmit2} variant="primary">
                    Update
                </Button>
                </Modal.Footer>
            </Modal>
            

            <div className="d-flex">
                <div className="w-50">
                    <h3 className="mb-0 text2">Manager Report</h3>                    
                </div>                
            </div>

        
            <div className="table-card mt-30">
                {loading ? (
                    <div style={{paddingTop: '25vh', paddingBottom: '25vh'}}>
                        <CustomLoader />
                    </div>
                ) : (
                    <DataTableBase 
                        title="Manager Report"
                        progressPending={pending}
                        columns={columns}
                        data={departments && departments}
                    />
                )}
            </div>
            <ToastContainer />
        </div>
    )
}

export default ReportManager;