export function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " mins" : "";
  
    return hoursText + minutesText;
}

export function countTotalDuration(data) {
    let sum = 0;
    data?.map(item => {
        sum = sum + Number(item?.duration)
    })

    return sum;
}

export function formateDateDayMonth(stingDate){
    // Create a new Date object
    var date = new Date(stingDate);

    // Define an array of month names
    var months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
    ];

    // Extract the day and month
    var day = date.getDate();
    var monthName = months[date.getMonth()];

    // Format the date
    var formattedDate = day + " " + monthName;

    return formattedDate; 
}

export function totalUserAmount(data){
    let sum = 0;
    data?.map(item => {
        sum = sum + Number(item?.user?.hour_rate * (Number(item?.duration) / 60))
    })

    return sum;
}

export function formateTimeHoursMints(date){
    if(date){
        const time = date?.split(' ')[1];
        const formateTime = time?.split(':');
        return formateTime[0]+":"+formateTime[1];
    }
}

export function checkProjectProgress(data){
    const allocatedHours = Number(data?.hours_allocated) * 60;
    const workingHours = Number(data?.timelog_sum_duration);
    if(workingHours < (allocatedHours * 0.8)){
        return 'green';
    } else if(workingHours <= allocatedHours){
        return '#cccc05';
    } else if(workingHours > allocatedHours){
        return 'red';
    }
}


export function getCurrentYear(){
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    console.log(currentYear);

    return currentYear;
}


export function getCurrentMonth(){
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Returns a number from 0 to 11

    console.log(currentMonth);
    return currentMonth+1;
}