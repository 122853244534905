import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import DataTableBase from "../../../components/common/DateTableBase";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { companyURL } from "../../../App";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { checkProjectProgress, getCurrentMonth, getCurrentYear } from "../../../utility/Index";
import CustomLoader from "../../../components/common/CustomLoader";


function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursText = hours > 0 ? hours + " Hrs " : "";
    const minutesText = remainingMinutes > 0 ? remainingMinutes + " min" : "";
  
    return hoursText + minutesText;
}



const columns = [
    {
        name: 'Project Name',
        selector: row => row.name,
    },
    {
        name: 'Working Time',
        selector: row => row.wtime,
    },
    {
        name: 'Break Time',
        selector: row => row.btime,
    },
    {
        name: <><b className="working-th">Working</b><b className="working-th break-th ms-2">Break</b></>,
        // selector: row => <div className="text-center"><div className="performancediv"><div className="performnce-value" style={{width: `${row?.working}%`}}><span className="text5">{row.working}%</span></div> </div></div>,
        selector: row => <div className="text-end">
            <div className="performancediv performancediv-break d-flex">
                <div className="performnce-value text-center" style={{width: `${row?.working ? row?.working : '90' }%`}}>
                    <span className="text5">{row?.wtime}</span>
                </div>
                {/* <div className="text-center" style={{width: `${100 - row?.working}%`}}> */}
                <div className="text-center" style={{width: `${10}%`}}>
                    <span className="text5">{row?.btime}</span> 

                </div>
            </div>
            </div>,
        width: '300px' 
    },
    {
        name: 'Project time',
        selector: row => row.ptime,
    },
];



const ManagerDashboard = () => {
    const [startDate, setStartDate] = useState();

    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [show, setShow] = useState(false);
    const [flag, setFlag] = useState(1);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(true);
    
    const [month, setMonth] = useState(getCurrentMonth());
    const [year, setYear] = useState(getCurrentYear());

    const navigate = useNavigate();

    const [pending, setPending] = useState(true);
    const [pending2, setPending2] = useState(true);
    const [pagination, setPagination] = useState(1);
    const [pagination2, setPagination2] = useState(1);

    const [dashboardData, setDashboardData] = useState(null);
    const [milstone, setMilestone] = useState([]);

    const [selectMilstone, setSelectMilstone] = useState({});

    const handleOptGroupSelect = (e, id) => {
        console.log("handle change : ", e.target.value);
        setSelectMilstone({project_id: id, sub_milestone_id: e.target.value});
        setShow(true);
    }
    

    const columns2 = [
        {
            name: '',
            width: '70px',
            selector: row => <div className="d-flex">
            {row?.isChecked === 0 ? ( 
                <div className="pointer white-radio-div"></div>
            ) : row?.isChecked === 1 ? (
                <div className="pointer green-radio-div"></div>
            ) : row?.isChecked === 2 ?
                <div className="pointer yellow-radio-div"></div>
            : <div className="pointer red-radio-div"></div>
            }
        </div>
        },
        {
            name: 'Project Name',
            selector: row => row?.project_name,
        },
        {
            name: 'Actual Time',
            // selector: row => {
            //     let sum = 0;
            //     row?.timelog?.map(item => {
            //         sum = sum + Number(item?.duration);
            //     })
            //     if(sum === 0){
            //         return 0;
            //     }
            //     return convertMinutesToHoursAndMinutes(sum);
            // },
            selector: row => row?.duration_month > 0 ? convertMinutesToHoursAndMinutes(row?.duration_month) : 0,
        },
        // {
        //     name: 'Break Time',
        //     selector: row => row?.btime,
        // },
        // {
        //     name: <b className="working-th">Working</b>,
        //     selector: row => <div className="text-center"><div className="performancediv"><div className="performnce-value" style={{width: `${row?.working ? 23 : 90}%`}}><span className="text5">{row?.working ? 90 : 90}%</span></div> </div></div>,        
        //     width: '300px' 
        // },
        {
            name: <b className="working-th">Progress</b>,
            // selector: row => <div className="text-center"><div className="performancediv"><div className="performnce-value" style={{width: `${row?.timelog_sum_duration ? (row?.timelog_sum_duration / (Number(row?.hours_allocated) * 60)) * 100 : 0}%`}}><span style={{letterSpacing: '-1px'}} className="text5">{row?.timelog_sum_duration ? Math.ceil(((row?.timelog_sum_duration / (Number(row?.hours_allocated) * 60)) * 100) * 100) / 100 : 0}%</span></div> </div></div>,        
            selector: row => <div className="text-center"><div className="performancediv"><div className="performnce-value" style={{backgroundColor: checkProjectProgress(row), width: `${row?.percent_complete ? row?.percent_complete : 0}%`}}><span style={{letterSpacing: '-1px'}} className="text5">{row?.percent_complete ? row?.percent_complete : 0}%</span></div> </div></div>,        
            width: '300px' 
        },
        // {
        //     name: 'Tools',
        //     selector: row => <svg xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326"><path d="M8.813.025C3.953.025 0 3.978 0 8.838c0 4.86 3.953 8.813 8.813 8.813 1.485 0 2.946-.353 4.18-1.033.098.12.208.229.327.328l2.518 2.518a2.568 2.568 0 1 0 3.626-3.626l-2.518-2.518a2.518 2.518 0 0 0-.403-.327c.68-1.234 1.108-2.67 1.108-4.18C17.65 3.953 13.698 0 8.838 0Zm0 2.518c3.5 0 6.295 2.795 6.295 6.295 0 1.662-.605 3.198-1.662 4.33l-.076.076a2.518 2.518 0 0 0-.327.328c-1.108 1.007-2.619 1.586-4.255 1.586a6.265 6.265 0 0 1-6.295-6.295c0-3.5 2.795-6.295 6.295-6.295Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>,
        // },
        {
            name: 'Allocated Time',
            selector: row => row?.hours_allocated+' Hrs',
            width: '139px',
        },
        {
            name: 'Total Cost',
            width: '110px',
            selector: row => '$600',
        },
        {
            name: 'Sessions',
            width: '140px',
            selector: row => <button className="edit-btn px-3" onClick={() => navigate(`/company/sessions/${row?.id}`)}>Sessions</button>
        },
        {
            name: 'Detail',
            width: '110px',
            selector: row => <button className="edit-btn px-3">Detail</button>
        },
        {
            name: 'Tasks',
            width: '110px',
            selector: row => <button className="edit-btn px-3">Tasks</button>
        },
        {
            name: "Mile Stone",
            width: '200px',
            selector: row => <select class="form-control" value={row?.sub_milestone_id} onChange={(e) => handleOptGroupSelect(e, row?.id)}>
                {/* {console.log("miltone : ", milstone)} */}
                {milstone?.map((item, index) => (
                    <optgroup label={item?.name} key={index}>
                        {item?.sub_milestones?.map((sub_item, ind) => (
                            <option value={sub_item?.id} key={index+ind}>{sub_item?.name}</option>
                        ))}
                    </optgroup>
                ))}
        </select>            
        }
    ];
    


    const columns0 = [
        {
            name: "Personnel user",
            selector: row => `${row?.first_name} ${row?.last_name}`
        },
        {
            name: "Working Time",
            // selector: row => row?.monthlytimelog_sum_duration === null ? 0 : convertMinutesToHoursAndMinutes(row?.monthlytimelog_sum_duration),
            selector: row => row?.duration,
            // selector: row => row?.monthlytimelog_sum_duration,
        },
        {
            name: "P/H Rate",
            selector: row => `$${row?.hour_rate}`,
        },
        {
            name: "Wages",
            // selector: row => Math.ceil(((row?.monthlytimelog_sum_duration / 60) * row?.hour_rate) * 100) / 100+"$",
            selector: row => "$"+row?.wages,
        },
        {
            name: "Working Status",
            selector: row => row?.isOnline === 1? <span className="text6">Online</span> : <span className="text7">Offline</span>,
        },
        {
            name: 'Tools',
            width: '100px',
            selector: row => <svg className="cursor-pointer" onClick={() => navigate(`/company/personnel/workdetail/${row?.id}/${row?.first_name} ${row?.last_name}`)} xmlns="http://www.w3.org/2000/svg" width="20.326" height="20.326"><path d="M8.813.025C3.953.025 0 3.978 0 8.838c0 4.86 3.953 8.813 8.813 8.813 1.485 0 2.946-.353 4.18-1.033.098.12.208.229.327.328l2.518 2.518a2.568 2.568 0 1 0 3.626-3.626l-2.518-2.518a2.518 2.518 0 0 0-.403-.327c.68-1.234 1.108-2.67 1.108-4.18C17.65 3.953 13.698 0 8.838 0Zm0 2.518c3.5 0 6.295 2.795 6.295 6.295 0 1.662-.605 3.198-1.662 4.33l-.076.076a2.518 2.518 0 0 0-.327.328c-1.108 1.007-2.619 1.586-4.255 1.586a6.265 6.265 0 0 1-6.295-6.295c0-3.5 2.795-6.295 6.295-6.295Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>,
        },
        // {
        //     name: 'Working Time',
        //     selector: row => convertMinutesToHoursAndMinutes(row?.duration),
        //     // selector: row => {
        //     //     let sum = 0;
        //     //     row?.today_timelogs?.map(item => {
        //     //         sum = sum + Number(item?.duration);
        //     //     })
                
        //     //     return convertMinutesToHoursAndMinutes(sum);
        //     // },    
        // },
        // {
        //     name: 'Current Stauts',
        //     selector: row => row.status === "1"? <span className="text6">Active</span> : <span className="text7">Deactive</span>,
        // }
    ]




    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("updatedd");
        axios.post(`${companyURL}/dashboard?page=${pagination}`, {month: month, year: year}, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res project : ", res);
            setProjects(res?.data);
            // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
            // setUsers(res?.data?.users);
            let mintsCount = 0;
            let wagesCount = 0;
            const tempData = res?.data?.users?.data?.map((item) => {
                mintsCount = mintsCount + item?.monthlytimelog_sum_duration;
                wagesCount = wagesCount + ((item?.monthlytimelog_sum_duration / 60) * item?.hour_rate);
            })
            const totalHours = convertMinutesToHoursAndMinutes(mintsCount);
            setDashboardData({actualPercent: res?.data?.actualPercent, totalOnlineUser: res?.data?.totalOnlineUser, hours: totalHours, wages: Math.ceil(wagesCount * 100) / 100})
            setPending(false);
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
        }).catch((err) => {
            console.log("err : ", err);
        })
    }, [flag, month, year, pagination])
    
    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("updatedd");
        axios.post(`${companyURL}/userDashboard?page=${pagination2}`, {month: month, year: year}, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res users : ", res);
            // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
            setUsers(res?.data);
            // let mintsCount = 0;
            // let wagesCount = 0;
            // const tempData = res?.data?.users?.data?.map((item) => {
            //     mintsCount = mintsCount + item?.monthlytimelog_sum_duration;
            //     wagesCount = wagesCount + ((item?.monthlytimelog_sum_duration / 60) * item?.hour_rate);
            // })
            // const totalHours = convertMinutesToHoursAndMinutes(mintsCount);
            // setDashboardData({actualPercent: res?.data?.actualPercent, totalOnlineUser: res?.data?.totalOnlineUser, hours: totalHours, wages: Math.ceil(wagesCount * 100) / 100})
            setPending2(false);
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
        }).catch((err) => {
            console.log("err user: ", err);
        })
    }, [flag, month, year, pagination2])

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/getMileStoneList`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("Milestone list res : ", res);
            setMilestone(res?.data?.data)
            setLoading3(false);
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
        }).catch((err) => {
            setLoading3(false);
            console.log("Mile stone list err : ", err);
        })
    }, [flag]) 


    const updateMilestoneFun = () => {
        setLoading2(true);
        const token = localStorage.getItem("token");
        axios.post(`${companyURL}/updateProjectPercent`, selectMilstone, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("Milestone list res : ", res);
            setFlag(flag + 1);
            setLoading2(false);
            setShow(false);
            toast.success("Mile Stone Updated Successfully");
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
        }).catch((err) => {
            setLoading2(false);
            console.log("Mile stone list err : ", err);
        })
    }


    // console.log("projects", users);

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ];
      
    const currentDate = new Date();
    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year2 = currentDate.getFullYear();
    
    const formattedDate = `${day} ${months[monthIndex]} ${year2}`;



    const prevFunc = () => {
        if(pagination > 1){
            setPending(true);
            setPagination(pagination - 1);
        }
    }
    const nextFunc = () => {
        if(pagination < projects?.projects?.data?.length){
            setPending(true);
            setPagination(pagination + 1);
        }
    }

    const prevFunc2 = () => {
        if(pagination2 > 1){
            setPagination2(pagination2 - 1);
            setPending2(true);
        }
    }
    const nextFunc2 = () => {
        if(pagination2 < users?.users?.data?.length){
            setPending2(true);
            setPagination2(pagination2 + 1);
        }
    }


    // useEffect(() => {
    //     if(pagination === 1){

    //     }
    // }, [pagination])

    // console.log("projects : ", projects?.data)
    // console.log("users : ", users?.data)

    return (
        <div className="customer-dashboard">
            <ToastContainer />
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Are you sure you want to update this?</h2>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    No
                </Button>
                {loading2 ? (
                    <Spinner className="ms-3" />
                ) : (
                <Button className="bg-0facd5" onClick={updateMilestoneFun} variant="primary">
                    Yes
                </Button>
                )}
                </Modal.Footer>
            </Modal>
            <div className="d-flex">
                <div className="w-40">
                    <h3 className="mb-0 text2">Dashboard - This Month</h3>
                    <p className="text3">{formattedDate}</p>
                </div>
                <div className="w-60 d-flex justify-content-end">
                <div className="mx-2">
                        <select className="form-control" defaultValue={year} onChange={(e) => setYear(e.target.value)}>
                            <option>Select Year</option>
                            <option value={2024}>2024</option>
                            <option value={2023}>2023</option>
                        </select>                        
                    </div>
                    <div>
                        <select className="form-control" defaultValue={month} onChange={(e) => setMonth(e.target.value)}>
                            <option>Select Month</option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                    </div>
                    {/* <ul className="dashboard-bar-ul">
                        <li>
                            <p className="text4">Select Range</p>
                        </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li> - </li>
                        <li>
                            <div className="p-relative">
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    placeholderText="DD-MM-YYYY"
                                    className="datepickerr"
                                />
                                <svg className="date-picker-icon" xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
                            </div>
                        </li>
                        <li>
                            <button className="filter-btn">Filter</button>
                        </li>
                    </ul> */}
                </div>
            </div>
            {!pending && (
                <div className="d-flex mt-2">
                    <div className="w-20 me-2">
                        <div className="d-flexx dashboard-card">
                            <div className="w-751">
                                <h4 className="text-white">Total Project</h4>
                                {/* <h1 className="text-white">{projects?.length === 0 ? 0 : projects?.length }</h1> */}
                                <h1 className="text-white text-center">
                                    {projects?.totalActiveProjects} / {projects?.totalProjects}
                                    <svg style={{float: 'right'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20"><g data-name="edit"><g data-name="Group 257"><path d="M18 14v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 18"/><path d="M10.5 13.8 20 4.2 15.8 0 6.3 9.5 6 14l4.5-.2Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#fff" fill="transparent" data-name="Path 19"/></g></g></svg>
                                </h1>
                                <p className="text-white text-center mb-0">
                                    Active
                                    <span className="ps-4">Total</span>
                                </p>
                            </div>
                            <div className="w-251"></div>
                        </div>
                    </div>

                    <div className="mx-2 w-20">
                        <div className="d-flexx dashboard-card">
                            <div className="w-751">
                                <h4 className="text-white">Online Employee</h4>
                                {/* <h1 className="text-white"> {dashboardData?.totalOnlineUser}/{users?.length === 0 ? 0 : users?.length }</h1> */}
                                <h1 className="text-white text-center">
                                    {users?.totalOnlineUser} / {users?.totalUsers}
                                    <svg style={{float: "right"}} xmlns="http://www.w3.org/2000/svg" width="19.174" height="20" viewBox="940 230.5 19.174 20"><path d="M949.587 240.03c2.427 0 4.394-2.134 4.394-4.765 0-2.632-1.967-4.765-4.394-4.765s-4.394 2.133-4.394 4.765c0 2.631 1.967 4.765 4.394 4.765Zm0 2.093c-5.842 0-9.587 3.496-9.587 5.199v3.178h19.174v-3.178c0-2.059-3.546-5.199-9.587-5.199Z" fill="#fff" fill-rule="evenodd" data-name="person-svgrepo-com (1)"/></svg>
                                </h1>
                                <p className="text-white text-center mb-0">
                                    Online
                                    <span className="ps-4">All</span>
                                </p>
                            </div>
                            <div className="w-251"></div>
                        </div>
                    </div>
                    
                    <div className="w-20 mx-2">
                        <div className="d-flexx dashboard-card">
                            <div className="w-751">
                                <h4 className="text-white">Total Hours - This Month</h4>
                                {/* <h3 className="pt-2 text-white">{dashboardData?.hours ? dashboardData?.hours : 0}</h3> */}
                                <h1 className=" text-white">{users?.totalDurationOfMonth}</h1>
                            </div>
                            <div className="w-251"></div>
                        </div>                    
                    </div>

                    <div className="w-20 ms-2">
                        <div className="d-flexx dashboard-card">
                            <div className="w-751">
                                <h4 className="text-white">Total Wages - This Month</h4>
                                <h1 className="text-white text-center">{users?.totalWages}$</h1>
                            </div>
                            <div className="w-251"></div>
                        </div>
                    </div>
                    <div className="w-20 ms-2">
                        <div className="d-flexx dashboard-card">
                            <div className="w-751">
                                <h4 className="text-white">Complete Status</h4>
                                <h1 className="text-white text-center">{Number(projects?.actualPercent).toFixed(2)}%</h1>
                            </div>
                            <div className="w-251"></div>
                        </div>
                    </div>
                </div>
            )}


            <div className="table-card mt-30">
                {/* {projects && ( */}
                    {/* <DataTableBase 
                        title="Project"
                        columns={columns2}
                        data={projects && projects?.projects?.data}
                        progressPending={pending || loading3}
                    /> */}
                    <h3 className="sc-blmCWO">Projects</h3>
                    {pending || loading3 ? (
                        <div>
                            <CustomLoader />
                        </div>
                    ) : (
                        <div className="table-responsive">                            
                            <table className="session-table table" style={{width: '130%'}}>
                                <thead>
                                    <tr>
                                        <th style={{width: '80px'}}></th>
                                        <th style={{width: '220px'}}>Project Name</th>
                                        <th style={{width: '200px'}}>Actual Time</th>
                                        <th style={{width: '300px'}}><b className="working-th">Progress</b></th>
                                        <th style={{width: '170px'}}>Allocated Time</th>
                                        <th style={{width: '110px'}}>Sessions</th>
                                        <th style={{width: '110px'}}>Detail</th>
                                        <th style={{width: '110px'}}>Tasks</th>
                                        <th style={{width: '140px'}}>Month Cost</th>
                                        <th style={{width: '140px'}}>Total Cost</th>
                                        <th style={{width: '200px'}}>Milestone</th>
                                        {/* <th>Tools</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {projects?.projects?.data?.map((item, index) => (
                                        <tr key={index} className={`${index+1 === projects?.data?.length && "remove-border-bottom"} ${item?.isChecked === 0 ? "white-row" : item?.isChecked === 1 ? "green-row" : item?.isChecked === 2 ? "yellow-row" : item?.isChecked === 3 ? "red-row" : ""}`}>
                                            <td style={{width: '80px'}}>
                                                {/* <div className="d-flex">
                                                    <div onClick={() => {handleChange(index, 0); handleButtonClick(item, 0);}} className="pointer white-radio-div"></div>
                                                    <div onClick={() => {handleChange(index, 1); handleButtonClick(item, 1);}} className="pointer green-radio-div"></div>
                                                    <div onClick={() => {handleChange(index, 2); handleButtonClick(item, 2);}} className="pointer yellow-radio-div"></div>
                                                    <div onClick={() => {handleChange(index, 3); handleButtonClick(item, 3);}} className="pointer red-radio-div"></div>
                                                </div> */}
                                                <div className="d-flex">
                                                    {item?.isChecked === 0 ? ( 
                                                        <div className="pointer white-radio-div"></div>
                                                    ) : item?.isChecked === 1 ? (
                                                        <div className="pointer green-radio-div"></div>
                                                    ) : item?.isChecked === 2 ?
                                                        <div className="pointer yellow-radio-div"></div>
                                                    : <div className="pointer red-radio-div"></div>
                                                    }
                                                </div>
                                                {/* <input type="radio" defaultChecked={item?.isChecked === 0 && true} onChange={() => handleButtonClick(item, 0)} className="radio-white" name={`type${index}`} />
                                                <input type="radio" defaultChecked={item?.isChecked === 1 && true} onChange={() => handleButtonClick(item, 1)} className="radio-success mx-2" name={`type${index}`} />
                                                <input type="radio" defaultChecked={item?.isChecked === 2 && true} onChange={() => handleButtonClick(item, 2)} className="radio-yellow me-2" name={`type${index}`} />
                                                <input type="radio" defaultChecked={item?.isChecked === 3 && true} onChange={() => handleButtonClick(item, 3)} className="radio-danger" name={`type${index}`} />                                                 */}
                                            </td>
                                            <td style={{width: '220px'}}>{item?.project_name}</td>
                                            <td style={{width: '200px'}}>{item?.duration_month > 0 ? convertMinutesToHoursAndMinutes(item?.duration_month) : 0}</td>
                                            {/* <td style={{width: '150px'}}>{convertMinutesToHoursAndMinutes(Number(item?.duration_month))}</td> */}
                                            <td style={{width: '300px'}}><div className="text-center"><div className="performancediv"><div className="performnce-value" style={{backgroundColor: checkProjectProgress(item), width: `${item?.percent_complete ? item?.percent_complete : 0}%`}}><span style={{letterSpacing: '-1px'}} className="text5">{item?.percent_complete ? item?.percent_complete : 0}%</span></div> </div></div></td>
                                            <td style={{width: '110px'}}>{item?.hours_allocated+' Hrs'}</td>
                                            <td style={{width: '140px'}}><button className="edit-btn px-3" onClick={() => navigate(`/company/sessions/${item?.id}`)}>Sessions</button></td>
                                            <td style={{width: '110px'}}><button className="edit-btn px-3">Detail</button></td>
                                            <td style={{width: '110px'}}><button className="edit-btn px-3">Tasks</button></td>
                                            <td style={{width: '110px'}}>${item?.monthly_cost}</td>
                                            <td style={{width: '110px'}}>${item?.total_cost}</td>
                                            <td style={{width: '200px'}}>
                                                <select class="form-control" value={item?.sub_milestone_id} onChange={(e) => handleOptGroupSelect(e, item?.id)}>
                                                    {/* {console.log("miltone : ", milstone)} */}
                                                    {milstone?.map((items, index) => (
                                                        <optgroup label={items?.name} key={index}>
                                                            {items?.sub_milestones?.map((sub_item, ind) => (
                                                                <option value={sub_item?.id} key={index+ind}>{sub_item?.name}</option>
                                                            ))}
                                                        </optgroup>
                                                    ))}
                                                </select>
                                            </td>
                                            {/* <td>
                                                <svg  onClick={() => navigate(`/company/sessions/${id}/screenshots/${item?.id}`)} className="me-3 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="21.111" height="19" viewBox="1566.706 322 21.111 19"><g data-name="Icon feather-monitor"><path d="M1568.817 322h16.89c1.165 0 2.11.945 2.11 2.111v10.556a2.111 2.111 0 0 1-2.11 2.11h-16.89a2.111 2.111 0 0 1-2.11-2.11V324.11c0-1.166.944-2.111 2.11-2.111Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 10"/><path d="M1573.04 341h8.444" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 11"/><path d="M1577.262 336.778V341" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="#2362aa" fill="transparent" data-name="Path 12"/></g></svg> */}
                                                {/* <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="17.183" height="21.148" viewBox="1607.818 319.852 17.183 21.148"><path d="M1609.14 326.46v13.218c0 .727.594 1.322 1.321 1.322h11.896c.727 0 1.322-.595 1.322-1.322v-13.217h-14.54Zm3.965 11.896h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.644 0h-1.322v-9.252h1.322v9.252Zm2.643 0h-1.322v-9.252h1.322v9.252Zm2.974-15.86h-4.296v-1.653a.994.994 0 0 0-.991-.991h-4.626a.994.994 0 0 0-.991.991v1.652h-4.296a.994.994 0 0 0-.992.992v1.652H1625v-1.652a.994.994 0 0 0-.991-.992Zm-5.617 0h-3.966v-1.306h3.966v1.305Z" fill="#e1e1e1" fill-rule="evenodd" data-name="Icon metro-bin"/></svg> */}
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="18.642" height="21.438" viewBox="1593 437.562 18.642 21.438"><g data-name="bin-delete-recycle-svgrepo-com"><path d="M1610.71 438.494h-6.525a.932.932 0 0 0-.932-.932h-1.864a.932.932 0 0 0-.932.932h-6.525a.932.932 0 1 0 0 1.864h16.778a.932.932 0 0 0 0-1.864Z" fill="#5d5b5b" fill-rule="evenodd" data-name="Path 14"/><path d="M1609.312 441.29a.932.932 0 0 0-.933.932v14.914h-12.117v-14.914a.932.932 0 0 0-1.864 0v15.846c0 .515.417.932.932.932h13.982a.932.932 0 0 0 .932-.932v-15.846a.932.932 0 0 0-.932-.932Z" fill="#5d5b5b" fill-rule="evenodd" data-name="Path 15"/><path d="M1600.923 454.34v-8.39a.932.932 0 0 0-1.864 0v8.39a.932.932 0 0 0 1.864 0Z" fill="#5d5b5b" fill-rule="evenodd" data-name="Path 16"/><path d="M1605.583 454.34v-8.39a.932.932 0 0 0-1.864 0v8.39a.932.932 0 0 0 1.864 0Z" fill="#5d5b5b" fill-rule="evenodd" data-name="Path 17"/></g></svg>
                                            </td> */}
                                        </tr>                            
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                {/* )} */}
                <div className="text-end mt-3">
                    <button className="prev-next-btn" onClick={prevFunc}>PREV</button>
                    <button className="current-page-btn mx-2">{pagination}/{projects?.projects?.last_page}</button>
                    <button className="prev-next-btn" onClick={nextFunc}>NEXT</button>
                </div>
            </div>
            <div className="table-card mt-30">
                {/* {users && ( */}
                    <DataTableBase 
                        title="Personnel"
                        columns={columns0}
                        data={users?.users?.data}
                        progressPending={pending2}
                    />
                {/* )} */}
                <div className="text-end mt-3">
                    <button className="prev-next-btn" onClick={prevFunc2} >PREV</button>
                    <button className="current-page-btn mx-2">{pagination2}/{users?.users?.last_page}</button>
                    <button className="prev-next-btn" onClick={nextFunc2}>NEXT</button>
                </div>
            </div>

        </div>
    )
}

export default ManagerDashboard;