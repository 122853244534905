import React, { useEffect } from "react";
import Navbar from "./common/Navbar";
import Topbar from "./common/Topbar";
import CustomerDashboard from "../users/customer/pages/Dashboard";
import { Outlet, useNavigate } from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem('role');
        const token = localStorage.getItem('token');
        if(!token){
            navigate('/login');        
        }
        console.log("role", role)
        if(role !== "User"){
            navigate('/company');        
        }

    }, [])

    return (
        <div className="w-100 d-flex">
            <div>
                <Navbar />
            </div>
            <div className="content-div">
                <Topbar />
                {/* <CustomerDashboard /> */}
                <Outlet />
            </div>
        </div>
    )
}

export default Dashboard;