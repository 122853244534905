import React from "react";
import { useNavigate } from "react-router-dom";

const ProjectReportDetail = () => {
    const navigate = useNavigate();

    return (
        <div className="report-detail-page">
            <h1 className="heading">
                Find My Car
                <span>34%</span>
            </h1>
            <div className="text-end">
                <button onClick={() => navigate(`/company/project-report`)} className="back-btn-red">Back</button>
            </div>
            <div className="top-div mt-3">
                <h2>Project details</h2>
                <div className="d-flex">
                    <div className="w-50">
                        <div className="d-flex mt-3 justify-content-betweenx">
                            <div className="label w-25">Project name</div>
                            <div className="value">BIGLOT</div>
                        </div>
                        <div className="d-flex mt-3 justify-content-betweenx">
                            <div className="label w-25">Descriptions</div>
                            <div className="value">Description Loremmmmmmmm</div>
                        </div>
                        <div className="d-flex mt-3 justify-content-betweenx">
                            <div className="label w-25">Task</div>
                            <div className="value">Ux/Ui </div>
                        </div>
                    </div>
                    <div className="w-50">
                        <div className="d-flex mt-3 justify-content-betweenx">
                            <div className="label w-25">Manager</div>
                            <div className="value">Joe Sripatana</div>
                        </div>
                        <div className="d-flex mt-3 justify-content-betweenx">
                            <div className="label w-25">Start</div>
                            <div className="value">23-09-2023</div>
                        </div>
                        <div className="d-flex mt-3 justify-content-betweenx">
                            <div className="label w-25">Finish</div>
                            <div className="value">03-09-2024</div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div className="bottom-div mt-4">
                <table>
                    <thead>
                        <tr>
                            <td>Task</td>
                            <td>Staff</td>
                            <td>Due Date</td>
                            <td>Work Hours</td>
                            <td>Process</td>
                            <td>Cost</td>
                            <td>Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="548 533.95 22 22"><g data-name="Group 288"><g data-name="Ellipse 2"><path d="M548 544.9a11 11 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd"/><path d="M548.5 544.902a10.5 10.5 0 1 1 0 .096z" stroke-linejoin="round" stroke-linecap="round" stroke="#1a0" fill="transparent" stroke-width=".95455"/></g><path d="M550 544.9a9 9 0 1 1 0 .1z" fill="#1a0" fill-rule="evenodd" data-name="Ellipse 3"/></g></svg>
                                    Ux/Ui
                                    <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="626 533 23 23"><g data-name="Group 289"><g data-name="Rectangle 273"><path d="M628 533h19a2 2 0 0 1 2 2v19a2 2 0 0 1-2 2h-19a2 2 0 0 1-2-2v-19a2 2 0 0 1 2-2z" fill="#fff" fill-rule="evenodd"/><path d="M628.413 533.5h18.174a1.913 1.913 0 0 1 1.913 1.913v18.174a1.913 1.913 0 0 1-1.913 1.913h-18.174a1.913 1.913 0 0 1-1.913-1.913v-18.174a1.913 1.913 0 0 1 1.913-1.913z" stroke-linejoin="round" stroke-linecap="round" stroke="#707070" fill="transparent" stroke-width=".95652"/></g><g data-name="Polygon 2"><path d="m637.5 552-7.5-13h15z" fill="#707070" fill-rule="evenodd"/><path d="M7.5 0 15 13H0z" stroke-linejoin="round" stroke-linecap="round" stroke="#707070" fill="transparent" transform="matrix(-.93333 0 0 -.92308 644.5 551.5)"/></g></g></svg>
                                </div>
                                <div className="mt-2 ps-4">
                                    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="548 533.95 22 22"><g data-name="Group 288"><g data-name="Ellipse 2"><path d="M548 544.9a11 11 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd"/><path d="M548.5 544.902a10.5 10.5 0 1 1 0 .096z" stroke-linejoin="round" stroke-linecap="round" stroke="#1a0" fill="transparent" stroke-width=".95455"/></g><path d="M550 544.9a9 9 0 1 1 0 .1z" fill="#1a0" fill-rule="evenodd" data-name="Ellipse 3"/></g></svg>
                                    Ux/Ui Design Phase 01
                                </div>
                                <div className="mt-2 ps-4">
                                    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="548 533.95 22 22"><g data-name="Group 288"><g data-name="Ellipse 2"><path d="M548 544.9a11 11 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd"/><path d="M548.5 544.902a10.5 10.5 0 1 1 0 .096z" stroke-linejoin="round" stroke-linecap="round" stroke="#1a0" fill="transparent" stroke-width=".95455"/></g><path d="M550 544.9a9 9 0 1 1 0 .1z" fill="#1a0" fill-rule="evenodd" data-name="Ellipse 3"/></g></svg>
                                    Ux/Ui Design Phase 02
                                </div>
                                
                            </td>
                            <td>Zen Office</td>
                            <td>
                                <b>03-09-2024</b>
                            </td>
                            <td>14</td>
                            <td>
                                <span className="grn-status">100%</span>
                            </td>
                            <td>100%</td>
                            <td>
                                <span className="grn-status">Complete</span>                                
                            </td>
                        </tr>
                        <tr style={{borderBottom: 'none'}}>
                            <td>
                                <div>
                                    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="548 533.95 22 22"><g data-name="Group 288"><g data-name="Ellipse 2"><path d="M548 544.9a11 11 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd"/><path d="M548.5 544.902a10.5 10.5 0 1 1 0 .096z" stroke-linejoin="round" stroke-linecap="round" stroke=" #0facd5" fill="transparent" stroke-width=".95455"/></g><path d="M550 544.9a9 9 0 1 1 0 .1z" fill=" #0facd5" fill-rule="evenodd" data-name="Ellipse 3"/></g></svg>
                                    Ux/Ui
                                    <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="626 533 23 23"><g data-name="Group 289"><g data-name="Rectangle 273"><path d="M628 533h19a2 2 0 0 1 2 2v19a2 2 0 0 1-2 2h-19a2 2 0 0 1-2-2v-19a2 2 0 0 1 2-2z" fill="#fff" fill-rule="evenodd"/><path d="M628.413 533.5h18.174a1.913 1.913 0 0 1 1.913 1.913v18.174a1.913 1.913 0 0 1-1.913 1.913h-18.174a1.913 1.913 0 0 1-1.913-1.913v-18.174a1.913 1.913 0 0 1 1.913-1.913z" stroke-linejoin="round" stroke-linecap="round" stroke="#707070" fill="transparent" stroke-width=".95652"/></g><g data-name="Polygon 2"><path d="m637.5 552-7.5-13h15z" fill="#707070" fill-rule="evenodd"/><path d="M7.5 0 15 13H0z" stroke-linejoin="round" stroke-linecap="round" stroke="#707070" fill="transparent" transform="matrix(-.93333 0 0 -.92308 644.5 551.5)"/></g></g></svg>
                                </div>
                                <div className="mt-2 ps-4">
                                    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="548 533.95 22 22"><g data-name="Group 288"><g data-name="Ellipse 2"><path d="M548 544.9a11 11 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd"/><path d="M548.5 544.902a10.5 10.5 0 1 1 0 .096z" stroke-linejoin="round" stroke-linecap="round" stroke=" #0facd5" fill="transparent" stroke-width=".95455"/></g><path d="M550 544.9a9 9 0 1 1 0 .1z" fill=" #0facd5" fill-rule="evenodd" data-name="Ellipse 3"/></g></svg>
                                    Ux/Ui Design Phase 01
                                </div>
                                <div className="mt-2 ps-4">
                                    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="548 533.95 22 22"><g data-name="Group 288"><g data-name="Ellipse 2"><path d="M548 544.9a11 11 0 1 1 0 .1z" fill="#fff" fill-rule="evenodd"/><path d="M548.5 544.902a10.5 10.5 0 1 1 0 .096z" stroke-linejoin="round" stroke-linecap="round" stroke=" #0facd5" fill="transparent" stroke-width=".95455"/></g><path d="M550 544.9a9 9 0 1 1 0 .1z" fill=" #0facd5" fill-rule="evenodd" data-name="Ellipse 3"/></g></svg>
                                    Ux/Ui Design Phase 02
                                </div>
                                
                            </td>
                            <td>Zen Office</td>
                            <td>
                                <b>03-09-2024</b>
                            </td>
                            <td>14</td>
                            <td>
                                <span className="grn-status blue-status">100%</span>
                            </td>
                            <td>100%</td>
                            <td>
                                <span className="grn-status blue-status">Complete</span>                                
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ProjectReportDetail;