import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { timezones } from "./timezones";
import { companyURL } from "../../../App";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomLoader from "../../../components/common/CustomLoader";

const AddUser = () => {
    const navigate = useNavigate();
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    // const [error, setError] = useState(null);
    const {register, handleSubmit, watch} = useForm();
    const [departments, setDepartments] = useState([]);
    const showSuccessAlert = () => {
        console.log("success alert")
        toast.success('User created successfully');
    };

    // Function to show an error alert
    // const showErrorAlert = () => {
    //     toast.error('Error message');
    // };
    
    // const [isChecked, setIsChecked] = useState(false); // Initialize the state

    // const handleCheckboxChange = (e) => {
    //   const newValue = e.target.checked;
    //   setIsChecked(newValue); // Update the state with the new value
    // };

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/departments`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            const temDepartment = res?.data?.data;
            setDepartments([...temDepartment]);
            
        }).catch((err) => {
            console.log("err : ", err);
        })
    }, [])

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(`${companyURL}/getPersonnelManager`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then((res) => {
            console.log("res : ", res);
            setManagers(res?.data?.data);
            // const revArray = res?.data?.data?.today[0]?.today_timelogs.reverse();
            // setUsers(res?.data?.data);
            // console.log(res?.data?.data?.today[0]?.today_timelogs);
        }).catch((err) => {
            console.log("err : ", err);
        })
    }, [])

    const roleWatch = watch("accountType");

    const onSubmit = (data) => {
        if(image){

            setLoading(true);
            console.log("data : ", data);
            const token = localStorage.getItem("token");
            const formData = new FormData();
            formData.append("accountType", data?.accountType);
            formData.append("country", data?.country);
            formData.append("department_id", data?.department_id);
            formData.append("email", data?.email);
            formData.append("first_name", data?.first_name);
            formData.append("hour_rate", data?.hour_rate);
            formData.append("last_name", data?.last_name);
            formData.append("manager_id", data?.manager_id);
            formData.append("phone_number", data?.phone_number);
            formData.append("timezone", data?.timezone);
            formData.append("profileImage", image);
            formData.append("password", password);
            
            axios.post(`${companyURL}/createPersonnel`, formData, {
                headers: {'Authorization': `Bearer ${token}`}
            }).then((res) => {
                console.log("res : ", res);
                setLoading(false);
                showSuccessAlert();
                setTimeout(() => {
                    navigate('/company/personnel');
                }, 3000)
            }).catch((err) => {
                setLoading(false);
                // showErrorAlert();
                toast.error(err?.response?.data?.message ? err?.response?.data?.message : err?.response?.data?.error[0]);
                console.log("err : ", err);
                // setError(err?.response?.data?.error[0]);
            })
        }
    }

    const [showPassword, setShowPassword] = useState(false);

    const [password, setPassword] = useState(null);
  
    // Function to generate a random string
    const generateRandomString = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const stringLength = 8; // You can adjust the desired length
  
      let result = '';
      for (let i = 0; i < stringLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
  
    //   reset({password: result});
      setPassword(result);
    };

    // useEffect(() => {console.log(isChecked)}, [isChecked]);

    return (
        <div className="customer-dashboard">
            <h3 className="mb-0 text2">Create Personnel</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="table-card mt-30">
                    <h2 className="text10">Information</h2>
                        {loading ? (
                            <div style={{paddingTop: '35vh', paddingBottom: '35vh'}}>
                                <CustomLoader />
                            </div>
                        ) : (                        
                        <>
                        <div className="w-100 d-flex mt-4">
                            <div className="d-flex w-50">
                                <div className="w-25">
                                    <p className="text11 pt-2">Department</p>
                                    {/* <p className="text11">Status</p> */}
                                    <p className="text11 pt-2">User Role</p>
                                    <p className="text11 pt-1">User</p>
                                    <p className="text11 pt-1">First Name</p>
                                    <p className="text11">Last Name</p>
                                    <p className="text11 mt-2 pt-1">Country</p>
                                    <p className="text11 pt-2">Mobile</p>
                                    {/* <p className="text11 pt-2">Password</p> */}
                                    
                                </div>
                                <div>
                                    <div>
                                        {/* <select className="form-select custom-form-select">
                                            <option value="">Finance</option>
                                            <option value="">Development</option>
                                            <option>Management</option>
                                        </select> */}
                                        <select className="form-select custom-form-select" {...register("department_id", {required: true})}>
                                            <option value="">Select Department</option>
                                            {departments?.map((item, index) => (
                                                <option value={item?.id} key={index + 1}>{item?.title}</option>
                                            ))}
                                        </select>

                                        {/* <input {...register("department_id", {required: true})} className="form-select custom-form-select" type="text" placeholder="Enter department" /> */}
                                    </div>
                                    {/* <div className="pt-1">
                                        <div className='switch-btn-div pt-1' onClick={(e) => e.stopPropagation()}>
                                            <input onChange={handleCheckboxChange} checked={isChecked} name="check"  type="checkbox" defaultChecked={false} class="toggle-station"/>                            
                                        </div>
                                    </div> */}
                                    <div>
                                        <select className="form-select custom-form-select" {...register("accountType", {required: true})}>
                                            <option value="">Select Role</option>
                                            <option value="Employee">Employee</option>
                                            <option value="Manager">Manager</option>
                                            {/* <option value="company">Company</option> */}
                                        </select>
                                    </div>
                                    <input {...register("email", {required: true})} type="email" className="form-select custom-form-select"  placeholder="Enter email" />
                                    <div>
                                        <input placeholder="Enter first name" {...register("first_name", {required: true})} className="form-select custom-form-select" />
                                    </div>
                                    <div>
                                        <input placeholder="Enter last name" {...register("last_name", {required: true})} className="form-select custom-form-select" />
                                    </div>
                                    <div>
                                        <select className="form-select custom-form-select" {...register("country", {required: true})}>
                                            <option value="Thailand">Thailand</option>
                                            <option value="USA">USA</option>
                                            <option value="Pakistan">Pakistan</option>
                                        </select>
                                    </div>
                                    <div>
                                        <input type="text" {...register("phone_number")} className="form-select custom-form-select" placeholder="99999999999" />
                                    </div>
                                    {/* <button className="edit-btn mt-2">Edit</button> */}
                                </div>
                            </div>

                            
                                <div className="w-50 d-flex">
                                    <div className="w-25">
                                        {roleWatch === "Employee" && (
                                            <>
                                                <p className="text11 pt-3">Manager</p>
                                            </>
                                        )}
                                        <p className="text11 pt-3">Hour Rate</p>
                                        <p className="text11 pt-2">Time Zone</p>
                                        <p className="text11 pt-2">Profile Image</p>

                                    </div>
                                    <div>
                                        {roleWatch === "Employee" && (
                                            <>
                                                <div>
                                                    <select {...register("manager_id", {required: true})} className="form-select custom-form-select">
                                                        <option value="">Select Manager</option>
                                                        {managers?.map((item, index) => (
                                                            <option value={item?.id} key={index}>{item?.first_name} {item?.last_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </>
                                        )}
                                        <div>
                                            <input type="text" {...register("hour_rate", {required: true})} className="form-select custom-form-select" />
                                        </div>
                                        <div>
                                            <select className="form-select custom-form-select" {...register("timezone", {required: true})}>
                                                {timezones?.map((item, index) => (
                                                    <option value={item?.text} key={index}>{item?.text}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <input required type="file" onChange={(e) => setImage(e.target.files[0])} className="form-control mt-3" />
                                        </div>
                                    </div>
                                </div>
                            
                        </div>

                        <div className="w-100 mt-3">
                            <h4 className="text14 w-100">Password</h4>
                            <div className="d-flex w-50">
                                <div className="d-flex w-25 pt-3">
                                    <p className="text11">Password</p>
                                </div>
                                <div className="d-flex pt-1">
                                    <div className="p-relative">
                                        <input type={showPassword? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" className="form-select custom-form-select" />
                                        <svg onClick={() => setShowPassword(!showPassword)} className="eye-icon cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="33.75" height="22.5" viewBox="973.25 716.75 33.75 22.5"><path d="M1006.796 727.145c-3.177-6.2-9.468-10.395-16.671-10.395-7.203 0-13.495 4.198-16.671 10.395a1.896 1.896 0 0 0 0 1.71c3.177 6.2 9.468 10.395 16.671 10.395 7.203 0 13.495-4.198 16.671-10.395a1.895 1.895 0 0 0 0-1.71Zm-16.671 9.293a8.438 8.438 0 1 1 8.438-8.438 8.433 8.433 0 0 1-8.438 8.438Zm0-14.063c-.502.007-1 .082-1.483.222a2.804 2.804 0 0 1-3.92 3.92 5.612 5.612 0 1 0 5.403-4.142Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-eye"/></svg>
                                    </div>
                                    <div>
                                        <button onClick={generateRandomString} type="button" className="genrate-pass-btn mt-2 ms-3">Generate</button>    
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mt-3">
                                <button className="back-btn">back</button>
                                <button className="edit-btn mt-2 ms-3">Accept</button>
                            </div> */}
                        </div>


                        <div>
                            <button className="back-btn">back</button>
                            <button type="submit" className="edit-btn mt-2 ms-3">Submit</button>
                        </div>
                    </> )}
                    {/* <div className="w-100 mt-3">
                        <h4 className="text14 w-100">Password</h4>
                        <div className="d-flex w-50">
                            <div className="d-flex w-25 pt-3">
                                <p className="text11">Reset password</p>
                            </div>
                            <div className="d-flex pt-1">
                                <div className="p-relative">
                                    <input type="password" placeholder="Enter new password" className="form-select custom-form-select" />
                                    <svg className="eye-icon" xmlns="http://www.w3.org/2000/svg" width="33.75" height="22.5" viewBox="973.25 716.75 33.75 22.5"><path d="M1006.796 727.145c-3.177-6.2-9.468-10.395-16.671-10.395-7.203 0-13.495 4.198-16.671 10.395a1.896 1.896 0 0 0 0 1.71c3.177 6.2 9.468 10.395 16.671 10.395 7.203 0 13.495-4.198 16.671-10.395a1.895 1.895 0 0 0 0-1.71Zm-16.671 9.293a8.438 8.438 0 1 1 8.438-8.438 8.433 8.433 0 0 1-8.438 8.438Zm0-14.063c-.502.007-1 .082-1.483.222a2.804 2.804 0 0 1-3.92 3.92 5.612 5.612 0 1 0 5.403-4.142Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-eye"/></svg>
                                </div>
                                <div>
                                    <button className="genrate-pass-btn mt-2 ms-3">Generate</button>    
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <button className="back-btn">back</button>
                            <button className="edit-btn mt-2 ms-3">Accept</button>
                        </div>
                    </div> */}
                </div>
            </form>
            <ToastContainer />
        </div>
    )

}

export default AddUser;