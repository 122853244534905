import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { apiEndPoint } from "../../App";

const Topbar = ({type}) => {
    const navigate = useNavigate();

    const logoutFun = () => {
            const token = localStorage.getItem("token");
            const role = localStorage.getItem("role");

            axios.get(role === 'Company'? `${apiEndPoint}/api/company/logout` : `${apiEndPoint}/api/user/userLogout`, {headers: {
                'Authorization': `Bearer ${token}`
              }}).then((res) => {
                console.log("logout  res : ", res);
                localStorage.removeItem("m_token");
                localStorage.removeItem("email");
                localStorage.removeItem("name");
                localStorage.removeItem("first_name");
                localStorage.removeItem("last_name");
                localStorage.removeItem("mobile");
                localStorage.removeItem("country");
                localStorage.removeItem("token");
                
                localStorage.removeItem("role");

            }).catch((err) => {
                console.log("project list err : ", err);
            })
            localStorage.removeItem("pca_token");
            navigate('/login');
    }

    return (
        <div className="topbar">
            <svg className="notification-icon me-5" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="1618 17 50 50"><g data-name="Group 30"><path d="M1633 17h20a15 15 0 0 1 15 15v20a15 15 0 0 1-15 15h-20a15 15 0 0 1-15-15V32a15 15 0 0 1 15-15z" fill="#f7f8fc" fill-rule="evenodd" data-name="Rectangle 14"/><g data-name="Icon ionic-ios-notifications-outline"><path d="M1645.745 51.273a.91.91 0 0 0-.893.718c-.07.316-.19.576-.351.766-.092.106-.359.415-1.132.415-.774 0-1.041-.317-1.133-.415-.161-.19-.28-.45-.351-.766a.91.91 0 0 0-.893-.718.917.917 0 0 0-.893 1.118c.352 1.568 1.477 2.609 3.27 2.609 1.792 0 2.917-1.034 3.269-2.609a.92.92 0 0 0-.893-1.118Z" fill="#707070" fill-rule="evenodd" data-name="Path 8"/><path d="M1653.512 47.908c-1-1.295-2.97-2.055-2.97-7.855 0-5.954-2.677-8.347-5.173-8.922-.234-.057-.403-.134-.403-.376v-.185c0-.849-.701-1.57-1.56-1.57h-.039c-.857 0-1.559.721-1.559 1.57v.185c0 .236-.169.319-.403.376-2.502.581-5.172 2.968-5.172 8.922 0 5.8-1.97 6.553-2.97 7.855-.643.836-.033 2.03 1.033 2.03H1652.498c1.046 0 1.65-1.2 1.014-2.03Zm-2.535.37h-15.154a.28.28 0 0 1-.214-.466c.455-.51.955-1.18 1.364-2.13.624-1.443.93-3.287.93-5.63 0-2.38.455-4.243 1.358-5.538.806-1.162 1.813-1.602 2.515-1.762.546-.127.936-.37 1.208-.67a.52.52 0 0 1 .774-.013c.331.364.78.581 1.221.683.702.16 1.71.6 2.515 1.762.904 1.295 1.359 3.158 1.359 5.539 0 2.342.305 4.186.929 5.628.422.97.936 1.653 1.397 2.163a.263.263 0 0 1-.202.434Z" fill="#707070" fill-rule="evenodd" data-name="Path 9"/></g></g></svg>
            <button className="btn btn-primary me-5 mt-3 bg-0facd5" onClick={logoutFun}>Logout</button>
        </div>
    )
}

export default Topbar;