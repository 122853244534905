import React from "react";

const Profile = () => {
    return (
        <div className="customer-dashboard">
            <h3 className="mb-0 text2">User</h3>
            
            <div className="table-card mt-30">
                <h2 className="text10">Information</h2>
                <div className="w-100 d-flex mt-4">
                    <div className="d-flex w-50">
                        <div className="w-25">
                            <p className="text11">Status</p>
                            <p className="text11">User Role</p>
                            <p className="text11">User</p>
                            <p className="text11">First Name</p>
                            <p className="text11">Last Name</p>
                            <p className="text11">Country</p>
                            <p className="text11">Mobile</p>
                        </div>
                        <div>
                            <h6 className="text12 mb-3 clr-11aa00">Active</h6>
                            <h6 className="text12 mb-3 pt-1">Employee</h6>
                            <h6 className="text12 mb-3 pt-1">{localStorage.getItem("email")}</h6>
                            <h6 className="text12 mb-3 pt-1">{localStorage.getItem("first_name")}</h6>
                            <h6 className="text12 mb-3 pt-1">{localStorage.getItem("last_name")}</h6>
                            <h6 className="text12 mb-3 pt-1">{localStorage.getItem("country")}</h6>
                            <h6 className="text12 mb-3 pt-1">{localStorage.getItem("mobile")}</h6>
                        </div>
                    </div>
                    <div className="w-50 d-flex">
                        <div className="w-25">
                            <p className="text11">Manager</p>
                            <p className="text11">Time Zone</p>

                        </div>
                        <div>
                            <h6 className="text12 mb-3 pt-1">Joe Sripatana</h6>
                            <h6 className="text12 mb-3 pt-1">(UTC+07:00) Bangkok, Hanoi, Jakarta</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;