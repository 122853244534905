import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = () => {
  // Function to show a success alert
  const showSuccessAlert = () => {
    console.log("success alert")
    toast.success('Success message');
  };

  // Function to show an error alert
  const showErrorAlert = () => {
    toast.error('Error message');
  };

  // Function that returns a promise
  const fetchData = () => {
    return new Promise((resolve, reject) => {
      // Simulate an asynchronous operation
      setTimeout(() => {
        const random = Math.random();
        if (random > 0.5) {
          resolve('Promise resolved');
        } else {
          reject('Promise rejected');
        }
      }, 2000);
    });
  };

  // Function to show a promise-based alert
  const showPromiseAlert = () => {
    fetchData()
      .then((response) => {
        toast.success(response);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <div>
      <button onClick={showSuccessAlert}>Show Success Alert</button>
      <button onClick={showErrorAlert}>Show Error Alert</button>
      <button onClick={showPromiseAlert}>Show Promise Alert</button>
      <ToastContainer />
    </div>
  );
};

export default Toast;
