import Spinner from 'react-bootstrap/Spinner';

export default function CustomLoader() {
    return (
      <>
        <div style={{paddingLeft: 'calc(50% - 40px)'}}>
            <Spinner animation="border" variant="primary" />

        </div>
        {/* <Spinner animation="grow" variant="primary" /> */}
      </>
    );
  }
  