import React from "react";
import HomeNav from "../components/common/HomeNav";
import { RiWindowsFill } from "react-icons/ri";
import { FaApple } from "react-icons/fa";
import { FaLinux } from "react-icons/fa6";
import dashboardImg from "../assets/dashboad.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className="home">
            <HomeNav />
            <div className="hero-section">
                <h1 className="text-white text-center pt-4">Simple employee monitoring and time-tracking software with automatic screenshots and activity levels.</h1>
                <h4 className="text-center text-white mt-3">
                    <RiWindowsFill className="me-1" size={"0.8cm"} />
                    Windows
                    <FaApple className="me-1 ms-3" size={"0.8cm"} />
                    Mac
                    <FaLinux className="me-1 ms-3" size={"0.7cm"} />
                    Linux
                </h4>
                <div className="text-center">
                    <button onClick={() => navigate('/signup')} className="trial-btn mt-3">START TRIAL</button>
                    <div className="text-center mt-4 pt-4">
                        <img src={dashboardImg}  className="dashboard-img"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;